import _ from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import moment from 'moment';

import ReactTableFilterable from '../../components/ReactTableFilterable';
import { FormErrors, Select, TextInput, LabelField, SubmitButton, stockListRules } from '../../components/Forms';
import { fetchWholesalerList, fetchStockBalanceList, autoSubmitStockBalances } from '../../actions';
import EncryptStorage from '../../components/EncryptStrorage';

// variables for table
const months = {
  jan: {desc: 'Jan', val: 'jan'},
  feb: {desc: 'Feb', val: 'feb'},
  mar: {desc: 'Mar', val: 'mar'},
  apr: {desc: 'Apr', val: 'apr'},
  may: {desc: 'May', val: 'may'},
  jun: {desc: 'Jun', val: 'jun'},
  jul: {desc: 'Jul', val: 'jul'},
  aug: {desc: 'Aug', val: 'aug'},
  sep: {desc: 'Sep', val: 'sep'},
  oct: {desc: 'Oct', val: 'oct'},
  nov: {desc: 'Nov', val: 'nov'},
  dec: {desc: 'Dec', val: 'dec'},
};
const wholesalerColumn = {
  Header: 'Wholesaler Code',
  accessor: 'wholesaler.code',
  minWidth: 100
};
const wholesalerNameColumn = {
  Header: 'Wholesaler Name',
  accessor: 'wholesaler.name',
  minWidth: 100
};
const monthCellsRenderer = row => (
  row.value
  ? <Link className="table-links" to={{
      pathname: '/edit_stock_balance',
      state: {
        stockBalanceId: _.get(row.value, 'id'),
        stockIsSubmitted: _.get(row.value, 'submitted')
      }
    }}>
      { _.get(row.value, 'submitted')
        ? <p className="submit">Submitted</p>
        : <p className="draft">Draft</p>
      }
    </Link>
  : ''
);
const janColumn = {
  Header: months.jan.desc,
  id: `_submission_${months.jan.val}`,
  accessor: months.jan.val,
  Cell: monthCellsRenderer,
  minWidth: 85
};
const febColumn = {
  Header: months.feb.desc,
  id: `_submission_${months.feb.val}`,
  accessor: months.feb.val,
  Cell: monthCellsRenderer,
  minWidth: 85
};
const marColumn = {
  Header: months.mar.desc,
  id: `_submission_${months.mar.val}`,
  accessor: months.mar.val,
  Cell: monthCellsRenderer,
  minWidth: 85
};
const aprColumn = {
  Header: months.apr.desc,
  id: `_submission_${months.apr.val}`,
  accessor: months.apr.val,
  Cell: monthCellsRenderer,
  minWidth: 85
};
const mayColumn = {
  Header: months.may.desc,
  id: `_submission_${months.may.val}`,
  accessor: months.may.val,
  Cell: monthCellsRenderer,
  minWidth: 85
};
const junColumn = {
  Header: months.jun.desc,
  id: `_submission_${months.jun.val}`,
  accessor: months.jun.val,
  Cell: monthCellsRenderer,
  minWidth: 85
};
const julColumn = {
  Header: months.jul.desc,
  id: `_submission_${months.jul.val}`,
  accessor: months.jul.val,
  Cell: monthCellsRenderer,
  minWidth: 85
};
const augColumn = {
  Header: months.aug.desc,
  id: `_submission_${months.aug.val}`,
  accessor: months.aug.val,
  Cell: monthCellsRenderer,
  minWidth: 85
};
const sepColumn = {
  Header: months.sep.desc,
  id: `_submission_${months.sep.val}`,
  accessor: months.sep.val,
  Cell: monthCellsRenderer,
  minWidth: 85
};
const octColumn = {
  Header: months.oct.desc,
  id: `_submission_${months.oct.val}`,
  accessor: months.oct.val,
  Cell: monthCellsRenderer,
  minWidth: 85
};
const novColumn = {
  Header: months.nov.desc,
  id: `_submission_${months.nov.val}`,
  accessor: months.nov.val,
  Cell: monthCellsRenderer,
  minWidth: 85
};
const decColumn = {
  Header: months.dec.desc,
  id: `_submission_${months.dec.val}`,
  accessor: months.dec.val,
  Cell: monthCellsRenderer,
  minWidth: 85
};
const adminColumns = [wholesalerColumn, wholesalerNameColumn, janColumn, febColumn, marColumn, aprColumn, mayColumn, junColumn, julColumn, augColumn, sepColumn, octColumn, novColumn, decColumn];
const monthColumn = {
  Header: 'Month',
  id: 'month',
  accessor: d => moment(d.date).format('MMM'),
  minWidth: 100
};
const submissionColumn = {
  Header: 'Submission',
  id: '_submission_details',
  accessor: d => d,
  Cell: row => (
    <Link className="table-links" to={{
      pathname: '/edit_stock_balance',
      state: {
        stockBalanceId: _.get(row.value, 'id'),
        stockIsSubmitted: _.get(row.value, 'submitted')
      }
    }}>
      { _.get(row.value, 'submitted')
        ? <p className="submit">Details</p>
        : <p className="draft">Details</p>
      }
    </Link>
  ),
  minWidth: 100
};
const statusColumn = {
  Header: 'Status',
  id: '_submmision_status',
  accessor: d => d,
  Cell: row => _.get(row.value, 'submitted') ? 'Submitted' : 'Draft',
  minWidth: 100
};
const wholesalerColumns = [monthColumn, submissionColumn, statusColumn];

let formRules = _.cloneDeep(stockListRules);

const validateStockListForm = (values) => {
  const {year} = values;
  let errors = {};

  let re = /^\d{4}$/;
  if(!re.test(year)) {
    formRules.year_format.valid = false;
    errors.year = true;
  } else {
    formRules.year_format.valid = true;
  }

  return errors;
}

const currYear = new Date().getFullYear().toString();
const initialWholesalerFilter = [];

const StockList = (props) => {
  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const currentWholesaler = useSelector(({auth}) => auth.currentUser ? auth.currentUser.wholesaler : null);
  const isLoading = useSelector(({sales}) => sales.isLoading);

  const dispatch = useDispatch();

  const [filterYear, setFilterYear] = useState(currYear);
  const [filterWholesalers, setFilterWholesalers] = useState(initialWholesalerFilter);
  useEffect(() => {
    if(authToken) {
      if(isAdmin) {
        dispatch(fetchStockBalanceList(authToken, isAdmin, filterYear));
      } else if(currentWholesaler) {
        dispatch(fetchStockBalanceList(authToken, isAdmin, filterYear, currentWholesaler.id));
      }
    }
  }, [filterYear, authToken, isAdmin, currentWholesaler, dispatch]);
  const data = useSelector(({stock}) => stock.stockBalanceList) || [];

  useEffect(() => {
    if(authToken && isAdmin) {
      dispatch(fetchWholesalerList(authToken, isAdmin));
    }
  }, [authToken, isAdmin, dispatch]);
  const wholesalerList = useSelector(({wholesaler}) => wholesaler.wholesalerList);
  const isLoadingWholesaler = useSelector(({wholesaler}) => wholesaler.isLoading);

  // Save filters on Local Storage
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if(!isLoadingWholesaler) {
      let stocksListStorage = EncryptStorage.getItem('stocksList') || {};
      if(stocksListStorage && stocksListStorage.filterWholesalers) {
        setFilterWholesalers(stocksListStorage.filterWholesalers);
      }
      if(stocksListStorage && stocksListStorage.filterYear) {
        setFilterYear(stocksListStorage.filterYear);
      }  
      setLoaded(true);
    }
  }, [isLoadingWholesaler]);

  useEffect(() => {
    if(loaded) {
      const stocksListStorage = {filterYear, filterWholesalers};
      EncryptStorage.setItem('stocksList', stocksListStorage);
    }
  }, [loaded, filterYear, filterWholesalers]);

  // Prepare data
  const filteredData = useMemo(() => {
    if(!isAdmin)
      return data;

    const filtered = data.filter(stock => 
      filterWholesalers.length === 0 || _.find(filterWholesalers, ws => ws.id === _.get(stock, 'wholesaler_id'))
    );
    const wholesalers = filterWholesalers.length === 0 ? wholesalerList : filterWholesalers;
    const initialObj = wholesalers.reduce(
      (acc, curr) => {
        acc[curr.id] = {wholesaler: curr}
        return acc;
      }, {}
    );
    const reorganisedObj = filtered.length !== 0 && Object.keys(initialObj).length !== 0
    ? filtered.reduce(
        (acc, curr) => {
          if(curr.hasOwnProperty('wholesaler_id') && curr.hasOwnProperty('date') && curr.hasOwnProperty('id')) {
            acc[curr.wholesaler_id] = {...acc[curr.wholesaler_id], [moment(curr.date).format('MMM').toLowerCase()]: curr};
          }
          return acc;
        }, initialObj
      )
    : initialObj;
    return Object.values(reorganisedObj);
  }, [isAdmin, data, filterWholesalers, wholesalerList]);

  if(!authToken)
    return null;

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li className="is-active"><a href="# " aria-current="page">Stock Balance</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              Stock Balance
            </h1>
          </div>
        </div>
      </section>
      <Form
        onSubmit={ values => {
          const {wholesaler, year} = values;
          if(year !== filterYear) {
            setFilterYear(year);
          }
          if(isAdmin) {
            setFilterWholesalers(wholesaler || initialWholesalerFilter);
          }
        }}
        initialValues={{wholesaler: filterWholesalers, year: filterYear}}
        validate={validateStockListForm}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <FormErrors formRules={Object.values(formRules)}/>
            { isAdmin
              ? <Field name="wholesaler">
                  {(props) => (
                    <Select
                      label="Wholesaler"
                      options={wholesalerList}
                      getOptionLabel={o => o.name}
                      getOptionValue={o => o.id}
                      placeholder="All"
                      isLoading={isLoadingWholesaler}
                      isMulti
                      isClearable
                      isSearchable
                      {...props}
                    />
                  )}
                </Field>
              : <LabelField label="Wholesaler">{_.get(currentWholesaler, 'name', '')}</LabelField>
            }
            <Field name="year" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Year"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <SubmitButton
              disabled={ submitting || validating}
              loading={submitting || validating || isLoading}
            >Filter</SubmitButton>
          </form>
        )}
      />
      <div className="column is-full has-text-centered" style={{margin: '30px'}}>
        <Link
          className={`button is-outlined is-primary ${isLoading && 'is-loading'}`}
          to='/add_stock_balance'
          onClick={() => {
            if(!isAdmin && currentWholesaler) {
              const cutOffDate = moment().subtract(2, 'months').endOf('month');
              const overdueDrafts = data.filter(stock => moment(stock.date) < cutOffDate && !stock.submitted);

              if(overdueDrafts.length) {
                if(window.confirm(`Stock Balances are overdue for months${overdueDrafts.map(draft => `\r\n- ${moment(draft.date).format('MMM, YYYY')}`)}\r\nStock Balances will be submitted automatically.`)) {
                  dispatch(autoSubmitStockBalances(authToken, overdueDrafts));
                }
              }
            }
          }}
        >New Submission</Link>
      </div>
      <div>
        <ReactTableFilterable
          data={filteredData}
          columns={isAdmin ? adminColumns : wholesalerColumns}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    </div>
  );
};

export default StockList;