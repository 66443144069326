import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import ReactTableFilterable from '../../components/ReactTableFilterable';
import { FormErrors, deactivateMessage } from '../../components/Forms';
import { fetchOutletList, clearOutlet } from '../../actions';
import {CSVFromTable} from '../../components/ExportCSV';

// variables for table
const codeColumn = {
  Header: 'Outlet Code',
  accessor: 'code',
  minWidth: 100
};
const nameColumn = {
  Header: 'Outlet Name',
  accessor: 'name',
  minWidth: 250
};
const branchColumn = {
  Header: 'Branch',
  accessor: 'branch',
  minWidth: 200
};
const contractColumn = {
  Header: 'Contract Type',
  accessor: 'contract.contract',
  minWidth: 150
};
const outletTypeColumn = {
  Header: 'Outlet Type',
  accessor: 'outlet_type.outlet_type',
  minWidth: 200
};
const legalNameColumn = {
  Header: 'Legal Name',
  accessor: 'legal_name',
  minWidth: 250
};
const stateColumn = {
  Header: 'State',
  accessor: 'state.state',
  minWidth: 150
};
const activeColumn = {
  Header: 'Status',
  id: 'status',
  accessor: 'active',
  Cell: row => (
    <span>
      <span style={{
        color: row.value ? '#57d500' : '#ff2e00',
        transition: 'all .3s ease'
      }}>
        &#x25cf;
      </span> {
        row.value  ? 'Active' : 'Deactivated'
      }
    </span>
  ),
  minWidth: 100
};
const editColumn = {
  Header: 'Edit',
  id: 'edit',
  accessor: outlet => outlet,
  Cell: row => (
    <div className="has-text-centered">
      <Link className="button is-primary is-outlined" to={{
        pathname: '/edit_outlet',
        state: {outletToUpdate: row.value}
      }}>
        <span className="icon">
          <i className="fas fa-edit"/>
        </span>
      </Link>
    </div>
  ),
  width: 100
}
const adminColumns = [codeColumn, nameColumn, branchColumn, contractColumn, outletTypeColumn, legalNameColumn, stateColumn, activeColumn, editColumn];
const executiveColumns = [codeColumn, nameColumn, branchColumn, contractColumn, outletTypeColumn, legalNameColumn, stateColumn, activeColumn];

const OutletList = (props) => {
  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const isLoading = useSelector(({outlet}) => outlet.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if(authToken) {
      // clear existing outlet in session
      dispatch(clearOutlet());
      dispatch(fetchOutletList(authToken));
    }
  }, [authToken, dispatch]);
  const data = useSelector(({outlet}) => outlet.outletList) || [];

  const dataTableRef = useRef(null);

  if(!authToken)
    return null;

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li className="is-active"><a href="# " aria-current="page">Outlet</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              Outlet List
            </h1>
          </div>
        </div>
      </section>
      <div className="column is-full buttons" style={{marginBottom: '50px', justifyContent: 'flex-end'}}>
        <CSVFromTable
          tableRef={dataTableRef}
          columns={isAdmin ? adminColumns : executiveColumns}
          fileName="PRM-OutletList"
          loading={isLoading}
        >
          <span className="icon">
            <i className="fas fa-file-excel"></i>
          </span>
          <span>Export to Excel</span>
        </CSVFromTable>
        { isAdmin &&
          <Link
            className={`button is-outlined is-primary ${isLoading && 'is-loading'}`}
            to='/add_outlet'
          >New Outlet</Link>
        }
      </div>
      { isAdmin &&
        <FormErrors formRules={Object.values(deactivateMessage)}/>
      }
      <div>
        <ReactTableFilterable
          data={data}
          columns={isAdmin ? adminColumns : executiveColumns}
          defaultPageSize={10}
          className="-striped -highlight"
          ref={dataTableRef}
        />
      </div>
    </div>
  );
};

export default OutletList;