import _ from 'lodash';
import moment from 'moment';

export const compareNumbers = (key) => (a, b) => {
  const aNumber = Number(_.get(a, key));
  const bNumber = Number(_.get(b, key));
  return aNumber - bNumber;
};

export const compareStrings = (keys) => (a, b) => {
  for(let i = 0; i < keys.length; i++) {
    const key =  keys[i];
    const aString = _.get(a, key).toString().toLowerCase();
    const bString = _.get(b, key).toString().toLowerCase();
    if(aString < bString)
      return -1;
    if(aString > bString)
      return 1;
  };
  return 0;
};

export const compareDates = (key) => (a, b) => {
  const aDate = moment(_.get(a, key));
  const bDate = moment(_.get(b, key));
  return aDate - bDate;
};