import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const CustomSelect = ({label, input, meta={}, ...rest}) => label 
? (
  <div className="field is-horizontal">
    <div className="field-label">
      <label className="label">{label}</label>
    </div>
    <div className="field-body">
      <div className="field" style={styles.inputField}>
        <div className="control">
          <Select
            styles={!meta.active && meta.touched && meta.error && {control: (provided) => ({...provided, borderColor: '#c30c3e'})}}
            {...input}
            {...rest}
          />
        </div>
      </div>
    </div>
  </div>
) : (
  <div className="control">
    <Select
      {...input}
      {...rest}
    />
  </div>
);

const styles = {
  inputField: {
    maxWidth: '500px',
  }
};

CustomSelect.propTypes = {
  label: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.object)]).isRequired,
    onChange: PropTypes.func.isRequired
  }),
  meta: PropTypes.object
};

export default CustomSelect;