import client from './client';
import { createMessage } from '.';
import { compareStrings } from './sortHelper';

export const LOADING_PRODUCT = 'loading_product';
export const FETCH_PRODUCT_LIST = 'fetch_product_list';
export const CREATE_PRODUCT = 'create_product';
export const UPDATE_PRODUCT = 'update_product';
export const COMPLETE_PRODUCT = 'complete_product';

export const fetchProductList = (authToken) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_PRODUCT
    });

    client.getProducts(authToken)
    .then(({data}) => {
      dispatch({
        type: FETCH_PRODUCT_LIST,
        productList: data.sort(compareStrings(['brand', 'sub_brand', 'name']))
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_PRODUCT
      });
    });
  };
};

export const createProduct = (authToken, isAdmin, values, redirectOnSuccess) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_PRODUCT
      });

      const {brand, sub_brand, name, sku_no, pack_size, bottle_size, market} = values;
      client.postProduct(authToken, brand, sub_brand, name, sku_no, pack_size, bottle_size, market)
      .then(({data}) => {
        dispatch(createMessage('Product created successfully.', 'success'));
        dispatch({
          type: CREATE_PRODUCT,
          newProduct: data
        });
        redirectOnSuccess();
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_PRODUCT
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
};

export const updateProduct = (authToken, isAdmin, values, redirectOnSuccess) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_PRODUCT
      });

      const {id, brand, sub_brand, name, sku_no, pack_size, bottle_size, market, active} = values;
      client.putProduct(authToken, id, brand, sub_brand, name, sku_no, pack_size, bottle_size, market, active)
      .then(({data}) => {
        dispatch(createMessage('Product updated successfully.', 'success'));
        dispatch({
          type: UPDATE_PRODUCT,
          changedProduct: data
        });
        redirectOnSuccess();
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_PRODUCT
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
};
