import client from './client';
import { createMessage } from '.';
import { compareStrings, compareDates } from './sortHelper';

export const LOADING_OUTLET = 'loading_outlet';
export const FETCH_OUTLET_LIST = 'fetch_outlet_list';
export const FETCH_OUTLET = 'fetch_outlet';
export const CREATE_OUTLET = 'create_outlet';
export const UPDATE_OUTLET = 'update_outlet';
export const FETCH_NEW_OUTLETS_LISTING = 'fetch_new_outlets_listing';
export const COMPLETE_OUTLET = 'complete_outlet';
export const CLEAR_OUTLET = 'clear_outlet';

export const fetchOutletList = (authToken) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_OUTLET
    });

    client.getOutlets(authToken)
    .then(({data}) => {
      dispatch({
        type: FETCH_OUTLET_LIST,
        outletList: data.sort(compareStrings(['name', 'branch']))
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_OUTLET
      });
    });
  };
};

export const fetchOutletListLean = (authToken) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_OUTLET
    });

    // Clear existing data for fresh outlet list
    // To allow delay rendering of sales submission react-table (table should not render when outlet list is empty)
    dispatch({
      type: CLEAR_OUTLET,
      outletList: []
    });

    client.getOutletsLean(authToken)
    .then(({data}) => {
      dispatch({
        type: FETCH_OUTLET_LIST,
        outletList: data.sort(compareStrings(['name', 'branch']))
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_OUTLET
      });
    });
  };
};

export const fetchOutlet = (authToken, outletId) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_OUTLET
    });

    client.getOutlet(authToken, outletId)
    .then(({data}) => {
      dispatch({
        type: FETCH_OUTLET,
        outletToUpdate: data
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_OUTLET
      });
    });
  };
};

export const createOutlet = (authToken, isAdmin, values, redirectOnSuccess) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_OUTLET
      });

      const {code, name, branch, contract, outlet_type, legal_name, state, phone, pic, address1, address2, postcode, city, created_by} = values;
      client.postOutlet(authToken, code, name, branch, contract, outlet_type, legal_name, state, phone, pic, address1, address2, postcode, city, created_by)
      .then(({data}) => {
        dispatch(createMessage('Outlet created successfully.', 'success'));
        dispatch({
          type: CREATE_OUTLET,
          newOutlet: data
        });
        redirectOnSuccess();
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_OUTLET
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
};

export const updateOutlet = (authToken, isAdmin, values, redirectOnSuccess) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_OUTLET
      });

      const {id, code, name, branch, contract, outlet_type, legal_name, state, phone, pic, address1, address2, postcode, city, created_by, active} = values;
      client.putOutlet(authToken, id, code, name, branch, contract, outlet_type, legal_name, state, phone, pic, address1, address2, postcode, city, created_by, active)
      .then(({data}) => {
        dispatch(createMessage('Outlet updated successfully.', 'success'));
        dispatch({
          type: UPDATE_OUTLET,
          changedOutlet: data
        });
        redirectOnSuccess();
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_OUTLET
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
};

export const fetchNewOutletsListing = (authToken, isAdmin, startDate, endDate) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_OUTLET
      });

      client.getNewOutletsListing(authToken, startDate, endDate)
      .then(({data}) => {
        dispatch({
          type: FETCH_NEW_OUTLETS_LISTING,
          newOutletsListing: data.sort(compareDates('created'))
        });
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_OUTLET
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
};

// clear previous outlet in session
export const clearOutlet = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_OUTLET,
      outletToUpdate: undefined
    });
  };
};
