import React from 'react';

const Footer = (props) => (
  <footer className="footer">
    <div className="content has-text-centered">
      <small className="has-text-grey">
        <span>Copyright © {new Date().getFullYear()} <a href="https://www.pernod-ricard.com/en">Pernod Ricard Malaysia</a>. All Rights Reserved.</span>
      </small>
    </div>
  </footer>
)

export default Footer;
