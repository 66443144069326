import React from 'react';
import PropTypes from 'prop-types';

const TextInput = ({label, input, meta={}, horizontal, ...rest}) => label
? horizontal 
  ? (
    <div className="field is-horizontal">
      <div className="field-label">
        <label className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field" style={styles.inputField}>
          <div className={`control ${meta.error && meta.touched && 'has-icons-right'}`}>
            <input
              className={`input ${meta.error && meta.touched && 'is-danger'}`}
              {...input}
              {...rest}
            />
            { meta.error && meta.touched &&
              <span className="icon is-small is-right">
                <i className="fas fa-exclamation-triangle"/>
              </span>
            }
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="column is-full" style={styles.loginFieldContainer}>
      <div className="field" style={styles.loginField}>
        <label className="label">{label}</label>
        <div className={`control ${meta.error && meta.touched && 'has-icons-right'}`}>
          <input
            className={`input ${meta.error && meta.touched && 'is-danger'}`}
            {...input}
            {...rest}
          />
        </div>
      </div>
    </div>
  )
: (
  <div className={"control"}>
    <input
      className={"input"}
      // Add "key" to re-render input when defaultValue changes
      key={input.defaultValue}
      {...input}
      {...rest}
    />
  </div>
);

TextInput.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.oneOf(["text", "password"]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
  }),
  meta: PropTypes.object,
  horizontal: PropTypes.bool
};

const fieldWidth = '500px';
const styles = {
  inputField: {
    maxWidth: fieldWidth,
  },
  loginFieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  loginField: {
    width: fieldWidth,
  },
};

export default TextInput;