import React from 'react';
import PropTypes from 'prop-types';

import './FormErrors.sass';

const FormErrors = (props) => {
  return (
    <div>
      <ul className="validation-list">
        {
          props.formRules.map((rule, index) => {
            return <li
              className={
                rule.info
                ? "info"
                : rule.loading
                  ? "load"
                  : rule.valid
                    ? "success"
                    : rule.pristine ? "idle" : "error"
              }
              key={index+1}
            >{rule.desc}</li>
          })
        }
      </ul>
      <br/>
    </div>
  );
};

FormErrors.propTypes = {
  formRules: PropTypes.arrayOf(PropTypes.shape({
    desc: PropTypes.string.isRequired,
    valid: PropTypes.bool
  })).isRequired
};

export default FormErrors;
