import {
  LOADING_SALES,
  FETCH_ALL_SALES_SUMMARY,
  FETCH_SALES_SUMMARY_LIST,
  FETCH_SALES_SUMMARY_DETAILS,
  CLEAR_SALES_SUMMARY_DETAILS,
  FETCH_SALES_SUMMARY_REPORT,
  COMPLETE_SALES
} from '../actions';

const INITIAL_STATE = {
  isLoading: false,
  allSalesSummary: [],
  salesSummaryList: [],
  salesSummaryDetails: {}
};

export default (state = INITIAL_STATE, action) => {
  const {type, ...rest} = action;
  switch(type) {
    case LOADING_SALES: {
      return {...state, isLoading: true};
    }

    case FETCH_ALL_SALES_SUMMARY:
    case FETCH_SALES_SUMMARY_LIST:
    case FETCH_SALES_SUMMARY_DETAILS:
    case FETCH_SALES_SUMMARY_REPORT: {
      return {...state, ...rest, isLoading: false};
    }

    case CLEAR_SALES_SUMMARY_DETAILS: {
      return {...state, salesSummaryDetails: {}};
    }

    case COMPLETE_SALES: {
      return {...state, isLoading: false};
    }

    default: {
      return state;
    }
  }
};