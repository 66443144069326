import {
  LOADING_WHOLESALER,
  FETCH_WHOLESALER_LIST,
  CREATE_WHOLESALER,
  UPDATE_WHOLESALER,
  COMPLETE_WHOLESALER,
} from '../actions';

const INITIAL_STATE = {
  isLoading: false,
  wholesalerList: []
};

export default (state = INITIAL_STATE, action) => {
  const {type, ...rest} = action;
  switch(action.type) {
    case LOADING_WHOLESALER: {
      return {...state, isLoading: true};
    }

    case FETCH_WHOLESALER_LIST: {
      return {...state, ...rest, isLoading: false};
    }

    case CREATE_WHOLESALER: {
      const {newWholesaler} = action;
      const wholesalerList = [...state.wholesalerList, newWholesaler];
      return {...state, wholesalerList, isLoading: false};
    }

    case UPDATE_WHOLESALER: {
      const {changedWholesaler} = action;
      const wholesalerList = state.wholesalerList.map(ws => ws.id === changedWholesaler.id ? changedWholesaler : ws);
      return {...state, wholesalerList, isLoading: false};
    }

    case COMPLETE_WHOLESALER: {
      return {...state, isLoading: false};
    }

    default: {
      return state;
    }
  }
};