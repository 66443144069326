import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const MessageHandler = (props) => {
  const message = useSelector(({message}) => message);

  const {createMessage, removeMessage} = props;

  useEffect(() => {
    if (message.messageName && message.messageType) {
      createMessage(message.messageName, message.messageType, message.messageTimeout);
    } else {
      removeMessage();
    }
  }, [message, createMessage, removeMessage]);

  return (
    <></>
  );
};

MessageHandler.propTypes = {
  createMessage: PropTypes.func.isRequired,
  removeMessage: PropTypes.func.isRequired
};

export default MessageHandler;