import _ from 'lodash';
import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import { FormErrors, Select, TextInput, CheckBox, SubmitButton, userFormRules } from '../../components/Forms';
import { fetchAllUserList, fetchWholesalerList, createUser, updateUser } from '../../actions';

const UserForm = (props) => {
  const forAdmin = props.forAdmin;
  const userToUpdate = _.get(props, 'location.state.userToUpdate');
  const history = props.history;

  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const isLoading = useSelector(({user}) => user.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if(authToken && isAdmin) {
      dispatch(fetchAllUserList(authToken, isAdmin));
    }
  }, [authToken, isAdmin, dispatch]);
  const userList = useSelector(({user}) => user.allUserList);

  useEffect(() => {
    if(authToken && isAdmin && !forAdmin) {
      dispatch(fetchWholesalerList(authToken, isAdmin));
    }
  }, [authToken, isAdmin, forAdmin, dispatch]);
  const wholesalerList = useSelector(({wholesaler}) => wholesaler.wholesalerList);
  const isLoadingWholesaler = useSelector(({wholesaler}) => wholesaler.isLoading);

  // Form Validation
  const formRules = useMemo(() => userFormRules(forAdmin, userToUpdate), [forAdmin, userToUpdate]);

  const validateEmail = (email) => {
    // eslint-disable-next-line
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const mValidateEmail = useCallback(validateEmail, []);

  const verifyUniqueFields = (values) => {
    const {username, email} = values;
    let errors = {};
    const userListExcludeCurrentUser = userToUpdate ? userList.filter(user => user.id !== userToUpdate.id) : userList;

    formRules.username_taken.pristine = false;
    if(userListExcludeCurrentUser.some(o => _.get(o, 'username', '').toLowerCase().trim() === (username||'').toLowerCase().trim())) {
      formRules.username_taken.valid = false;
      errors.username = true;
    } else {
      formRules.username_taken.valid = true;
    }

    formRules.email_taken.pristine = false;
    if(userListExcludeCurrentUser.some(o => _.get(o, 'email', '').toLowerCase().trim() === (email||'').toLowerCase().trim())) {
      formRules.email_taken.valid = false;
      errors.email = true;
    } else {
      formRules.email_taken.valid = true;
    }

    return errors;
  }
  const mVerifyUniqueFields = useCallback(verifyUniqueFields, [userList, formRules]);

  const validateUserForm = (values) => {
    const {wholesaler, username, email, password, confirm_password} = values;
    let errors = {};

    if(formRules.wholesaler_required) {
      if(!wholesaler) {
        formRules.wholesaler_required.valid = false;
        errors.wholesaler = true;
      } else {
        formRules.wholesaler_required.valid = true;
      }
    }

    if(!username) {
      formRules.username_required.valid = false;
      errors.username = true;
    } else {
      formRules.username_required.valid = true;
    }

    if(!mValidateEmail(email)) {
      formRules.email_format.valid = false;
      errors.email = true;
    } else {
      formRules.email_format.valid = true;
    }

    if(formRules.password_length) {
      if(!password || password.length < 8) {
        formRules.password_length.valid = false;
        errors.password = true;
      } else {
        formRules.password_length.valid = true;

        if(formRules.password_match) {
          if(!confirm_password || password !== confirm_password) {
            formRules.password_match.valid = false;
            errors.confirm_password = true;
          } else {
            formRules.password_match.valid = true;
          }
        }
      }
    }

    return Object.keys(errors).length ? errors : mVerifyUniqueFields(values);
  }
  const mValidateUserForm = useCallback(validateUserForm, [mValidateEmail, mVerifyUniqueFields, formRules]);

  // Validate initial values once on CDM - future refactor and remove eslint-disable
  useEffect(() => {
    userToUpdate && mValidateUserForm(userToUpdate)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  if(!authToken || !isAdmin) {
    return null;
  }

  const pathname = _.get(props, 'location.pathname');
  if(!userToUpdate) {
    if(pathname === '/edit_admin') {
      return <Redirect to='/add_admin'/>;
    } else if(pathname === '/edit_executive') {
      return <Redirect to='/add_executive'/>;
    }
  }

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li><Link to={forAdmin ? '/admin' : '/executive'}>{forAdmin ? 'Admin' : 'Executive'}</Link></li>
          <li className="is-active"><a href="# " aria-current="page">{userToUpdate ? 'Edit ' : 'New '}{forAdmin ? 'Admin' : 'Executive'}</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              {userToUpdate ? 'Edit ' : 'New '}{forAdmin ? 'Admin' : 'Executive'}
            </h1>
          </div>
        </div>
      </section>
      <Form
        onSubmit={ values => {
          // if(window.confirm(`Confirm to ${userToUpdate ? 'update' : 'create'} User?`)) {
            if(userToUpdate) {
              dispatch(updateUser(authToken, isAdmin, forAdmin, values, () => history.push(forAdmin ? '/admin' : '/executive')));
            } else {
              dispatch(createUser(authToken, isAdmin, forAdmin, values, () => history.push(forAdmin ? '/admin' : '/executive')));
            }
          // }
        }}
        initialValues={userToUpdate || {admin: forAdmin, wholesaler: null, active: true}}
        validate={mValidateUserForm}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <FormErrors formRules={Object.values(formRules)}/>
            { !forAdmin &&
              <Field name="wholesaler">
              {(props) => (
                <Select
                  label="Wholesaler"
                  options={wholesalerList}
                  getOptionLabel={o => o.name}
                  getOptionValue={o => o.id}
                  placeholder="Select Wholesaler..."
                  isLoading={isLoadingWholesaler}
                  isClearable
                  isSearchable
                  {...props}
                />
              )}
              </Field>
            }
            <Field name="username" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Username"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="email" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Email"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            { !userToUpdate &&
              <>
                <Field name="password" type="password">
                  {(props) => (
                    <TextInput
                      horizontal
                      label="Password"
                      disabled={isLoading}
                      {...props}
                    />
                  )}
                </Field>
                <Field name="confirm_password" type="password">
                  {(props) => (
                    <TextInput
                      horizontal
                      label="Confirm Password"
                      disabled={isLoading}
                      {...props}
                    />
                  )}
                </Field>
              </>
            }
            { userToUpdate &&
              <Field name="active" type="checkbox">
                {(props) => (
                  <CheckBox
                    label="Status"
                    desc="Active"
                    disabled={isLoading}
                    {...props}
                  />
                )}
              </Field>
            }
            <SubmitButton
              disabled={pristine || submitting || validating}
              loading={submitting || validating}
            >{userToUpdate ? 'Update' : 'Add'}</SubmitButton>
          </form>
        )}
      />
    </div>
  );
};

UserForm.propTypes = {
  forAdmin: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(UserForm);