import React from 'react';
import PropTypes from 'prop-types';

const SubmitButton = ({disabled, loading, children, ...rest}) => (
  <div className="column is-full has-text-centered" style={{marginTop: '50px'}}>
    <button
      type="submit"
      disabled={disabled}
      className={`button is-outlined is-primary ${loading && 'is-loading'}`}
      onClick={() => {
        const el = document.querySelector('.error')
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
        document.activeElement.blur();
      }}
      onMouseDown={e => e.preventDefault()}
      {...rest}
    >{children}</button>
  </div>
);

SubmitButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default SubmitButton;