import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import ReactTableFilterable from '../../components/ReactTableFilterable';
import { FormErrors, deactivateMessage } from '../../components/Forms';
import { fetchWholesalerList } from '../../actions';

// variables for table
const codeColumn = {
  Header: 'Wholesaler Code',
  accessor: 'code',
  minWidth: 100
};
const nameColumn = {
  Header: 'Wholesaler Name',
  accessor: 'name',
  minWidth: 250
};
const regionColumn = {
  Header: 'Region',
  accessor: 'region.region',
  minWidth: 150
};
const stateColumn = {
  Header: 'State',
  accessor: 'state.state',
  minWidth: 150
};
const activeColumn = {
  Header: 'Status',
  id: 'status',
  accessor: 'active',
  Cell: row => (
    <span>
      <span style={{
        color: row.value ? '#57d500' : '#ff2e00',
        transition: 'all .3s ease'
      }}>
        &#x25cf;
      </span> {
        row.value  ? 'Active' : 'Deactivated'
      }
    </span>
  ),
  minWidth: 100
};
const editColumn = {
  Header: 'Edit',
  id: 'edit',
  accessor: wholesaler => wholesaler,
  Cell: row => (
    <div className="has-text-centered">
      <Link className="button is-primary is-outlined" to={{
        pathname: '/edit_wholesaler',
        state: {wholesalerToUpdate: row.value}
      }}>
        <span className="icon">
          <i className="fas fa-edit"/>
        </span>
      </Link>
    </div>
  ),
  width: 100
}
const columns = [codeColumn, nameColumn, regionColumn, stateColumn, activeColumn, editColumn];

const WholesalerList = (props) => {
  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const isLoading = useSelector(({wholesaler}) => wholesaler.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if(authToken && isAdmin) {
      dispatch(fetchWholesalerList(authToken, isAdmin));
    }
  }, [authToken, isAdmin, dispatch]);
  const data = useSelector(({wholesaler}) => wholesaler.wholesalerList) || [];

  if(!authToken || !isAdmin)
    return null;

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li className="is-active"><a href="# " aria-current="page">Wholesaler</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              Wholesaler List
            </h1>
          </div>
        </div>
      </section>
      <div className="column is-full has-text-right" style={{marginBottom: '50px'}}>
        <Link
          className={`button is-outlined is-primary ${isLoading && 'is-loading'}`}
          to='/add_wholesaler'
        >New Wholesaler</Link>
      </div>
      <FormErrors formRules={Object.values(deactivateMessage)}/>
      <div>
        <ReactTableFilterable
          data={data}
          columns={columns}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    </div>
  );
};

export default WholesalerList;