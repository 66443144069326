import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import ReactTableFilterable from '../../components/ReactTableFilterable';
import { FormErrors, deactivateMessage } from '../../components/Forms';
import { fetchProductList } from '../../actions';
import {CSVFromTable} from '../../components/ExportCSV';

// variables for table
const brandColumn = {
  Header: 'Brand',
  accessor: 'brand',
  minWidth: 150
};
const subBrandColumn = {
  Header: 'Sub Brand',
  accessor: 'sub_brand',
  minWidth: 250
};
const nameColumn = {
  Header: 'Product Name',
  accessor: 'name',
  minWidth: 300
};
const skuColumn = {
  Header: 'SKU No.',
  accessor: 'sku_no',
  minWidth: 150
};
const packColumn = {
  Header: 'Pack Size',
  accessor: 'pack_size',
  minWidth: 150
};
const bottleColumn = {
  Header: 'Bottle Size',
  accessor: 'bottle_size',
  minWidth: 100
};
const marketColumn = {
  Header: 'Market',
  accessor: 'market.market',
  minWidth: 100
};
const activeColumn = {
  Header: 'Status',
  id: 'status',
  accessor: 'active',
  Cell: row => (
    <span>
      <span style={{
        color: row.value ? '#57d500' : '#ff2e00',
        transition: 'all .3s ease'
      }}>
        &#x25cf;
      </span> {
        row.value  ? 'Active' : 'Deactivated'
      }
    </span>
  ),
  minWidth: 100
};
const editColumn = {
  Header: 'Edit',
  id: 'edit',
  accessor: product => product,
  Cell: row => (
    <div className="has-text-centered">
      <Link className="button is-primary is-outlined" to={{
        pathname: '/edit_product',
        state: {productToUpdate: row.value}
      }}>
        <span className="icon">
          <i className="fas fa-edit"/>
        </span>
      </Link>
    </div>
  ),
  width: 100
}
const columns = [brandColumn, subBrandColumn, nameColumn, skuColumn, packColumn, bottleColumn, marketColumn, activeColumn, editColumn];

const ProductList = (props) => {
  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const isLoading = useSelector(({product}) => product.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if(authToken && isAdmin) {
      dispatch(fetchProductList(authToken));
    }
  }, [authToken, isAdmin, dispatch]);
  const data = useSelector(({product}) => product.productList) || [];

  const dataTableRef = useRef(null);

  if(!authToken || !isAdmin)
    return null;

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li className="is-active"><a href="# " aria-current="page">Product</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              Product List
            </h1>
          </div>
        </div>
      </section>
      <div className="column is-full buttons" style={{marginBottom: '50px', justifyContent: 'flex-end'}}>
        <CSVFromTable
          tableRef={dataTableRef}
          columns={columns}
          fileName="PRM-ProductList"
          loading={isLoading}
        >
          <span className="icon">
            <i className="fas fa-file-excel"></i>
          </span>
          <span>Export to Excel</span>
        </CSVFromTable>
        <Link
          className={`button is-outlined is-primary ${isLoading && 'is-loading'}`}
          to='/add_product'
        >New Product</Link>
      </div>
      <FormErrors formRules={Object.values(deactivateMessage)}/>
      <div>
        <ReactTableFilterable
          data={data}
          columns={columns}
          defaultPageSize={10}
          className="-striped -highlight"
          ref={dataTableRef}
        />
      </div>
    </div>
  );
};

export default ProductList;