import _ from 'lodash';
import React, {useMemo, useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter, Link} from 'react-router-dom';
import * as XLSX from 'xlsx';

import {createMessage} from '../../actions';
import {CSVFromArray} from '../../components/ExportCSV';

const convertSalesWorker = new Worker('./worker/convertSales.js');

// Products Columns
const productColumns = () => {
  const brandColumn = {
    Header: 'Brand',
    accessor: 'brand'
  };
  const subBrandColumn = {
    Header: 'Sub Brand',
    accessor: 'sub_brand'
  };
  const nameColumn = {
    Header: 'Product Name',
    accessor: 'name'
  };
  const skuColumn = {
    Header: 'SKU No.',
    accessor: 'sku_no'
  };
  const packColumn = {
    Header: 'Pack Size',
    accessor: 'pack_size'
  };
  const bottleColumn = {
    Header: 'Bottle Size',
    accessor: 'bottle_size'
  };
  const marketColumn = {
    Header: 'Market',
    accessor: 'market.market'
  };
  return([brandColumn, subBrandColumn, nameColumn, skuColumn, packColumn, bottleColumn, marketColumn]);
};

// Outlets Columns
const outletColumns = () => {
  const codeColumn = {
    Header: 'Outlet Code',
    accessor: 'code'
  };
  const nameColumn = {
    Header: 'Outlet Name',
    accessor: 'name'
  };
  const branchColumn = {
    Header: 'Branch',
    accessor: 'branch'
  };
  const legalNameColumn = {
    Header: 'Legal Name',
    accessor: 'legal_name'
  };
  return([codeColumn, nameColumn, branchColumn, legalNameColumn]);
};

// Sales Template Columns
const salesTemplateColumns = () => {
  const invoiceDateColumn = {
    Header: 'Invoice Date',
    accessor: 'invoice_date'
  };
  const invoiceNoColumn = {
    Header: 'Invoice No.',
    accessor: 'invoice_no'
  };
  const outletNameColumn = {
    Header: 'Outlet Name',
    accessor: 'outlet_name'
  };
  const outletBranchColumn = {
    Header: 'Outlet Branch',
    accessor: 'outlet_branch'
  };
  const productNameColumn = {
    Header: 'Product Name',
    accessor: 'product_name'
  };
  const actualUnitsColumn = {
    Header: 'Actual Units Sold (Bottles)',
    accessor: 'actual_units'
  };
  const standardTOColumn = {
    Header: 'Standard TO (Bottles)',
    accessor: 'standard_to'
  };
  const addTOColumn = {
    Header: 'Additional TO (Bottles)',
    accessor: 'additional_to'
  };
  return([invoiceDateColumn, invoiceNoColumn, outletNameColumn, outletBranchColumn, productNameColumn, actualUnitsColumn, standardTOColumn, addTOColumn]);
}

const sampleSalesData = (products, outlets) => {
  var sampleData = [];
  products.forEach((product, index) => {
    sampleData.push({
      invoice_date: new Date("2021-01-31"),
      invoice_no: `SAMPLE-INV00${index+1}`,
      outlet_name: _.get(outlets[index], 'name'),
      outlet_branch: _.get(outlets[index], 'branch'),
      product_name: _.get(product, 'name'),
      actual_units: `${index+10}`,
      standard_to: `${index>=2 ? 2 : ''}`,
      additional_to: `${index>=4 ? 2 : ''}`
    });
  });
  return sampleData;
};

const scrollToRef = (ref) => window.scrollTo({top: ref.current.offsetTop, behavior: 'smooth'});

const SalesUpload = (props) => {
  const [fromSales, salesSummaryId, salesIsSubmitted, outletList, productList, preselectedFormValues] = useMemo(() => [
    _.get(props.location, 'state.fromSales'),
    _.get(props.location, 'state.salesSummaryId'),
    _.get(props.location, 'state.salesIsSubmitted'),
    _.get(props.location, 'state.outletList', []),
    _.get(props.location, 'state.productList', []),
    _.get(props.location, 'state.preselectedFormValues')
  ], [props.location]);
  const authToken = useSelector(({auth}) => auth.authToken);
  const {history} = props;

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [columnError, setColumnError] = useState(false);
  const [dateError, setDateError] = useState([]);
  const [outletError, setOutletError] = useState([]);
  const [productError, setProductError] = useState([]);
  const [unitsError, setUnitsError] = useState([]);

  const [computedSalesData, setComputedSalesData] = useState([]);

  //validations
  const genOutletLookupPhrase = (name, branch) => `n:${name.trim().toLowerCase()}|b:${branch.trim().toLowerCase()}|`;
  const outletDict = useMemo(() => {
    var dict = {};
    outletList.forEach(outlet => {
      dict[genOutletLookupPhrase(_.get(outlet, 'name'), _.get(outlet, 'branch'))] = _.get(outlet, 'id');
    });
    return dict;
  }, [outletList]);

  const genProductLookupPhrase = (name) => `pn:${name.trim().toLowerCase()}|`;
  const productDict = useMemo(() => {
    var dict = {};
    productList.forEach(product => {
      dict[genProductLookupPhrase(_.get(product, 'name'))] = _.get(product, 'id');
    });
    return dict;
  }, [productList])

  //Populate sales form
  useEffect(() => {
    if(computedSalesData.length > 0) {
      history.replace({
        pathname: '/edit_sales_summary',
        state: {
          salesSummaryId,
          salesIsSubmitted,
          preselectedFormValues,
          computedSalesData
        }
      });
    }
  }, [computedSalesData, history, preselectedFormValues, salesIsSubmitted, salesSummaryId]);

  const processJsonData = (jsonData) => {
    var parsedData = [];
    const salesColumn = salesTemplateColumns();
    jsonData.forEach(data => {
      var obj = {};
      // replace empty fields with '' or '0'
      salesColumn.forEach(column => {
        obj[column.accessor] = data[column.Header]
          ? _.toString(data[column.Header])
          : _.includes(['actual_units', 'standard_to', 'additional_to'], column.accessor)
            ? '0'
            : '';
      });
      // add outlet and product lookup phrases
      // TODO: error handling
      obj = {
        ...obj,
        outletLookupPhrase: genOutletLookupPhrase(obj.outlet_name, obj.outlet_branch),
        productLookupPhrase: genProductLookupPhrase(obj.product_name)
      }
      parsedData.push(obj);
    });
    return parsedData;
  };

  const validateColumns = (dataColumns) => {
    var valid = true;
    const salesColumn = salesTemplateColumns();
    salesColumn.forEach(column => {
      if(!_.includes(dataColumns, _.get(column, 'Header'))) {
        setColumnError(true);
        valid = false;
      }
    });
    return valid;
  }

  const validateSalesData = (salesData) => {
    convertSalesWorker.postMessage({msg: 'convertSales', salesData, outletDict, productDict});
    // get results on "convertSalesWorker.onmessage" below
  };

  // Get result from convert sales data WebWorker
  useEffect(() => {
    convertSalesWorker.onmessage = ($event) => {
      if ($event && $event.data) {
        const {computedData, dateError, outletError, productError, unitsError, dataHasError} = $event.data;

        if(!dataHasError){
          setComputedSalesData(computedData);
        } else {
          setDateError(dateError);
          setOutletError(outletError);
          setProductError(productError);
          setUnitsError(unitsError);
          scrollToRef(scrollRef);
        }
      }
      setIsLoading(false);
    };
    convertSalesWorker.onerror = ($event) => {
      console.error("Web Worker Error: ", $event);
    }
  }, []);

  const resetFile = () => {
    setColumnError(false);
    setDateError([]);
    setOutletError([]);
    setProductError([]);
    setUnitsError([]);
    setComputedSalesData([]);
  };

  //Auto scroll
  const scrollRef = useRef(null);

  if(!authToken || !fromSales) {
    return null;
  }

  if(!outletList || !outletList.length || !productList || !productList.length) {
    dispatch(createMessage("Could not load Outlets and Products, please go back to Sales Summary and try again.", "danger"));
    return null;
  }

  return (
    <div>
      { isLoading &&
        <div className="content loading-overlay">
          <h3>Processing Data...</h3>
        </div>
      }
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li><Link to="/sales_summary">Sales Summary</Link></li>
          <li><Link to={{
              pathname: '/edit_sales_summary',
              state: {
                salesSummaryId,
                salesIsSubmitted,
                preselectedFormValues
              }
            }}
          >Sales Summary Details</Link></li>
          <li className="is-active"><a href="# " aria-current="page">Excel Upload</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              Sales Data Upload
            </h1>
          </div>
        </div>
      </section>
      <div className="content">
        <h3>Quick Guide:</h3>
        <ol type="1">
          <li><b>Download Sales Template.</b> Sales Template comes with 3 different tabs: </li>
            <ul>
              <li>"sales" tab: This is where you should fill up your sales data.</li>
              <li>"products" tab: List of all products in the portal for your reference.</li>
              <li>"outlets" tab: List of all outlets in the portal for your reference.</li>
            </ul>
          <li>On the downloaded Excel template, <b>remove the existing sample data from sales tab</b>, and <b>fill up sales tab</b> with
            <ul>
              <li>"Invoice Date" in "YYYY-MM-DD" date format (E.g. 2021-01-31), and within 1 year before and after today's date.</li>
              <li>"Invoice No" (E.g. INV00001)</li>
              <li>"Outlet Name" and "Outlet Branch" that must match with <b>outlets tab’s</b> "Outlet Name" and "Branch" columns</li>
              <li>"Product Name" that must matches with <b>products tab's</b> "Product Name" column</li>
              <li>1 or more of "Actual Units Sold", "Standard TO", and "Additional TO" columns that must be filled up for each row of sales data</li>
            </ul>
          </li>
          <li><b>ATTENTION: DO NOT change the name and sequence of any tabs and columns in the Excel template</b></li>
          <li><b>Upload the updated Excel template</b> using the "Upload Sales Data" button</li>
        </ol>
      </div>
      <div className="buttons pad-top">
        <CSVFromArray
          tabsData={{
            sales: {
              dataArray: sampleSalesData(productList.slice(0, 5), outletList.slice(0, 5)),
              columns: salesTemplateColumns()
            },
            products: {
              dataArray: productList,
              columns: productColumns()
            },
            outlets: {
              dataArray: outletList,
              columns: outletColumns()
            }
          }}
          fileName="PRM-SalesSummaryTemplate"
        >Download Sales Template (with sample data, Product list, and Outlet list)</CSVFromArray>
      </div>
      <div className="buttons pad-top">
        <div className="file is-excel is-loading">
          <label className="file-label">
            <input className="file-input" type="file" name="sales" onClick={e=>e.target.value=''} onChange={e => {
              e.preventDefault();
              resetFile();
              setIsLoading(true);
            
              var files = e.target.files, f = files[0];
              var reader = new FileReader();
              reader.onload = function (e) {
                var xlsxData = e.target.result;
                let readData = XLSX.read(xlsxData, {type: 'binary'});
                const wsname = readData.SheetNames[0];
                const ws = readData.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                const dataColumns = XLSX.utils.sheet_to_json(ws, {header:1})[0];
                if(validateColumns(dataColumns)) {
                  const parsedData = processJsonData(data);
                  validateSalesData(parsedData);
                }
              };
              reader.readAsBinaryString(f);
            }}/>
            <span className="file-cta">
              <span className="file-icon">
                <i className="fas fa-upload"></i>
              </span>
              <span className="file-label">
                Upload Sales Data
              </span>
            </span>
          </label>
        </div>
      </div>
      <div className="pad-top">
        <div className="content" ref={scrollRef}>
          { (columnError || dateError.length > 0 || outletError.length > 0 || productError.length > 0 || unitsError.length > 0) &&
            <p>
              <b className="has-text-danger">* Please fix the data listed below and upload your file again</b>
            </p>
          }
          { columnError &&
            <>
              <b>Invalid columns on upload data, columns must include</b>
              <ul>
                { 
                  salesTemplateColumns().map(column => <li key={_.get(column, 'accessor')}>{_.get(column, 'Header')}</li>)
                }
              </ul>
            </>
          }
          { dateError.length > 0 &&
            <>
              <b>Invalid Invoice Data (must be in “YYYY-MM-DD” date format, and within 1 year before and after today's date)</b>
              <ul>
                { 
                  dateError.map((incorrectValue, index) => <li key={index}>{incorrectValue}</li>)
                }
              </ul>
            </>
          }
          { outletError.length > 0 &&
            <>
              <b>Invalid Outlet Name, Outlet Branch (must exists in Outlet List)</b>
              <ul>
                { 
                  outletError.map((incorrectValue, index) => <li key={index}>{incorrectValue}</li>)
                }
              </ul>
            </>
          }
          { productError.length > 0 &&
            <>
              <b>Invalid Product Name (must exists in Product List)</b>
              <ul>
                { 
                  productError.map((incorrectValue, index) => <li key={index}>{incorrectValue}</li>)
                }
              </ul>
            </>
          }
          { unitsError.length > 0 &&
            <>
              <b>Invalid Actual Units Sold, Standard TO, Additional TO (must be numbers and not all 0)</b>
              <ul>
                { 
                  unitsError.map((incorrectValue, index) => <li key={index}>{incorrectValue}</li>)
                }
              </ul>
            </>
          }
        </div>
      </div>
    </div>
  );
};

SalesUpload.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(SalesUpload);
