import _ from 'lodash';
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import { FormErrors, Select, TextInput, CheckBox, SubmitButton, wholesalerFormRules } from '../../components/Forms';
import { fetchWholesalerList, createWholesaler, updateWholesaler, fetchRegionList, fetchStateList } from '../../actions';

let formRules = _.cloneDeep(wholesalerFormRules);

const WholesalerForm = (props) => {
  const wholesalerToUpdate = _.get(props, 'location.state.wholesalerToUpdate');
  const history = props.history;

  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const isLoading = useSelector(({wholesaler}) => wholesaler.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if(authToken && isAdmin) {
      dispatch(fetchWholesalerList(authToken, isAdmin));
      dispatch(fetchRegionList(authToken));
      dispatch(fetchStateList(authToken));
    }
  }, [authToken, isAdmin, dispatch]);
  const wholesalerList = useSelector(({wholesaler}) => wholesaler.wholesalerList);
  const regionList = useSelector(({master}) => master.regionList);
  const stateList = useSelector(({master}) => master.stateList);
  const isLoadingMaster = useSelector(({master}) => master.isloading);

  // Form Validation
  const verifyUniqueFields = (values) => {
    const {code, name} = values;
    let errors = {};
    const wholesalerListExcludeCurrentWholesaler = wholesalerToUpdate ? wholesalerList.filter(ws => ws.id !== wholesalerToUpdate.id) : wholesalerList;

    formRules.code_taken.pristine = false;
    if(wholesalerListExcludeCurrentWholesaler.some(ws => _.get(ws, 'code','').toLowerCase().trim() === (code||'').toLowerCase().trim())) {
      formRules.code_taken.valid = false;
      errors.code = true;
    } else {
      formRules.code_taken.valid = true;
    }

    formRules.name_taken.pristine = false;
    if(wholesalerListExcludeCurrentWholesaler.some(ws => _.get(ws, 'name','').toLowerCase().trim() === (name||'').toLowerCase().trim())) {
      formRules.name_taken.valid = false;
      errors.name = true;
    } else {
      formRules.name_taken.valid = true;
    }

    return errors;
  }
  const mVerifyUniqueFields = useCallback(verifyUniqueFields, [wholesalerList]);

  const validateWholesalerForm = (values) => {
    const {code, name, region, state} = values;
    let errors = {};

    if(!code) {
      formRules.code_required.valid = false;
      errors.code = true;
    } else {
      formRules.code_required.valid = true;
    }

    if(!name) {
      formRules.name_required.valid = false;
      errors.name = true;
    } else {
      formRules.name_required.valid = true;
    }

    if(!region) {
      formRules.region_required.valid = false;
      errors.region = true;
    } else {
      formRules.region_required.valid = true;
    }

    if(!state) {
      formRules.state_required.valid = false;
      errors.state = true;
    } else {
      formRules.state_required.valid = true;
    }

    return Object.keys(errors).length ? errors : mVerifyUniqueFields(values);
  }
  const mValidateWholesalerForm = useCallback(validateWholesalerForm, [mVerifyUniqueFields]);

  // Reset formRules
  useEffect(() => {
    formRules = _.cloneDeep(wholesalerFormRules);
  }, []);

  // Validate initial values once on CDM - future refactor and remove eslint-disable
  useEffect(() => {
    wholesalerToUpdate && mValidateWholesalerForm(wholesalerToUpdate)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  if(!authToken || !isAdmin) {
    return null;
  }

  const pathname = _.get(props, 'location.pathname');
  if(!wholesalerToUpdate && pathname === '/edit_wholesaler') {
    return <Redirect to='/add_wholesaler'/>;
  }

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li><Link to="/wholesaler">Wholesaler</Link></li>
          <li className="is-active"><a href="# " aria-current="page">{wholesalerToUpdate ? 'Edit ' : 'New '}Wholesaler</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              {wholesalerToUpdate ? 'Edit ' : 'New '}Wholesaler
            </h1>
          </div>
        </div>
      </section>
      <Form
        onSubmit={ values => {
          // if(window.confirm(`Confirm to ${wholesalerToUpdate ? 'update' : 'create'} Wholesaler?`)) {
            if(wholesalerToUpdate) {
              dispatch(updateWholesaler(authToken, isAdmin, values, () => history.push("/wholesaler")));
            } else {
              dispatch(createWholesaler(authToken, isAdmin, values, () => history.push("/wholesaler")));
            }
          // }
        }}
        initialValues={wholesalerToUpdate || {active: true}}
        validate={mValidateWholesalerForm}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <FormErrors formRules={Object.values(formRules)}/>
            <Field name="code" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Wholesaler Code"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="name" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Wholesaler Name"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="region">
              {(props) => (
                <Select
                  label="Region"
                  options={regionList}
                  getOptionLabel={o => o.region}
                  getOptionValue={o => o.id}
                  placeholder="Select Region..."
                  isLoading={isLoadingMaster}
                  isClearable
                  isSearchable
                  {...props}
                />
              )}
            </Field>
            <Field name="state">
              {(props) => (
                <Select
                  label="State"
                  options={stateList}
                  getOptionLabel={o => o.state}
                  getOptionValue={o => o.id}
                  placeholder="Select State..."
                  isLoading={isLoadingMaster}
                  isClearable
                  isSearchable
                  {...props}
                />
              )}
            </Field>
            <Field name="phone" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Phone"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="pic" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Person In Charge"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="address1" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Address 1"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="address2" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Address 2"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="postcode" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Postcode"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="city" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="City"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            { wholesalerToUpdate &&
              <Field name="active" type="checkbox">
                {(props) => (
                  <CheckBox
                    label="Status"
                    desc="Active"
                    disabled={isLoading}
                    {...props}
                  />
                )}
              </Field>
            }
            <SubmitButton
              disabled={pristine || submitting || validating}
              loading={submitting || validating}
            >{wholesalerToUpdate ? 'Update' : 'Add'}</SubmitButton>
          </form>
        )}
      />
    </div>
  );
};

WholesalerForm.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(WholesalerForm);