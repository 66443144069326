import _ from 'lodash';
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import { FormErrors, Select, TextInput, LabelField, Button, stockItemFormRules } from '../../components/Forms';
import { fetchStockBalanceDetails, fetchProductList, createStock, updateStock, deleteStock } from '../../actions';

let formRules = _.cloneDeep(stockItemFormRules);

const StockItemForm = (props) => {
  const stockToUpdate = _.get(props, 'location.state.stockToUpdate');
  const nextSequence = _.get(props, 'location.state.nextSequence');
  const stockBalanceId = _.get(props, 'location.state.stockBalanceId');
  const history = props.history;

  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const isLoading = useSelector(({stock}) => stock.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if(authToken) {
      dispatch(fetchStockBalanceDetails(authToken, stockBalanceId, false));
      dispatch(fetchProductList(authToken));
    }
  }, [authToken, stockBalanceId, dispatch]);
  const stocks = useSelector(({stock}) => stock.stockBalanceDetails.stocks || []);
  const productList = useSelector(({product}) => product.productList.map(product => ({...product, isDisabled: !product.active})));
  const isLoadingMaster = useSelector(({product}) => product.isLoading);

  // Form Validation
  const verifyUniqueFields = (values) => {
    const {product} = values;
    let errors = {};
    const stocksExcludeCurrentStock = stockToUpdate ? stocks.filter(s => s.id !== stockToUpdate.id) : stocks;

    formRules.product_taken.pristine = false;
    if(stocksExcludeCurrentStock.some(s => _.get(s, 'product.id', 0) === product.id)) {
      formRules.product_taken.valid = false;
      errors.product = true;
    } else {
      formRules.product_taken.valid = true;
    }

    return errors;
  }
  const mVerifyUniqueFields = useCallback(verifyUniqueFields, [stocks]);

  const validateStockItemForm = (values) => {
    const {product, balance_units} = values;
    let errors = {};
    if(!product) {
      formRules.product_required.valid = false;
      errors.product = true;
    } else {
      formRules.product_required.valid = true;
    }

    if(isNaN(balance_units||'')) {
      formRules.balance_format.valid = false;
      errors.balance_units = true;
    } else {
      formRules.balance_format.valid = true;
    }

    return Object.keys(errors).length ? errors : mVerifyUniqueFields(values);
  }
  const mValidateStockItemForm = useCallback(validateStockItemForm, [mVerifyUniqueFields]);

  // Reset formRules
  useEffect(() => {
    formRules = _.cloneDeep(stockItemFormRules);
  }, []);

  // Validate initial values once on CDM - future refactor and remove eslint-disable
  useEffect(() => {
    stockToUpdate && mValidateStockItemForm(stockToUpdate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  if(!authToken || !isAdmin) {
    return null;
  }
  const pathname = _.get(props, 'location.pathname');
  if(!(stockToUpdate && stockBalanceId) && pathname === '/edit_stock') {
    return null;
  }
  if(!(nextSequence && stockBalanceId) && pathname === '/add_stock') {
    return null;
  }

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li><Link to="/stock_balance">Stock Balance</Link></li>
          <li><Link to={{
              pathname: '/edit_stock_balance',
              state: {
                stockBalanceId,
                stockIsSubmitted: true
              }
            }}
          >Stock Balance Details</Link></li>
          <li className="is-active"><a href="# " aria-current="page">{stockToUpdate ? 'Edit ' : 'New '}Stock Item</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              {stockToUpdate ? 'Edit ' : 'New '}Stock Item
            </h1>
          </div>
        </div>
      </section>
      <Form
        onSubmit={ values => {
          const reformatted = {
            ...values, 
            stock_balance: {id: stockBalanceId},
            balance_units: parseInt(values.balance_units || 0)
          };
          if(stockToUpdate) {
            dispatch(updateStock(authToken, isAdmin, reformatted, () => history.push({
              pathname: '/edit_stock_balance',
              state: {
                stockBalanceId,
                stockIsSubmitted: true
              }
            })));
          } else {
            dispatch(createStock(authToken, isAdmin, reformatted, () => history.push({
              pathname: '/edit_stock_balance',
              state: {
                stockBalanceId,
                stockIsSubmitted: true
              }
            })));
          }
        }}
        initialValues={stockToUpdate || {stock_balance: {id: stockBalanceId}, sequence: nextSequence}}
        validate={mValidateStockItemForm}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <FormErrors formRules={Object.values(formRules)}/>
            <Field name="product">
              {(props) => {
                const selectedProduct = productList.find(product => product.id === props.input.value.id);
                return(
                  <>
                    <Select
                      label="Product Name"
                      options={productList}
                      value={selectedProduct}
                      getOptionLabel={p => p.name}
                      getOptionValue={p => p.id}
                      placeholder="Select Product..."
                      isLoading={isLoadingMaster || isLoading}
                      isClearable
                      isSearchable
                      {...props}
                    />
                    <LabelField label="SKU No.">{_.get(selectedProduct, 'sku_no')||''}</LabelField>
                    <LabelField label="Pack Size">{_.get(selectedProduct, 'pack_size')||''}</LabelField>
                    <LabelField label="Bottle Size (cl)">{_.get(selectedProduct, 'bottle_size')||''}</LabelField>
                  </>
                );
              }}
            </Field>
            <Field name="balance_units" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Balance Units (Bottles)"
                  disabled={isLoading}
                  onBlur = {e => {
                    e.target.value = e.target.value.trim();
                    if(e.target.value === '')
                      e.target.value = '0';
                  }}
                  onFocus = {e => {
                    e.target.value = e.target.value.trim();
                    if(e.target.value === '0')
                      e.target.value = '';
                  }}
                  {...props}
                />
              )}
            </Field>
            <div className="column is-full buttons" style={{marginTop: '50px', justifyContent: 'center'}}>
              <Button
                type="submit"
                className="is-primary"
                disabled={pristine || submitting || validating}
                loading={submitting || validating}
              >{stockToUpdate ? 'Update' : 'Add'}</Button>
              { stockToUpdate &&
                <Button
                  type="button"
                  className="is-danger"
                  onClick={() => {
                    if(window.confirm('Confirm to delete Stock Item?')) {
                      dispatch(deleteStock(authToken, isAdmin, stockToUpdate.id, () => history.push({
                        pathname: '/edit_stock_balance',
                        state: {
                          stockBalanceId,
                          stockIsSubmitted: true
                        }
                      })));
                    }
                  }}
                  disabled={submitting || validating}
                  loading={submitting || validating}
                >Delete</Button>
              }
            </div>
          </form>
        )}
      />
    </div>
  );
};

StockItemForm.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(StockItemForm);