import React, { useState, useCallback } from 'react';
import { Switch, Route } from 'react-router-dom';
import 'react-table/react-table.css';
import 'react-datepicker/dist/react-datepicker.css';
import './App.sass';

import NavBar from './containers/NavBar';
import Footer from './components/Footer';
import MessageHandler from './containers/MessageHandler';
import Message from './components/Message';
import Ping from './components/Ping';
import Login from './containers/Auths/Login';
import ForgotPassword from './containers/Auths/ForgotPassword';
import ResetPassword from './containers/Auths/ResetPassword';
import ChangePassword from './containers/Auths/ChangePassword';
import Dashboard from './containers/Dashboard';
import UserList from './containers/Users/UserList';
import UserForm from './containers/Users/UserForm';
import ProductList from './containers/Products/ProductList';
import ProductForm from './containers/Products/ProductForm';
import WholesalerList from './containers/Wholesalers/WholesalerList';
import WholesalerForm from './containers/Wholesalers/WholesalerForm';
import OutletList from './containers/Outlets/OutletList';
import OutletForm from './containers/Outlets/OutletForm';
import SalesList from './containers/Sales/SalesList';
import SalesForm from './containers/Sales/SalesForm';
import SalesItemForm from './containers/Sales/SalesItemForm';
import SalesUpload from './containers/Sales/SalesUpload';
import StockList from './containers/Stocks/StockList';
import StockForm from './containers/Stocks/StockForm';
import StockItemForm from './containers/Stocks/StockItemForm';
import Report from './containers/Reports/Report';
import SalesReport from './containers/Reports/SalesReport';
import WholesalerSalesSummaryReport from './containers/Reports/WholesalerSalesSummaryReport';
import OutletSalesSummaryReport from './containers/Reports/OutletSalesSummaryReport';
import StockBalanceReport from './containers/Reports/StockBalanceReport';
import StockBalanceSummaryReport from './containers/Reports/StockBalanceSummaryReport';
import NewOutletsListing from './containers/Reports/NewOutletsListing';
import SalesforceReport from './containers/Reports/SalesforceReport';

let timer = null;

const App = () => {
  const [messageName, setMessageName] = useState(null);
  const [messageType, setMessageType] = useState(null);

  const createMessage = (name='Sanity Check', type='success', timeout=10000) => {
    if(timer) {
      clearTimeout(timer);
      timer = null;
    }

    setMessageName(name);
    setMessageType(type);

    timer = setTimeout(() => {
      removeMessage();
      timer = null;
    }, timeout);
  };

  const removeMessage = () => {
    setMessageName(null);
    setMessageType(null);
  };

  const mCreateMessage = useCallback(createMessage, []);
  const mRemoveMessage = useCallback(removeMessage, []);

  return (
    <div className="App">
      <NavBar/>
      <section className="section">
        <div className="container is-fluid">
          <MessageHandler createMessage={mCreateMessage} removeMessage={mRemoveMessage}/>
          { messageName && messageType &&
            <Message
              messageName={messageName}
              messageType={messageType}
              removeMessage={removeMessage}
            />
          }
          <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/ping' component={Ping} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/admin_login' render={() => <Login isAdmin/>} />
            <Route exact path='/forgot_password' component={ForgotPassword} />
            <Route exact path='/reset_password' component={ResetPassword} />
            <Route exact path='/change_password' component={ChangePassword} />
            <Route exact path='/admin' render={() => <UserList forAdmin={true} /> } />
            <Route exact path='/executive' render={() => <UserList forAdmin={false} /> } />
            <Route exact path='/add_admin' render={() => <UserForm forAdmin={true} /> } />
            <Route exact path='/edit_admin' render={() => <UserForm forAdmin={true} /> } />
            <Route exact path='/add_executive' render={() => <UserForm forAdmin={false} /> } />
            <Route exact path='/edit_executive' render={() => <UserForm forAdmin={false} /> } />
            <Route exact path='/product' component={ProductList} />
            <Route exact path='/add_product' component={ProductForm} />
            <Route exact path='/edit_product' component={ProductForm} />
            <Route exact path='/wholesaler' component={WholesalerList} />
            <Route exact path='/add_wholesaler' component={WholesalerForm} />
            <Route exact path='/edit_wholesaler' component={WholesalerForm} />
            <Route exact path='/outlet' component={OutletList} />
            <Route exact path='/add_outlet' component={OutletForm} />
            <Route exact path='/edit_outlet' component={OutletForm} />
            <Route exact path='/sales_summary' component={SalesList} />
            <Route exact path='/add_sales_summary' component={SalesForm} />
            <Route exact path='/edit_sales_summary' component={SalesForm} />
            <Route exact path='/add_sale' component={SalesItemForm} />
            <Route exact path='/edit_sale' component={SalesItemForm} />
            <Route exact path='/upload_sales' component={SalesUpload} />
            <Route exact path='/stock_balance' component={StockList} />
            <Route exact path='/add_stock_balance' component={StockForm} />
            <Route exact path='/edit_stock_balance' component={StockForm} />
            <Route exact path='/add_stock' component={StockItemForm} />
            <Route exact path='/edit_stock' component={StockItemForm} />
            <Route exact path='/reports' component={Report} />
            <Route exact path='/reports/sales_report' component={SalesReport} />
            <Route exact path='/reports/wholesaler_sales_summary_report' component={WholesalerSalesSummaryReport} />
            <Route exact path='/reports/outlet_sales_summary_report' component={OutletSalesSummaryReport} />
            <Route exact path='/reports/stock_balance_report' component={StockBalanceReport} />
            <Route exact path='/reports/stock_balance_summary_report' component={StockBalanceSummaryReport} />
            <Route exact path='/reports/new_outlets_listing' component={NewOutletsListing} />
            <Route exact path='/reports/salesforce_report' component={SalesforceReport} />
          </Switch>
        </div>
      </section>
      <Footer/>
    </div>
  );
};

export default App;
