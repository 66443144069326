import {
  LOADING_USER,
  FETCH_ADMIN_LIST,
  FETCH_EXECUTIVE_LIST,
  FETCH_ALL_USER_LIST,
  CREATE_ADMIN,
  CREATE_EXECUTIVE,
  UPDATE_ADMIN,
  UPDATE_EXECUTIVE,
  COMPLETE_USER,
} from '../actions';

const INITIAL_STATE = {
  isLoading: false,
  adminList: [],
  executiveList: [],
  allUserList: []
};

export default (state = INITIAL_STATE, action) => {
  const {type, ...rest} = action;
  switch(type) {
    case LOADING_USER: {
      return {...state, isLoading: true};
    }

    case FETCH_ADMIN_LIST:
    case FETCH_EXECUTIVE_LIST:
    case FETCH_ALL_USER_LIST: {
      return {...state, ...rest, isLoading: false};
    }

    case CREATE_ADMIN: {
      const {newUser} = action;
      const adminList = [...state.adminList, newUser];
      const allUserList = [...state.allUserList, newUser];
      return {...state, adminList, allUserList, isLoading: false};
    }

    case CREATE_EXECUTIVE: {
      const {newUser} = action;
      const executiveList = [...state.executiveList, newUser];
      const allUserList = [...state.allUserList, newUser];
      return {...state, executiveList, allUserList, isLoading: false};
    }

    case UPDATE_ADMIN: {
      const {changedUser} = action;
      const adminList = state.adminList.map(user => user.id === changedUser.id ? changedUser : user);
      const allUserList = state.allUserList.map(user => user.id === changedUser.id ? changedUser : user);
      return {...state, adminList, allUserList, isLoading: false};
    }

    case UPDATE_EXECUTIVE: {
      const {changedUser} = action;
      const executiveList = state.executiveList.map(user => user.id === changedUser.id ? changedUser : user);
      const allUserList = state.allUserList.map(user => user.id === changedUser.id ? changedUser : user);
      return {...state, executiveList, allUserList, isLoading: false};
    }

    case COMPLETE_USER: {
      return {...state, isLoading: false};
    }

    default: {
      return state;
    }
  }
};