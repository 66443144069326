import {
  LOADING_STOCK,
  FETCH_ALL_STOCK_BALANCE,
  FETCH_STOCK_BALANCE_LIST,
  FETCH_STOCK_BALANCE_DETAILS,
  CLEAR_STOCK_BALANCE_DETAILS,
  FETCH_STOCK_FOR_CLONING,
  FETCH_STOCK_BALANCE_REPORT,
  COMPLETE_STOCK
} from '../actions';

const INITIAL_STATE = {
  isLoading: false,
  allStockBalance: [],
  stockBalanceList: [],
  stockBalanceDetails: {},
  stockForCloning: {}
};

export default (state = INITIAL_STATE, action) => {
  const {type, ...rest} = action;
  switch(type) {
    case LOADING_STOCK: {
      return {...state, isLoading: true};
    }

    case FETCH_ALL_STOCK_BALANCE:
    case FETCH_STOCK_BALANCE_LIST:
    case FETCH_STOCK_BALANCE_DETAILS:
    case FETCH_STOCK_FOR_CLONING:
    case FETCH_STOCK_BALANCE_REPORT: {
      return {...state, ...rest, isLoading: false};
    }

    case CLEAR_STOCK_BALANCE_DETAILS: {
      return {...state, stockBalanceDetails: {}, stockForCloning: {}, allStockBalance: []};
    }

    case COMPLETE_STOCK: {
      return {...state, isLoading: false};
    }

    default: {
      return state;
    }
  }
};