import client from './client';
import { createMessage } from '.';
import { compareStrings } from './sortHelper';

export const LOADING_WHOLESALER = 'loading_wholesaler';
export const FETCH_WHOLESALER_LIST = 'fetch_wholesaler_list';
export const CREATE_WHOLESALER = 'create_wholesaler';
export const UPDATE_WHOLESALER = 'update_wholesaler';
export const COMPLETE_WHOLESALER = 'complete_wholesaler';

export const fetchWholesalerList = (authToken, isAdmin) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_WHOLESALER
      });

      client.getWholesalers(authToken)
      .then(({data}) => {
        dispatch({
          type: FETCH_WHOLESALER_LIST,
          wholesalerList: data.sort(compareStrings(['name']))
        });
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_WHOLESALER
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
};

export const createWholesaler = (authToken, isAdmin, values, redirectOnSuccess) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_WHOLESALER
      });

      const {code, name, region, state, phone, pic, address1, address2, postcode, city} = values;
      client.postWholesaler(authToken, code, name, region, state, phone, pic, address1, address2, postcode, city)
      .then(({data}) => {
        dispatch(createMessage('Wholesaler created successfully.', 'success'));
        dispatch({
          type: CREATE_WHOLESALER,
          newWholesaler: data
        });
        redirectOnSuccess();
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_WHOLESALER
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
}

export const updateWholesaler = (authToken, isAdmin, values, redirectOnSuccess) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_WHOLESALER
      });

      const {id, code, name, region, state, phone, pic, address1, address2, postcode, city, active} = values;
      client.putWholesaler(authToken, id, code, name, region, state, phone, pic, address1, address2, postcode, city, active)
      .then(({data}) => {
        dispatch(createMessage('Wholesaler updated successfully.', 'success'));
        dispatch({
          type: UPDATE_WHOLESALER,
          changedWholesaler: data
        });
        redirectOnSuccess();
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_WHOLESALER
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
}
