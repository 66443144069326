import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import { FormErrors, TextInput, SubmitButton, changePasswordFormRules } from '../../components/Forms';
import { changePassword } from '../../actions';

// Form Validation
let formRules = _.cloneDeep(changePasswordFormRules);
const validateCPForm = (values) => {
  const {password, new_password, confirm_new_password} = values;
  let errors = {};

  if(!password) {
    formRules.password_required.valid = false;
    errors.password = true;
  } else {
    formRules.password_required.valid = true;
  }

  if(!new_password || new_password.length < 8) {
    formRules.new_password_length.valid = false;
    errors.new_password = true;
  } else {
    formRules.new_password_length.valid = true;

    if(!confirm_new_password || new_password !== confirm_new_password) {
      formRules.new_password_match.valid = false;
      errors.confirm_new_password = true;
    } else {
      formRules.new_password_match.valid = true;
    }
  }

  return errors;
}

const ChangePassword = (props) => {
  const authToken = useSelector(({auth}) => auth.authToken);
  const isLoading = useSelector(({auth}) => auth.isLoading);

  const dispatch = useDispatch();

  // Reset formRules
  useEffect(() => {
    formRules = _.cloneDeep(changePasswordFormRules);
  }, []);

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li className="is-active"><a href="# " aria-current="page">Change Password</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              Change Password
            </h1>
          </div>
        </div>
      </section>
      <Form
        onSubmit={ values => {
          if(window.confirm("Confirm to change password? You will be logged out after your password is changed.")) {
            dispatch(changePassword(authToken, values));
          }
        }}
        validate={validateCPForm}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <FormErrors formRules={Object.values(formRules)}/>
              <Field name="password" type="password">
                {(props) => (
                  <TextInput
                    horizontal
                    label="Current Password"
                    disabled={isLoading}
                    {...props}
                  />
                )}
              </Field>
              <Field name="new_password" type="password">
                {(props) => (
                  <TextInput
                    horizontal
                    label="New Password"
                    disabled={isLoading}
                    {...props}
                  />
                )}
              </Field>
              <Field name="confirm_new_password" type="password">
                {(props) => (
                  <TextInput
                    horizontal
                    label="Confirm New Password"
                    disabled={isLoading}
                    {...props}
                  />
                )}
              </Field>
            <SubmitButton
              disabled={submitting || validating}
            >Change Password</SubmitButton>
          </form>
        )}
      />
    </div>
  );
};

export default ChangePassword;