export const loginFormRules = {
  email_format: {
    field: 'email',
    desc: 'Email format must be valid.',
    valid: false
  },
  password_length: {
    field: 'password',
    desc: 'Password must be at least 8 characters long.',
    valid: false
  }
};

export const forgotPasswordFormRules = {
  email_format: {
    field: 'email',
    desc: 'Email format must be valid.',
    valid: false
  }
};

export const resetPasswordFormRules = {
  secret_required: {
    field: 'secret_key',
    desc: 'Secret Key is required.',
    valid: false
  },
  password_length: {
    field: 'password',
    desc: 'New Password must be at least 8 characters long.',
    valid: false
  },
  password_match: {
    field: 'confirm_password',
    desc: 'New Password did not match.',
    valid: false
  }
};

export const changePasswordFormRules = {
  password_required: {
    field: 'password',
    desc: 'Current Password is required.',
    valid: false
  },
  new_password_length: {
    field: 'new_password',
    desc: 'New Password must be at least 8 characters long.',
    valid: false
  },
  new_password_match: {
    field: 'confirm_new_password',
    desc: 'New Password did not match.',
    valid: false
  }
};

export const userFormRules = (forAdmin, editUser) => ({
  ...(!forAdmin && {
    wholesaler_required: {
      field: 'wholesaler',
      desc: 'Wholesaler is required.',
      valid: false
    }
  }),
  username_required: {
    field: 'username',
    desc: 'Username is required.',
    valid: false
  },
  email_format: {
    field: 'email',
    desc: 'Email format must be valid.',
    valid: false
  },
  ...(!editUser && {
    password_length: {
      field: 'password',
      desc: 'Password must be at least 8 characters long.',
      valid: false
    },
    password_match: {
      field: 'confirm_password',
      desc: 'Password did not match.',
      valid: false
    }
  }),
  username_taken: {
    field: 'username',
    desc: 'Username is not taken.',
    valid: false,
    loading: false,
    pristine: true
  },
  email_taken: {
    field: 'email',
    desc: 'Email is not taken.',
    valid: false,
    loading: false,
    pristine: true
  }
});

export const productFormRules = {
  brand_required: {
    field: 'brand',
    desc: 'Brand is required.',
    valid: false
  },
  sub_brand_required: {
    field: 'sub_brand',
    desc: 'Sub Brand is required.',
    valid: false
  },
  name_required: {
    field: 'name',
    desc: 'Product Name is required.',
    valid: false
  },
  sku_required: {
    field: 'sku_no',
    desc: 'SKU NO. is required',
    valid: false
  },
  market_required: {
    field: 'market',
    desc: 'Market is required.',
    valid: false
  },
  name_taken: {
    field: 'name',
    desc: 'Product Name is not taken.',
    valid: false,
    loading: false,
    pristine: true
  },
};

export const wholesalerFormRules = {
  code_required: {
    field: 'code',
    desc: 'Wholesaler Code is required.',
    valid: false
  },
  name_required: {
    field: 'name',
    desc: 'Wholesaler Name is required.',
    valid: false
  },
  region_required: {
    field: 'region',
    desc: 'Region is required.',
    valid: false
  },
  state_required: {
    field: 'state',
    desc: 'State is required.',
    valid: false
  },
  code_taken: {
    field: 'code',
    desc: 'Wholesaler Code is not taken.',
    valid: false,
    loading: false,
    pristine: true
  },
  name_taken: {
    field: 'name',
    desc: 'Wholesaler Name is not taken.',
    valid: false,
    loading: false,
    pristine: true
  },
};

export const outletFormRules = {
  name_required: {
    field: 'name',
    desc: 'Outlet Name is required.',
    valid: false
  },
  contract_required: {
    field: 'contract',
    desc: 'Contract Type is required.',
    valid: false
  },
  outlet_type_required: {
    field: 'outlet_type',
    desc: 'Outlet Type is required.',
    valid: false
  },
  legal_name_required: {
    field: 'legal_name',
    desc: 'Legal Name is required.',
    valid: false
  },
  state_required: {
    field: 'state',
    desc: 'State is required.',
    valid: false
  },
  name_branch_taken: {
    field: ['name', 'branch'],
    desc: 'Outlet Name and Branch is not taken.',
    valid: false,
    loading: false,
    pristine: true
  },
};

export const salesListRules = {
  year_format: {
    field: 'year',
    desc: 'Year must be in \'YYYY\' format'
  }
};

export const salesFormRules = {
  wholesaler_required: {
    desc: 'Wholesaler is required.',
    valid: false
  },
  month_required: {
    desc: 'Month is required.',
    valid: false
  },
  month_taken: {
    desc: 'Month is not taken.',
    valid: false
  },
  invoice_date_required: {
    desc: 'Invoice Date is required.',
    valid: false,
    loading: false,
    pristine: true
  },
  invoice_no_requried: {
    desc: 'Invoice No. is required.',
    valid: false,
    loading: false,
    pristine: true
  },
  outlet_required: {
    desc: 'Outlet is required.',
    valid: false,
    loading: false,
    pristine: true
  },
  product_required: {
    desc: 'Product is required.',
    valid: false,
    loading: false,
    pristine: true
  },
  units_required: {
    desc: 'Total of Actual Units Sold, Standard TO, and Additional TO must not be zero.',
    valid: false,
    loading: false,
    pristine: true
  },
  invoice_product_taken: {
    desc: 'Invoice No. and Product is not duplicated.',
    valid: false,
    loading: false,
    pristine: true
  }
};

export const salesItemFormRules = {
  invoice_date_required: {
    desc: 'Invoice Date is required.',
    valid: false
  },
  invoice_no_requried: {
    desc: 'Invoice No. is required.',
    valid: false
  },
  outlet_required: {
    desc: 'Outlet is required.',
    valid: false
  },
  product_required: {
    desc: 'Product is required.',
    valid: false
  },
  actual_units_format: {
    desc: 'Actual Units Sold must be in number.',
    valid: false
  },
  standard_to_format: {
    desc: 'Standard TO must be in number.',
    valid: false
  },
  additional_to_format: {
    desc: 'Additional TO must be in number.',
    valid: false
  },
  units_required: {
    desc: 'Total of Actual Units Sold, Standard TO, and Additional TO must not be zero.',
    valid: false
  },
  invoice_product_taken: {
    desc: 'Invoice No. and Product is not duplicated.',
    valid: false,
    loading: false,
    pristine: true
  }
};

export const stockListRules = {
  year_format: {
    field: 'year',
    desc: 'Year must be in \'YYYY\' format'
  }
};

export const stockFormRules = {
  wholesaler_required: {
    desc: 'Wholesaler is required.',
    valid: false
  },
  month_required: {
    desc: 'Month is required.',
    valid: false
  },
  month_taken: {
    desc: 'Month is not taken.',
    valid: false
  },
  product_required: {
    desc: 'Product is required.',
    valid: false,
    loading: false,
    pristine: true
  },
  product_taken: {
    desc: 'Product is not duplicated.',
    valid: false,
    loading: false,
    pristine: true
  }
};

export const stockItemFormRules = {
  product_required: {
    desc: 'Product is required.',
    valid: false
  },
  balance_format: {
    desc: 'Actual Units Sold must be in number.',
    valid: false
  },
  product_taken: {
    desc: 'Product is not duplicated.',
    valid: false,
    loading: false,
    pristine: true
  }
};

export const reportRules = {
  date_range: {
    desc: 'Start date must be before End Date',
    valid: false
  }
};

export const deactivateMessage = {
  message: {
    desc: 'Records can only be Deactivated, but cannot be deleted, in order to keep historical data.',
    info: true,
  }
}
