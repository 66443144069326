import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers";
import thunk from 'redux-thunk';
import { persistStore } from "redux-persist";

const configureStore = initialState => {
  const store = createStore(
    rootReducer,
    applyMiddleware(
      thunk
    )
  )
  const persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
