import _ from 'lodash';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { Form, Field } from 'react-final-form';
import moment from 'moment';

import { FormErrors, Select, DatePicker, TextInput, LabelField, Button, stockFormRules } from '../../components/Forms';
import { fetchAllStockBalance, fetchStockBalanceDetails, clearStockBalanceDetails, fetchStockForCloning, fetchWholesalerList, fetchProductList, createStockBalance, updateDraftStock, submitDraftStock, revertSubmittedStock, createMessage } from '../../actions';
import EncryptStorage from '../../components/EncryptStrorage';

// Stock table columns
const numberColumn = {
  Header: 'No.',
  accessor: 'sequence',
  Cell: row => (row.index + 1),
  Footer: (
    <span className="has-text-grey-light">+</span>
  ),
  minWidth: 50
};
const submittedNumberColumn = {
  Header: 'No.',
  accessor: 'sequence',
  Cell: row => (row.index + 1),
  minWidth: 50
};
const productColumn = (stocks, setStocks, productList) => ({
  Header: 'Product Name',
  accessor: 'product.id',
  Cell: row => {
    // const filteredProductList = productList.filter(product => !stocks.some((stock, index) => index !== row.index && _.get(stock, 'product.id', 0) === product.id));
    return(
      <Select
        options={productList}
        getOptionLabel={p => p.name}
        getOptionValue={p => p.id}
        placeholder="Select Product..."
        isSearchable
        menuPortalTarget={document.querySelector("body")}
        value={productList.find(product => product.id === row.value)}
        onChange={selected => {
          let newStocks = _.cloneDeep(stocks);
          newStocks[row.index].product = selected;
          setStocks(newStocks);
        }}
      />
    );
  },
  minWidth: 300
});
const submittedProductColumn = (productList) => ({
  Header: 'Product Name',
  accessor: 'product.id',
  Cell: row => (
    _.get(productList.find(product => product.id === row.value), 'name', '')
  ),
  minWidth: 300
});
const skuColumn = (productList) => ({
  Header: 'SKU No.',
  accessor: 'product.id',
  Cell: row => (
    _.get(productList.find(product => product.id === row.value), 'sku_no', '')
  ),
  minWidth: 100
});
const packColumn = (productList) => ({
  Header: 'Pack Size',
  accessor: 'product.id',
  Cell: row => (
    _.get(productList.find(product => product.id === row.value), 'pack_size', '')
  ),
  minWidth: 150
});
const bottleColumn = (productList) => ({
  Header: 'Bottle Size (cl)',
  accessor: 'product.id',
  Cell: row => (
    _.get(productList.find(product => product.id === row.value), 'bottle_size', '')
  ),
  minWidth: 100
});
const balanceUnitsColumn = (stocks, setStocks) => ({
  Header: 'Balance Units (Bottles)',
  accessor: 'balance_units',
  Cell: row => (
    <TextInput
      input={{
        type: 'text',
        defaultValue: ''+(row.value||0),
        onBlur: e => {
          if(e.target.value === '')
            e.target.value = '0'
          if(e.target.value !== ''+(row.value||0)) {
            let newStocks = _.cloneDeep(stocks);
            if(!isNaN(e.target.value)) {
              newStocks[row.index].balance_units = +(e.target.value);
            }
            setStocks(newStocks);
          }
        },
        onFocus: (e) => {
          if(e.target.value === '0')
            e.target.value = ''
        }
      }}
    />
  ),
  minWidth: 200
});
const submittedBalanceUnitsColumn = {
  Header: 'Balance Units (Bottles)',
  accessor: 'balance_units',
  Cell: row => (''+(row.value||0)),
  minWidth: 200
};
const addRemoveColumn = (stocks, setStocks, setShouldScrollTable) => ({
  Header: '',
  id: 'edit',
  accessor: row => row,
  Cell: row => (
    <div className="has-text-centered">
      <button
        type="button"
        className="button is-danger is-outlined" 
        onClick={e => {
          e.preventDefault();
          if(window.confirm('Confirm to remove row?')) {
            let newStocks = _.cloneDeep(stocks);
            newStocks.splice(row.index, 1);
            setStocks(newStocks);
          }
        }}
      >
        <span className="icon">
          <i className="fas fa-minus"/>
        </span>
      </button>
    </div>
  ),
  Footer: () => (
    <div className="has-text-centered">
      <button
        type="button"
        className="button is-success is-outlined" 
        onClick={e => {
          e.preventDefault();
          let newStocks = _.cloneDeep(stocks);
          newStocks.push({});
          setStocks(newStocks);
          setShouldScrollTable(true);
        }}
      >
        <span className="icon">
          <i className="fas fa-plus"/>
        </span>
      </button>
    </div>
  ),
  minWidth: 50
});
const editColumn = (stockBalanceId) => ({
  Header: '',
  id: 'edit',
  accessor: row => row,
  Cell: row => (
    <div className="has-text-centered">
      <Link className="button is-primary is-outlined" to={{
        pathname: '/edit_stock',
        state: {
          stockToUpdate: row.value,
          stockBalanceId
        }
      }}>
        <span className="icon">
          <i className="fas fa-edit"/>
        </span>
      </Link>
    </div>
  ),
  Footer: ({data}) => (
    <div className="has-text-centered">
      <Link className="button is-success is-outlined" to={{
        pathname: '/add_stock',
        state: {
          nextSequence: data ? _.get(data[data.length-1], 'sequence', 0) + 1 : 1,
          stockBalanceId
        }
      }}>
        <span className="icon">
          <i className="fas fa-plus"/>
        </span>
      </Link>
    </div>
  ),
  minWidth: 50
});

// Form validations
let formRules = _.cloneDeep(stockFormRules);
let indexWithErrors = [];

const emptyFormDefaultValue = [{}];

const _handleKeyDown = (triggerNewLineEvent, setTriggerNewLineEvent) => e => {
  if(e.key === 'Enter') {
    e.target.blur();
    if(!triggerNewLineEvent)
      setTriggerNewLineEvent(true);
  }
}

const StockForm = (props) => {
  const [stockBalanceId, stockIsSubmitted] = useMemo(() => [_.get(props.location, 'state.stockBalanceId'), _.get(props.location, 'state.stockIsSubmitted')], [props.location]);
  const history = props.history;

  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const currentWholesaler = useSelector(({auth}) => auth.currentUser ? auth.currentUser.wholesaler : null);
  const isLoading = useSelector(({stock}) => stock.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearStockBalanceDetails());
    return () => dispatch(clearStockBalanceDetails());
  }, [dispatch]);

  useEffect(() => {
    if(authToken) {
      if(stockBalanceId) {
        dispatch(fetchStockBalanceDetails(authToken, stockBalanceId, !stockIsSubmitted));
      } else {
        dispatch(fetchAllStockBalance(authToken));
        if(isAdmin) {
          dispatch(fetchWholesalerList(authToken, isAdmin));
        }
      }
      dispatch(fetchProductList(authToken));
    }
  }, [authToken, stockBalanceId, stockIsSubmitted, isAdmin, dispatch]);
  const stockBalanceList = useSelector(({stock}) => stock.allStockBalance);
  const stockBalanceDetails = useSelector(({stock}) => stockBalanceId ? stock.stockBalanceDetails : undefined);
  const wholesalerList = useSelector(({wholesaler}) => isAdmin ? wholesaler.wholesalerList.map(ws => ({...ws, isDisabled: !ws.active})) : undefined);
  const productList = useSelector(({product}) => product.productList.map(product => ({...product, isDisabled: !product.active})));
  const isLoadingMaster = useSelector(({product, wholesaler}) => product.isLoading || wholesaler.isLoading);

  const [formWholesaler, setFormWholesaler] = useState();
  const [formDate, setFormDate] = useState(moment());

  const [stocks, setStocks] = useState([]);
  useEffect(() => {
    let tempStocks = _.get(stockBalanceDetails, 'stocks');
    if(!tempStocks || !tempStocks.length) {
      tempStocks = stockIsSubmitted ? [] : emptyFormDefaultValue;
    }
    setStocks(tempStocks);
  }, [stockBalanceDetails, stockIsSubmitted]);

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const tempStocks = _.get(stockBalanceDetails, 'stocks');
    if(stockBalanceId && stockBalanceDetails && tempStocks && !stockIsSubmitted) {
      let stocksOnStorage = EncryptStorage.getStocksItem(stockBalanceId);
      if(stocksOnStorage && !_.isEqual(stocksOnStorage, tempStocks) && !_.isEqual(stocksOnStorage, emptyFormDefaultValue)) {
        if(window.confirm("Do you want to load your unsaved changes?")) {
          setStocks(stocksOnStorage);
        } else {
          EncryptStorage.removeStocksItem(stockBalanceId);
        }
      }
      setLoaded(true);
    }
    if(!stockBalanceId) {
      let stocksOnStorage = EncryptStorage.getStocksItem(0);
      if(stocksOnStorage && !_.isEqual(stocksOnStorage, emptyFormDefaultValue)) {
        if(window.confirm("Do you want to load your unsaved changes?")) {
          let stocksFormStorage = EncryptStorage.getItem('stocksForm') || {};
          if(stocksFormStorage && stocksFormStorage.formWholesaler) {
            setFormWholesaler(stocksFormStorage.formWholesaler);
          }
          if(stocksFormStorage && stocksFormStorage.formDate) {
            setFormDate(stocksFormStorage.formDate);
          }
          setStocks(stocksOnStorage);
        } else {
          EncryptStorage.removeStocksItem(0);
        }
      }
      setLoaded(true);
    }
  }, [stockBalanceId, stockBalanceDetails, stockIsSubmitted]);

  useEffect(() => {
    if(loaded) {
      if (stockBalanceId) {
        EncryptStorage.setStocksItem(stockBalanceId, stocks);
      } else {
        EncryptStorage.setStocksItem(0, stocks);
      }
    }
  }, [stockBalanceId, stocks, loaded, stockIsSubmitted]);

  useEffect(() => {
    if(loaded) {
      const stocksFormStorage = {formWholesaler, formDate};
      EncryptStorage.setItem('stocksForm', stocksFormStorage);
    }
  }, [loaded, formWholesaler, formDate]);

  // scroll table on new line added
  const [shouldScrollTable, setShouldScrollTable] = useState(false);
  useEffect(() => {
    if(shouldScrollTable) {
      document.getElementsByClassName('rt-tbody')[0].scrollTop = Number.MAX_SAFE_INTEGER;
      setShouldScrollTable(false);
    }
  }, [stocks, shouldScrollTable]);

  const stockColumns = useMemo(() => [
    numberColumn,
    productColumn(stocks, setStocks, productList),
    skuColumn(productList),
    packColumn(productList),
    bottleColumn(productList),
    balanceUnitsColumn(stocks, setStocks),
    addRemoveColumn(stocks, setStocks, setShouldScrollTable)
  ], [stocks, setStocks, productList]);
  const submittedStockColumn = useMemo(() => [
    submittedNumberColumn,
    submittedProductColumn(productList),
    skuColumn(productList),
    packColumn(productList),
    bottleColumn(productList),
    submittedBalanceUnitsColumn,
  ].concat(isAdmin ? [editColumn(stockBalanceId)] : []), [productList, isAdmin, stockBalanceId]);

  // Reset formRules
  useEffect(() => {
    formRules = _.cloneDeep(stockFormRules);
  }, []);

  // Form Validation
  const validateStockForm = (values) => {
    const {wholesaler, date} = values;
    let errors = {};

    if(!wholesaler) {
      formRules.wholesaler_required.valid = false;
      errors.wholesaler = true;
    } else {
      formRules.wholesaler_required.valid = true;
    }

    if(!date) {
      formRules.month_required.valid = false;
      errors.date = true;
    } else {
      formRules.month_required.valid = true;
    }

    if(wholesaler && date) {
      if(!stockBalanceId && stockBalanceList.some(sb => sb.wholesaler_id === wholesaler.id && moment(sb.date).format('MM-YYYY') === moment(date).format('MM-YYYY'))) {
        formRules.month_taken.valid = false;
        errors.date = true;
      } else {
        formRules.month_taken.valid = true;
      }
    }

    return errors;
  };
  const mValidateStockForm = useCallback(validateStockForm, [stockBalanceId, stockBalanceList]);

  const validationOnSubmit = (values) => {
    let errors = {};

    formRules.product_required.pristine = false;
    formRules.product_taken.pristine = false;

    let product_required = true;
    let product_taken = true;
    indexWithErrors = [];

    values.forEach((value, index) => {
      let hasError = false;
      if(!value.product) {
        product_required = false;
        hasError = true;
        errors.stocks = true;
      } else if(stocks.some((someV, someI) => someI !== index && _.get(someV, 'product.id', 0) === value.product.id)) {
        product_taken = false;
        hasError = true;
        errors.stocks = true;
      }

      if(hasError)
        indexWithErrors.push(index);
    });

    formRules.product_required.valid = product_required;
    formRules.product_taken.valid = product_taken;

    return errors;
  }
  const mValidationOnSubmit = useCallback(validationOnSubmit, [stocks]);

  // Validate initial values once on CDM - future refactor and remove eslint-disable
  useEffect(() => {
    stockBalanceDetails && mValidateStockForm(stockBalanceDetails)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle Enter Event
  const [triggerNewLineEvent, setTriggerNewLineEvent] = useState(false);
  useEffect(() => {
    if(triggerNewLineEvent) {
      setTriggerNewLineEvent(false);
      let newStocks = _.cloneDeep(stocks);
      newStocks.push({});
      setStocks(newStocks);
    }
  }, [triggerNewLineEvent, setTriggerNewLineEvent, stocks, setStocks]);

  // Initialize new submission with existing latest submission
  const [wholsalerIdForSubmissionCloning, setWholsalerIdForSubmissionCloning] = useState(null);
  useEffect(() => {
    if(!stockBalanceId)
      setWholsalerIdForSubmissionCloning(currentWholesaler ? currentWholesaler.id : null)
  }, [currentWholesaler, stockBalanceId]);
  useEffect(() => {
    if(wholsalerIdForSubmissionCloning && stockBalanceList && authToken) {
      const filteredSubmissions = stockBalanceList.filter(stock => stock.wholesaler_id === wholsalerIdForSubmissionCloning).sort((a, b) => a.date > b.date);
      const latestSubmission = filteredSubmissions && filteredSubmissions.length ? filteredSubmissions[filteredSubmissions.length-1] : null;
      if(latestSubmission && latestSubmission.id) {
        dispatch(fetchStockForCloning(authToken, latestSubmission.id, !latestSubmission.submitted));
      }
    }
  }, [wholsalerIdForSubmissionCloning, stockBalanceList, dispatch, authToken]);
  const stockForCloning = useSelector(({stock}) => stock.stockForCloning);
  useEffect(() => {
    if(!stockBalanceId && stockForCloning && stockForCloning.stocks) {
      if(window.confirm("Load products from previous Stock Balance?")) {
        const cloneProducts = stockForCloning.stocks.map(stock => ({product: stock.product}));
        setStocks(cloneProducts);
      }
    }
  }, [stockForCloning, stockBalanceId]);
  
  const [submitCheck, setSubmitCheck] = useState(false);

  if(!authToken) {
    return null;
  }

  const pathname = _.get(props, 'location.pathname');
  if(!stockBalanceId && pathname === '/edit_stock_balance') {
    return <Redirect to='/add_stock_balance'/>;
  }

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li><Link to="/stock_balance">Stock Balance</Link></li>
          <li className="is-active"><a href="# " aria-current="page">Stock Balance Details</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              {stockBalanceId ? 'Edit ' : 'New '}Stock Balance
            </h1>
          </div>
        </div>
      </section>
      <Form
        onSubmit={ values => {
          const errors = mValidationOnSubmit(stocks);
          if(Object.values(errors).length) {
            return errors;
          }
          
          const {saveAs, ...rest} = values;
          if((saveAs === 'submit' || saveAs === 'revert') && !submitCheck) {
            dispatch(createMessage('Please check the confirmation checkbox.', 'danger'));
            return false;
          }
          const stocksWithZero = stocks.map(stock => {
            const stockWithProduct = {...stock, product: _.find(productList, {id: _.get(stock, 'product.id')})};
            return stockWithProduct.balance_units ? {...stockWithProduct} : {...stockWithProduct, balance_units: 0}
          });
          let orderedStocks = _.orderBy(stocksWithZero, ['product.brand', 'product.sub_brand', 'product.name']);
          orderedStocks = orderedStocks.map((stock, index) => ({...stock, sequence: index+1}));
          if(window.confirm(`Confirm to ${saveAs === 'draft' ? 'save Stock Balance as Draft' : saveAs === 'submit' ? 'submit Stock Balance' : 'revert Stock Balance back to Draft'}?`)) {
            const reformatted = {...rest, stocks: orderedStocks, date: moment(values.date).format('YYYY-MM-DD[T00:00:00Z]')};
            if(stockBalanceId) {
              if(saveAs === 'draft') {
                dispatch(updateDraftStock(authToken, stockBalanceId, orderedStocks, () => history.push('/stock_balance')));
              } else if(saveAs === 'submit') {
                dispatch(submitDraftStock(authToken, stockBalanceId, reformatted, () => history.push('/stock_balance')));
              } else if(saveAs === 'revert') {
                dispatch(revertSubmittedStock(authToken, stockBalanceId, reformatted, () => history.push('/stock_balance')));
              }
            } else {
              dispatch(createStockBalance(authToken, saveAs === 'submit', reformatted, () => history.push('/stock_balance')));
            }
          }
        }}
        initialValues={stockBalanceId && Object.values(stockBalanceDetails).length ? stockBalanceDetails : {wholesaler: currentWholesaler && currentWholesaler.id ? currentWholesaler : formWholesaler, date: formDate ? moment(formDate) : moment()}}
        keepDirtyOnReinitialize
        validate={mValidateStockForm}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <FormErrors formRules={Object.values(formRules)}/>
            { stockBalanceId || !isAdmin
              ? <Field name="wholesaler">
                  {({input}) => <LabelField label="Wholesaler">{_.get(input.value, 'name')||''}</LabelField>}
                </Field>
              : <Field name="wholesaler">
                  {(props) => (
                    <Select
                      label="Wholesaler"
                      options={wholesalerList}
                      getOptionLabel={o => o.name}
                      getOptionValue={o => o.id}
                      placeholder="Select Wholesaler..."
                      isLoading={isLoadingMaster}
                      isClearable
                      isSearchable
                      {...props}
                      input={{...props.input, onChange: val => {
                        setWholsalerIdForSubmissionCloning(val.id);
                        setFormWholesaler(val);
                        props.input.onChange(val);
                      }}}
                    />
                  )}
                </Field>
            }
            { stockBalanceId
              ? <Field name="date">
                  {({input}) => <LabelField label="Month">{moment(input.value).format('MMM, YYYY')}</LabelField>}
                </Field>
              : <Field name="date">
                  {(props) => (
                    <DatePicker
                      label="Month"
                      dateFormat="MMM, yyyy"
                      showMonthYearPicker
                      selected={moment(props.input.value).toDate()}
                      {...props}
                      input={{...props.input, onChange: val => {
                        setFormDate(moment(val));
                        props.input.onChange(val);
                      }}}
                    />
                  )}
                </Field>
            }
            { stockBalanceId
              ? <Field name="submitted">
                  {({input}) => <LabelField label="Status">
                    { input.value
                      ? <p className="has-text-primary">Submitted</p>
                      : <p className="has-text-draft">Draft</p>
                    }
                  </LabelField>}
                </Field>
              : <LabelField label="Status"><p className="has-text-grey">New</p></LabelField>
            }
            <div onKeyDown={_handleKeyDown(triggerNewLineEvent, setTriggerNewLineEvent)}>
              <ReactTable
                data={stocks}
                loading={isLoading}
                columns={stockBalanceId && values.submitted ? submittedStockColumn : stockColumns}
                pageSize={stocks.length}
                showPagination={false}
                sortable={false}
                className="-striped"
                NoDataComponent={() => null}
                getTrProps={(_, rowInfo) => {
                  return { className: indexWithErrors.includes(rowInfo.index) ? 'error' : '' }
                }}
                style={{
                  maxHeight: "500px"
                }}
              />
            </div>
            { !values.submitted
              ? <>
                  <div className="column is-full buttons" style={{marginTop: '50px', justifyContent: 'center'}}>
                    <Button
                      type="submit"
                      className="is-draft"
                      onClick={() => {
                        form.change("saveAs", "draft");
                      }}
                      disabled={submitting || validating || isLoading}
                      loading={submitting || validating || isLoading}
                    >Save Draft</Button>
                  </div>
                  <div className="column is-full buttons" style={{marginTop: '30px', justifyContent: 'center'}}>
                    <label className="checkbox" style={{fontSize: '12pt', textAlign: 'center'}}>
                      <input
                        type="checkbox"
                        style={{width: 15, height: 15}}
                        checked={submitCheck}
                        onChange={() => {setSubmitCheck(!submitCheck)}}
                      />
                      {' '}Submit Stock Balance? Stock Balance should only be submitted at end of the month, use 'Save Draft' instead to save this copy.
                    </label>
                  </div>
                  <div className="column is-full buttons" style={{justifyContent: 'center'}}>
                    <Button
                      type="submit"
                      className="is-primary"
                      onClick={() => {
                        form.change("saveAs", "submit");
                      }}
                      disabled={submitting || validating || isLoading || !submitCheck}
                      loading={submitting || validating || isLoading}
                    >Submit</Button>
                  </div>
                </>
              : isAdmin
                ? <>
                    <div className="column is-full buttons" style={{marginTop: '30px', justifyContent: 'center'}}>
                      <label className="checkbox" style={{fontSize: '12pt', textAlign: 'center'}}>
                        <input
                          type="checkbox"
                          style={{width: 15, height: 15}}
                          checked={submitCheck}
                          onChange={() => {setSubmitCheck(!submitCheck)}}
                        />
                        {' '}Revert Stock Balance back to Draft? Please only change if really necessary, else you can edit a submitted Stock Balance too.
                      </label>
                    </div>
                    <div className="column is-full buttons" style={{justifyContent: 'center'}}>
                      <Button
                        type="submit"
                        className="is-draft"
                        onClick={() => {
                          form.change("saveAs", "revert");
                        }}
                        disabled={submitting || validating || isLoading || !submitCheck}
                        loading={submitting || validating || isLoading}
                      >Revert to Draft</Button>
                    </div>
                  </>
                : <></>
            }
          </form>
        )}
      />
    </div>
  );
};

StockForm.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(StockForm);