import {
  LOADING_AUTH,
  LOGIN_USER,
  FETCH_CURRENT_USER,
  LOGOUT_USER,
  COMPLETE_AUTH,
} from '../actions';

export const INITIAL_STATE = {
  isLoading: false,
  authToken: null,
  isAdmin: false,
  currentUser: null
};

export default (state = INITIAL_STATE, action) => {
  const {type, ...rest} = action;
  switch(type) {
    case LOADING_AUTH: {
      return {...state, isLoading: true};
    }

    case LOGIN_USER:
    case FETCH_CURRENT_USER: {
      return {...state, ...rest, isLoading: false};
    }

    case LOGOUT_USER: {
      return {...state, ...INITIAL_STATE};
    }

    case COMPLETE_AUTH: {
      return {...state, isLoading: false};
    }

    default: {
      return state;
    }
  }
};