import React from 'react';
import PropTypes from 'prop-types';

const DashboardButton = (props) => {
  const {icon, text, onClick} = props;

  return (
    <button
      className="button is-primary is-outlined"
      style={styles.button}
      onClick={onClick}
    >
      <i className={`fas fa-4x ${icon}`}/>
      <b>{text}</b>
    </button>
  );
};

const styles = {
  button: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    width: '180px',
    height: '180px',
    margin: '20px'
  }
}

DashboardButton.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default DashboardButton;