import _ from 'lodash';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import createDecorator from "final-form-calculate";
import moment from 'moment';

import ReactTableFilterable from '../../components/ReactTableFilterable';
import { FormErrors, DatePicker, Select, TextInput, SubmitButton, reportRules } from '../../components/Forms';
import { fetchOutletList, fetchStateList, fetchContractList, fetchOutletTypeList, fetchProductList, fetchSalesSummaryReport } from '../../actions';
import {CSVFromTable} from '../../components/ExportCSV';

// variables for table
const outletColString = (outlet) => outlet ? `${outlet.name ? outlet.name : '-'}${outlet.branch ? ` @ ${outlet.branch}` : ''}` : ''
const outletColumn = {
  Header: 'Outlet',
  id: 'outlet',
  accessor: 'outlet',
  Cell: row => outletColString(row.value),
  sortMethod: (a, b) => {
    return outletColString(a) > outletColString(b) ? 1 : -1;
  },
  minWidth: 250
};
const legalNameColumn = {
  Header: 'Legal Name',
  accessor: 'outlet.legal_name',
  minWidth: 200
};
const stateColumn = {
  Header: 'State',
  accessor: 'outlet.state.state',
  minWidth: 200
};
const contractTypeColumn = {
  Header: 'Contract Type',
  accessor: 'outlet.contract.contract',
  minWidth: 200
};
const outletTypeColumn = {
  Header: 'Outlet Type',
  accessor: 'outlet.outlet_type.outlet_type',
  minWidth: 200
};
const productColumn = {
  Header: 'Product',
  accessor: 'product.name',
  minWidth: 200
};
const brandColumn = {
  Header: 'Brand',
  accessor: 'product.brand',
  minWidth: 100
};
const subBrandColumn = {
  Header: 'Sub-brand',
  accessor: 'product.sub_brand',
  minWidth: 150
};
const skuColumn = {
  Header: 'SKU No.',
  accessor: 'product.sku_no',
  minWidth: 100
};
const actualUnitsColumn = {
  Header: 'Actual Units Sold',
  accessor: 'actual_units',
  Cell: row => (row.value || 0),
  minWidth: 100
};
const standardTOColumn = {
  Header: 'Standard Trade Offer',
  accessor: 'standard_to_units',
  Cell: row => (row.value || 0),
  minWidth: 100
};
const additionalTOColumn = {
  Header: 'Additional Trade Offer',
  accessor: 'additional_to_units',
  Cell: row => (row.value || 0),
  minWidth: 100
};
const defaultAdminColumns = [outletColumn, legalNameColumn, stateColumn, contractTypeColumn, outletTypeColumn, productColumn, brandColumn, subBrandColumn, skuColumn, actualUnitsColumn, standardTOColumn, additionalTOColumn];

// Validate form
let formRules = _.cloneDeep(reportRules);

const validateSalesReport = (values) => {
  const {start_date, end_date} = values;
  let errors = {};

  if(moment(start_date) > moment(end_date)) {
    formRules.date_range.valid = false;
    errors.start_date = true;
    errors.end_date = true;
  } else {
    formRules.date_range.valid = true;
  }

  return errors;
}

// Calculated outlet field
const calculator = createDecorator({
  field: 'state',
  updates: {
    outlet: function(state, {outlet}) {
      outlet = outlet || [];
      return outlet.filter(o => !state || state.length === 0 || state.find(s => s.id === _.get(o, 'state.id')));
    }
  }
}, {
  field: 'contract',
  updates: {
    outlet: function(contract, {outlet}) {
      outlet = outlet || [];
      return outlet.filter(o => !contract || contract.length === 0 || contract.find(c => c.id === _.get(o, 'contract.id')));
    }
  }
}, {
  field: 'outlet_type',
  updates: {
    outlet: function(outlet_type, {outlet}) {
      outlet = outlet || [];
      return outlet.filter(o => !outlet_type || outlet_type.length === 0 || outlet_type.find(ot => ot.id === _.get(o, 'outlet_type.id')));
    }
  }
});

const monthStart = moment().startOf('month').toDate();
const monthEnd = moment().endOf('month').toDate();

const outletGroupings = {
  outlet: {
    label: 'Outlet',
    value: 'outlet'
  },
  state: {
    label: 'State',
    value: 'outlet.state.state'
  },
  contractType: {
    label: 'Contract Type',
    value: 'outlet.contract.contract'
  },
  outletType: {
    label: 'Outlet Type',
    value: 'outlet.outlet_type.outlet_type'
  }
};
const defaultOutletGrouping = outletGroupings.outlet;
const productGroupings = {
  product: {
    label: 'Product',
    value: 'product.name'
  },
  subBrand: {
    label: 'Sub-Brand',
    value: 'product.sub_brand'
  }, 
  brand: {
    label: 'Brand',
    value: 'product.brand'
  }
};
const defaultProductGrouping = productGroupings.product;

const OutletSalesSummaryReport = (props) => {
  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const currentWholesaler = useSelector(({auth}) => auth.currentUser ? auth.currentUser.wholesaler : null);
  const isLoading = useSelector(({sales}) => sales.isLoading);

  const dispatch = useDispatch();

  const [adminColumns, setAdminColumns] = useState(defaultAdminColumns);
  const [filterStartDate, setFilterStartDate] = useState(monthStart);
  const [filterEndDate, setFilterEndDate] = useState(monthEnd);
  const [filterOutlets, setFilterOutlets] = useState([]);
  const [filterStates, setFilterStates] = useState([]);
  const [filterContractTypes, setFilterContractTypes] = useState([]);
  const [filterOutletTypes, setFilterOutletTypes] = useState([]);
  const [filterBrands, setFilterBrands] = useState('');
  const [filterSubBrands, setFilterSubBrands] = useState('');
  const [filterProducts, setFilterProducts] = useState([]);
  const [groupByOutlet, setGroupByOutlet] = useState(defaultOutletGrouping);
  const [groupByProduct, setGroupByProduct] = useState(defaultProductGrouping);
  useEffect(() => {
    if(authToken) {
      const startDate = moment(filterStartDate).format('YYYY-MM-DD[T00:00:00]');
      const endDate = moment(filterEndDate).format('YYYY-MM-DD[T00:00:00]');
      if(isAdmin) {
        dispatch(fetchSalesSummaryReport(authToken, isAdmin, startDate, endDate));
      } else if(currentWholesaler) {
        dispatch(fetchSalesSummaryReport(authToken, isAdmin, startDate, endDate, currentWholesaler.id));
      }
    }
  }, [filterStartDate, filterEndDate, authToken, isAdmin, currentWholesaler, dispatch]);
  const data = useSelector(({sales}) => sales.salesSummaryReport) || [];

  useEffect(() => {
    if(authToken) {
      dispatch(fetchOutletList(authToken));
      dispatch(fetchStateList(authToken));
      dispatch(fetchContractList(authToken));
      dispatch(fetchOutletTypeList(authToken));
      dispatch(fetchProductList(authToken));
    }
  }, [authToken, isAdmin, dispatch]);
  const outletList = useSelector(({outlet}) => outlet.outletList);
  const stateList = useSelector(({master}) => master.stateList);
  const contractList = useSelector(({master}) => master.contractList);
  const outletTypeList = useSelector(({master}) => master.outletTypeList);
  const productList = useSelector(({product}) => product.productList);
  const isLoadingMaster = useSelector(({master, wholesaler, outlet, product}) => master.isLoading || wholesaler.isLoading || outlet.isLoading || product.isLoading);

  // Prepare data
  const filteredData = useMemo(() => {
    const reformatted = data.map(sale => 
      ({...sale, outlet: outletList.find(o => o.id === _.get(sale, 'outlet.id'))})
    );

    const filtered = reformatted.filter(sale => 
      (filterOutlets.length === 0 || _.find(filterOutlets, o => o.id === _.get(sale, 'outlet.id'))) &&
      (filterStates.length === 0 || _.find(filterStates, s => s.id === _.get(sale, 'outlet.state.id'))) &&
      (filterContractTypes.length === 0 || _.find(filterContractTypes, ct => ct.id === _.get(sale, 'outlet.contract.id'))) &&
      (filterOutletTypes.length === 0 || _.find(filterOutletTypes, ot => ot.id === _.get(sale, 'outlet.outlet_type.id'))) &&
      (!filterBrands || _.includes(_.get(sale, 'product.brand', '').toLowerCase(), filterBrands.toLowerCase())) &&
      (!filterSubBrands || _.includes(_.get(sale, 'product.sub_brand', '').toLowerCase(), filterSubBrands.toLowerCase())) &&
      (filterProducts.length === 0 || _.find(filterProducts, p => p.id === _.get(sale, 'product.id')))
    );

    // Summarised data
    const summarised = [...filtered.reduce((r, ss) => {
      const key = 
        `${groupByOutlet.value === 'outlet'
          ? `${_.get(ss, 'outlet.name')}${_.get(ss, 'outlet.branch') && ` @ ${_.get(ss, 'outlet.branch')}`}`
          : _.get(ss, groupByOutlet.value)}`
        + '-separator-' + _.get(ss, groupByProduct.value);

      const item = r.get(key) || Object.assign({}, ss, {
        actual_units: 0,
        standard_to_units: 0,
        additional_to_units: 0
      });
      item.actual_units += ss.actual_units;
      item.standard_to_units += ss.standard_to_units;
      item.additional_to_units += ss.additional_to_units;
    
      return r.set(key, item);
    }, new Map()).values()];

    return summarised;
  }, [data, outletList, filterOutlets, filterStates, filterContractTypes, filterOutletTypes, filterBrands, filterSubBrands, filterProducts, groupByOutlet.value, groupByProduct.value]);

  const dataTableRef = useRef(null);

  if(!authToken)
    return null;

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li><Link to="/reports">Reports</Link></li>
          <li className="is-active"><a href="# " aria-current="page">Outlet Sales Summary Report</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              Outlet Sales Summary Report
            </h1>
          </div>
        </div>
      </section>
      <Form
        onSubmit={ values => {
          const {start_date, end_date, outlet, state, contract, outlet_type, brand, sub_brand, product, outletGroup, productGroup} = values;
          if(start_date !== filterStartDate) {
            setFilterStartDate(start_date);
          }
          if(end_date !== filterEndDate) {
            setFilterEndDate(end_date);
          }
          if(!_.isEqual(outlet, filterOutlets)) {
            setFilterOutlets(outlet || []);
          }
          if(!_.isEqual(state, filterStates)) {
            setFilterStates(state || []);
          }
          if(!_.isEqual(contract, filterContractTypes)) {
            setFilterContractTypes(contract || []);
          }
          if(!_.isEqual(outlet_type, filterOutletTypes)) {
            setFilterOutletTypes(outlet_type || []);
          }
          if(brand !== filterBrands) {
            setFilterBrands(brand);
          }
          if(sub_brand !== filterSubBrands) {
            setFilterSubBrands(sub_brand);
          }
          if(!_.isEqual(product, filterProducts)) {
            setFilterProducts(product || []);
          }
          if(outletGroup !== groupByOutlet || productGroup !== groupByProduct) {
            if(outletGroup !== groupByOutlet) {
              setGroupByOutlet(outletGroup);
            }
            if(productGroup !== groupByProduct) {
              setGroupByProduct(productGroup);
            }

            // Set Columns
            var columns = [...defaultAdminColumns];

            if(outletGroup !== outletGroupings.outlet) {
              _.pull(columns, outletColumn, legalNameColumn);
              if(outletGroup !== outletGroupings.state) {
                _.pull(columns, stateColumn);
              }
              if(outletGroup !== outletGroupings.contractType) {
                _.pull(columns, contractTypeColumn);
              }
              if(outletGroup !== outletGroupings.outletType) {
                _.pull(columns, outletTypeColumn);
              }
            }
            if(productGroup !== productGroupings.product) {
              _.pull(columns, productColumn, skuColumn);
              if(productGroup !== productGroupings.subBrand) {
                _.pull(columns, subBrandColumn);
              }
            }
            
            setAdminColumns(columns);
          }
        }}
        initialValues={{
          start_date: monthStart,
          end_date: monthEnd,
          outlet: [],
          state: [],
          contract: [],
          outlet_type: [],
          brand: '',
          sub_brand: '',
          product: [],
          outletGroup: defaultOutletGrouping,
          productGroup: defaultProductGrouping
        }}
        keepDirtyOnReinitialize
        decorators={[calculator]}
        validate={validateSalesReport}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <FormErrors formRules={Object.values(formRules)}/>
            <Field name="start_date">
              {(props) => (
                <DatePicker
                  label="From Date"
                  dateFormat="yyyy-MM-dd"
                  selected={moment(props.input.value).toDate()}
                  {...props}
                />
              )}
            </Field>
            <Field name="end_date">
              {(props) => (
                <DatePicker
                  label="To Date"
                  dateFormat="yyyy-MM-dd"
                  selected={moment(props.input.value).toDate()}
                  {...props}
                />
              )}
            </Field>
            <Field name="outlet">
              {(props) => (
                <Select
                  label="Outlet"
                  options={outletList.filter(o => 
                    (!values.state || values.state.length === 0 || _.find(values.state, s => s.id === _.get(o, 'state.id'))) &&
                    (!values.contract || values.contract.length === 0 || _.find(values.contract, c => c.id === _.get(o, 'contract.id'))) &&
                    (!values.outlet_type || values.outlet_type.length === 0 || _.find(values.outlet_type, ot => ot.id === _.get(o, 'outlet_type.id'))) 
                  )}
                  getOptionLabel={o => `${o.name}${o.branch && ` @ ${o.branch}`}`}
                  getOptionValue={o => o.id}
                  placeholder="All"
                  isLoading={isLoadingMaster || isLoading}
                  isMulti
                  isClearable
                  isSearchable
                  {...props}
                />
              )}
            </Field>
            <Field name="state">
              {(props) => (
                <Select
                  label="Outlet State"
                  options={stateList}
                  getOptionLabel={o => o.state}
                  getOptionValue={o => o.id}
                  placeholder="All"
                  isLoading={isLoadingMaster || isLoading}
                  isMulti
                  isClearable
                  isSearchable
                  {...props}
                />
              )}
            </Field>
            <Field name="contract">
              {(props) => (
                <Select
                  label="Contract Type"
                  options={contractList}
                  getOptionLabel={o => o.contract}
                  getOptionValue={o => o.id}
                  placeholder="All"
                  isLoading={isLoadingMaster || isLoading}
                  isMulti
                  isClearable
                  isSearchable
                  {...props}
                />
              )}
            </Field>
            <Field name="outlet_type">
              {(props) => (
                <Select
                  label="Outlet Type"
                  options={outletTypeList}
                  getOptionLabel={o => o.outlet_type}
                  getOptionValue={o => o.id}
                  placeholder="All"
                  isLoading={isLoadingMaster || isLoading}
                  isMulti
                  isClearable
                  isSearchable
                  {...props}
                />
              )}
            </Field>
            <Field name="brand" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Product Brand"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="sub_brand" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Product Sub-Brand"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="product">
              {(props) => (
                <Select
                  label="Product"
                  options={productList}
                  getOptionLabel={o => o.name}
                  getOptionValue={o => o.id}
                  placeholder="All"
                  isLoading={isLoadingMaster || isLoading}
                  isMulti
                  isClearable
                  isSearchable
                  {...props}
                />
              )}
            </Field>
            <Field name="outletGroup">
              {(props) => (
                <Select
                  label="Group By (Outlet)"
                  options={Object.values(outletGroupings)}
                  value={props.input.value}
                  getOptionLabel={o => o.label}
                  getOptionValue={o => o.value}
                  {...props}
                />
              )}
            </Field>
            <Field name="productGroup">
              {(props) => (
                <Select
                  label="Group By (Product)"
                  options={Object.values(productGroupings)}
                  value={props.input.value}
                  getOptionLabel={o => o.label}
                  getOptionValue={o => o.value}
                  {...props}
                />
              )}
            </Field>
            <div className="column is-full has-text-centered" style={{marginBottom: '30px'}}>
              <SubmitButton
                disabled={submitting || validating}
                loading={submitting || validating || isLoading}
              >Filter</SubmitButton>
            </div>
            <div className="column is-full has-text-right" style={{marginBottom: '20px'}}>
              <CSVFromTable
                tableRef={dataTableRef}
                columns={adminColumns}
                fileName="OutletSalesSummaryReport"
                loading={submitting || validating || isLoading}
              >
                <span className="icon">
                  <i className="fas fa-file-excel"></i>
                </span>
                <span>Export to Excel</span>
              </CSVFromTable>
            </div>
          </form>
        )}
      />
      <div>
        <ReactTableFilterable
          data={filteredData}
          columns={adminColumns}
          defaultPageSize={10}
          defaultSorted={[
            {
              id: groupByOutlet.value,
              desc: false
            }, {
              id: groupByProduct.value,
              desc: false
            }
          ]}
          className="-striped -highlight"
          ref={dataTableRef}
        />
      </div>
    </div>
  );
};

export default OutletSalesSummaryReport;