import _ from 'lodash';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { Form, Field } from 'react-final-form';
import moment from 'moment';

import { FormErrors, Select, DatePicker, TextInput, LabelField, Button, salesFormRules } from '../../components/Forms';
import { fetchAllSalesSummary, fetchSalesSummaryDetails, clearSalesSummaryDetails, fetchWholesalerList, fetchOutletListLean, fetchProductList, createSalesSummary, updateDraftSales, submitDraftSales, revertSubmittedSales, createMessage } from '../../actions';
import EncryptStorage from '../../components/EncryptStrorage';

const validateSalesFormWorker = new Worker('./worker/validateSalesForm.js');

// Sales table columns
const numberColumn = {
  Header: 'No.',
  accessor: 'sequence',
  Cell: row => (row.index + 1),
  Footer: (
    <span className="has-text-grey-light">+</span>
  ),
  minWidth: 50
};
const submittedNumberColumn = {
  Header: 'No.',
  accessor: 'sequence',
  Cell: row => (row.index + 1),
  minWidth: 50
};
const invoiceDateColumn = (sales, setSales) => ({
  Header: 'Invoice Date',
  accessor: 'invoice_date',
  Cell: row => (
    <DatePicker
      dateFormat="yyyy-MM-dd"
      selected={moment(row.value).toDate()}
      onChange={date => {
        if(date) {
          let newSales = _.cloneDeep(sales);
          newSales[row.index].invoice_date = moment(date).format('YYYY-MM-DD[T00:00:00Z]');
          setSales(newSales);
        }
      }}
    />
  ),
  Footer: ({data}) => (
    <span className="has-text-grey-light">
      { data.length ? moment(_.get(data[data.length-1], 'invoice_date')).format('YYYY-MM-DD') : ''}
    </span>
  ),
  minWidth: 200
});
const submittedInvoiceDateColumn = {
  Header: 'Invoice Date',
  accessor: 'invoice_date',
  Cell: row => (moment(row.value).format('YYYY-MM-DD')),
  minWidth: 200
};
const invoiceNoColumn = (sales, setSales) => ({
  Header: 'Invoice No.',
  accessor: 'invoice_no',
  Cell: row => (
    <TextInput
      input={{
        type: 'text',
        defaultValue: String(row.value),
        onBlur: e => {
          if(e.target.value !== row.value) {
            let newSales = _.cloneDeep(sales);
            newSales[row.index].invoice_no = _.toString(e.target.value);
            setSales(newSales);
          }
        }
      }}
    />
  ),
  Footer: ({data}) => (
    <span className="has-text-grey-light">
      { data.length ? _.get(sales[sales.length-1], 'invoice_no', '') : ''}
    </span>
  ),
  minWidth: 200
});
const submittedInvoiceNoColumn = {
  Header: 'Invoice No.',
  accessor: 'invoice_no',
  minWidth: 200
};
const outletColumn = (sales, setSales, outletList, currentPath, linkState, isAdmin) => ({
  Header: 'Outlet @ Branch',
  accessor: 'outlet.id',
  Cell: row => (
    <Select
      options={outletList}
      getOptionLabel={o => `${o.name}${o.branch && ` @ ${o.branch}`}`}
      getOptionValue={o => o.id}
      placeholder="Select Outlet...."
      isSearchable
      menuPortalTarget={document.querySelector("body")}
      value={outletList.find(outlet => outlet.id === row.value)}
      onChange={selected => {
        let newSales = _.cloneDeep(sales);
        newSales[row.index].outlet = selected;
        setSales(newSales);
      }}
      noOptionsMessage={() => isAdmin
        ? <Link
            to={{
              pathname: '/add_outlet',
              state: {
                breadcrumbs: [
                  {name: "Dashboard", link: "/"},
                  {name: "Sales Summary", link: "/sales_summary"},
                  {name: "Sales Summary Details", link: currentPath, linkState}
                ],
              }
            }}
          >Create new Outlet</Link>
        : "No options"
      }
    />
  ),
  Footer: ({data}) => {
    if(data){
      const outlet = outletList.find(outlet => outlet.id === _.get(data[data.length-1], 'outlet.id'));
      return (
        <span className="has-text-grey-light">
          {outlet && `${outlet.name}${outlet.branch && ` @ ${outlet.branch}`}`}
        </span>
      )
    }
    return '';
  },
  minWidth: 300
});
const submittedOutletColumn = (outletList) => ({
  Header: 'Outlet @ Branch',
  accessor: 'outlet.id',
  Cell: row => {
    const outlet = outletList.find(outlet => outlet.id === row.value);
    return outlet ? `${outlet.name}${outlet.branch && ` @ ${outlet.branch}`}` : '';
  },
  minWidth: 300
});
const legalNameColumn = (outletList) => ({
  Header: 'Legal Name',
  accessor: 'outlet.id',
  Cell: row => (
    _.get(outletList.find(outlet => outlet.id === row.value), 'legal_name', '')
  ),
  Footer: ({data}) => {
    if(data){
      const outlet = outletList.find(outlet => outlet.id === _.get(data[data.length-1], 'outlet.id'));
      return (
        <span className="has-text-grey-light">
          {outlet && outlet.legal_name}
        </span>
      )
    }
    return '';
  },
  minWidth: 200
});
const submittedLegalNameColumn = (outletList) => ({
  Header: 'Legal Name',
  accessor: 'outlet.id',
  Cell: row => (
    _.get(outletList.find(outlet => outlet.id === row.value), 'legal_name', '')
  ),
  minWidth: 200
});
const productColumn = (sales, setSales, productList) => ({
  Header: 'Product Name',
  accessor: 'product.id',
  Cell: row => (
    <Select
      options={productList}
      getOptionLabel={p => p.name}
      getOptionValue={p => p.id}
      placeholder="Select Product..."
      isSearchable
      menuPortalTarget={document.querySelector("body")}
      value={productList.find(product => product.id === row.value)}
      onChange={selected => {
        let newSales = _.cloneDeep(sales);
        newSales[row.index].product = selected;
        setSales(newSales);
      }}
    />
  ),
  minWidth: 300
});
const submittedProductColumn = (productList) => ({
  Header: 'Product Name',
  accessor: 'product.id',
  Cell: row => (
    _.get(productList.find(product => product.id === row.value), 'name', '')
  ),
  minWidth: 300
});
// const brandColumn = (productList) => ({
//   Header: 'Brand',
//   accessor: 'product.id',
//   Cell: row => (
//     _.get(productList.find(product => product.id === row.value), 'brand', '')
//   ),
//   minWidth: 100
// });
// const subBrandColumn = (productList) => ({
//   Header: 'Sub-Brand',
//   accessor: 'product.id',
//   Cell: row => (
//     _.get(productList.find(product => product.id === row.value), 'sub_brand', '')
//   ),
//   minWidth: 150
// });
const skuColumn = (productList) => ({
  Header: 'SKU No.',
  accessor: 'product.id',
  Cell: row => (
    _.get(productList.find(product => product.id === row.value), 'sku_no', '')
  ),
  minWidth: 100
});
const actualUnitsColumn = (sales, setSales) => ({
  Header: 'Actual Units Sold (Bottles)',
  accessor: 'actual_units',
  Cell: row => (
    <TextInput
      input={{
        type: 'text',
        defaultValue: ''+(row.value||0),
        onBlur: e => {
          if(e.target.value === '')
            e.target.value = '0';
          if(e.target.value !== ''+(row.value||0)) {
            let newSales = _.cloneDeep(sales);
            if(!isNaN(e.target.value)) {
              newSales[row.index].actual_units = +(e.target.value);
            }
            setSales(newSales);
          }
        },
        onFocus: (e) => {
          if(e.target.value === '0')
            e.target.value = '';
        }
      }}
    />
  ),
  minWidth: 200
});
const submittedActualUnitsColumn = {
  Header: 'Actual Units Sold (Bottles)',
  accessor: 'actual_units',
  Cell: row => (''+(row.value||0)),
  minWidth: 200
};
const standardTOColumn = (sales, setSales) => ({
  Header: 'Standard TO (Bottles)',
  accessor: 'standard_to_units',
  Cell: row => (
    <TextInput
      input={{
        type: 'text',
        defaultValue: ''+(row.value||0),
        onBlur: e => {
          if(e.target.value === '')
            e.target.value = '0';
          if(e.target.value !== ''+(row.value||0)) {
            let newSales = _.cloneDeep(sales);
            if(!isNaN(e.target.value)) {
              newSales[row.index].standard_to_units = +(e.target.value);
            }
            setSales(newSales);
          }
        },
        onFocus: (e) => {
          if(e.target.value === '0')
            e.target.value = ''
        }
      }}
    />
  ),
  minWidth: 200
});
const submittedStandardTOColumn = {
  Header: 'Standard TO (Bottles)',
  accessor: 'standard_to_units',
  Cell: row => (''+(row.value||0)),
  minWidth: 200
};
const additionalTOColumn = (sales, setSales) => ({
  Header: 'Additional TO (Bottles)',
  accessor: 'additional_to_units',
  Cell: row => (
    <TextInput
      input={{
        type: 'text',
        defaultValue: ''+(row.value||0),
        onBlur: e => {
          if(e.target.value === '')
            e.target.value = '0';
          if(e.target.value !== ''+(row.value||0)) {
            let newSales = _.cloneDeep(sales);
            if(!isNaN(e.target.value)) {
              newSales[row.index].additional_to_units = +(e.target.value);
            }
            setSales(newSales);
          }
        },
        onFocus: (e) => {
          if(e.target.value === '0')
            e.target.value = ''
        }
      }}
    />
  ),
  minWidth: 200
});
const submittedAdditionalTOColumn = {
  Header: 'Additional TO (Bottles)',
  accessor: 'additional_to_units',
  Cell: row => (''+(row.value||0)),
  minWidth: 200
};
const totalColumn = {
  Header: 'Total Units (Bottles)',
  id: 'total',
  accessor: row => row,
  Cell: row => (
    _.get(row.value, 'actual_units', 0) + _.get(row.value, 'standard_to_units', 0) + _.get(row.value, 'additional_to_units', 0)
  ),
  minWidth: 150
};
const addRemoveColumn = (sales, setSales, setShouldScrollTable) => ({
  Header: '',
  id: 'edit',
  accessor: row => row,
  Cell: row => (
    <div className="has-text-centered">
      <button
        type="button"
        className="button is-danger is-outlined" 
        onClick={e => {
          e.preventDefault();
          if(window.confirm('Confirm to remove row?')) {
            let newSales = _.cloneDeep(sales);
            newSales.splice(row.index, 1);
            setSales(newSales);
          }
        }}
      >
        <span className="icon">
          <i className="fas fa-minus"/>
        </span>
      </button>
    </div>
  ),
  Footer: () => (
    <div className="has-text-centered">
      <button
        type="button"
        className="button is-success is-outlined" 
        onClick={e => {
          e.preventDefault();
          const {invoice_date, invoice_no, outlet} = sales.length ? sales[sales.length-1] : emptyFormDefaultValue[0];
          let newSales = _.cloneDeep(sales);
          newSales.push({invoice_date, invoice_no, outlet});
          setSales(newSales);
          setShouldScrollTable(true);
        }}
      >
        <span className="icon">
          <i className="fas fa-plus"/>
        </span>
      </button>
    </div>
  ),
  minWidth: 50
});
const editColumn = (salesSummaryId) => ({
  Header: '',
  id: 'edit',
  accessor: row => row,
  Cell: row => (
    <div className="has-text-centered">
      <Link className="button is-primary is-outlined" to={{
        pathname: '/edit_sale',
        state: {
          saleToUpdate: row.value,
          salesSummaryId
        }
      }}>
        <span className="icon">
          <i className="fas fa-edit"/>
        </span>
      </Link>
    </div>
  ),
  Footer: ({data}) => (
    <div className="has-text-centered">
      <Link className="button is-success is-outlined" to={{
        pathname: '/add_sale',
        state: {
          nextSequence: data ? _.get(data[data.length-1], 'sequence', 0) + 1 : 1,
          salesSummaryId
        }
      }}>
        <span className="icon">
          <i className="fas fa-plus"/>
        </span>
      </Link>
    </div>
  ),
  minWidth: 50
});

// Form validations
let formRules = _.cloneDeep(salesFormRules);

const emptyFormDefaultValue = [{invoice_date: moment().format('YYYY-MM-DD[T00:00:00Z]')}];

const _handleKeyDown = (triggerNewLineEvent, setTriggerNewLineEvent) => e => {
  if(e.key === 'Enter') {
    e.target.blur();
    if(!triggerNewLineEvent)
      setTriggerNewLineEvent(true);
  }
}

const SalesForm = (props) => {
  const [salesSummaryId, salesIsSubmitted, preselectedFormValues, computedSalesData] = useMemo(() => [_.get(props.location, 'state.salesSummaryId'), _.get(props.location, 'state.salesIsSubmitted'), _.get(props.location, 'state.preselectedFormValues'), _.get(props.location, 'state.computedSalesData')], [props.location]);
  const history = props.history;

  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const currentWholesaler = useSelector(({auth}) => auth.currentUser ? auth.currentUser.wholesaler : null);
  const isLoading = useSelector(({sales}) => sales.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearSalesSummaryDetails());
    return () => dispatch(clearSalesSummaryDetails());
  }, [dispatch]);

  useEffect(() => {
    if(authToken) {
      if(salesSummaryId) {
        dispatch(fetchSalesSummaryDetails(authToken, salesSummaryId, !salesIsSubmitted));
      } else {
        dispatch(fetchAllSalesSummary(authToken));
      }
      if(isAdmin) {
        dispatch(fetchWholesalerList(authToken, isAdmin));
      }
      dispatch(fetchOutletListLean(authToken));
      dispatch(fetchProductList(authToken));
    }
  }, [authToken, salesSummaryId, salesIsSubmitted, isAdmin, dispatch]);

  const salesSummaryList = useSelector(({sales}) => sales.allSalesSummary);
  const salesSummaryDetails = useSelector(({sales}) => salesSummaryId ? sales.salesSummaryDetails : undefined);
  const wholesalerList = useSelector(({wholesaler}) => isAdmin ? wholesaler.wholesalerList.map(ws => ({...ws, isDisabled: !ws.active})) : undefined);
  const outletList = useSelector(({outlet}) => outlet.outletList.map(outlet => ({...outlet, isDisabled: !outlet.active})));
  const productList = useSelector(({product}) => product.productList.map(product => ({...product, isDisabled: !product.active})));
  const isLoadingMaster = useSelector(({outlet, product, wholesaler}) => outlet.isLoading || product.isLoading || wholesaler.isLoading);

  // Delay loading ReactTable until data are ready
  const [dataReady, setDataReady] = useState(false);
  useEffect(() => {
    if (isAdmin && (!wholesalerList || wholesalerList.length === 0))
      setDataReady(false);
    else if (!outletList || outletList.length === 0)
      setDataReady(false);
    else if (!productList || productList.length === 0)
      setDataReady(false);
    else if (isLoading || isLoadingMaster)
      setDataReady(false);
    else
      setDataReady(true);
  }, [isAdmin, wholesalerList, outletList, productList, isLoading, isLoadingMaster]);

  const [formWholesaler, setFormWholesaler] = useState();
  const [formDate, setFormDate] = useState(moment());
  useEffect(() => {
    if(_.get(preselectedFormValues, 'formWholesaler')) {
      setFormWholesaler(_.get(preselectedFormValues, 'formWholesaler'));
    }
    if(_.get(preselectedFormValues, 'formDate')) {
      setFormDate(moment(_.get(preselectedFormValues, 'formDate')));
    }
  }, [preselectedFormValues]);

  const [sales, setSales] = useState([]);

  useEffect(() => {
    if(computedSalesData && computedSalesData.length) {
      setSales(computedSalesData);
    } else {
      let tempSales = _.get(salesSummaryDetails, 'sales');
      if(!tempSales || !tempSales.length) {
        tempSales = salesIsSubmitted ? [] : emptyFormDefaultValue;
      }
      setSales(tempSales);
    }
  }, [computedSalesData, salesSummaryDetails, salesIsSubmitted]);

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if(!computedSalesData || computedSalesData.length === 0) {
      const tempSales = _.get(salesSummaryDetails, 'sales');
      if(salesSummaryId && salesSummaryDetails && tempSales && !salesIsSubmitted) {
        let salesOnStorage = EncryptStorage.getSalesItem(salesSummaryId);
        if(salesOnStorage && !_.isEqual(salesOnStorage, tempSales) && !_.isEqual(salesOnStorage, emptyFormDefaultValue)) {
          if(window.confirm("Do you want to load your unsaved changes?")) {
            setSales(salesOnStorage);
          } else {
            EncryptStorage.removeSalesItem(salesSummaryId);
          }
        }
        setLoaded(true);
      }
      if(!salesSummaryId) {
        let salesOnStorage = EncryptStorage.getSalesItem(0);
        if(salesOnStorage && !_.isEqual(salesOnStorage, emptyFormDefaultValue)) {
          if(window.confirm("Do you want to load your unsaved changes?")) {
            let salesFormStorage = EncryptStorage.getItem('salesForm') || {};
            if(salesFormStorage && salesFormStorage.formWholesaler) {
              setFormWholesaler(salesFormStorage.formWholesaler);
            }
            if(salesFormStorage && salesFormStorage.formDate) {
              setFormDate(moment(salesFormStorage.formDate));
            }
            setSales(salesOnStorage);
          } else {
            EncryptStorage.removeSalesItem(0);
          }
        }
        setLoaded(true);
      }
    } else {
      setLoaded(true);
    }
  }, [computedSalesData, salesSummaryId, salesSummaryDetails, salesIsSubmitted]);

  useEffect(() => {
    if(loaded) {
      if (salesSummaryId) {
        EncryptStorage.setSalesItem(salesSummaryId, sales);
      } else {
        EncryptStorage.setSalesItem(0, sales);
      }
    }
  }, [salesSummaryId, sales, loaded, salesIsSubmitted]);

  useEffect(() => {
    if(loaded) {
      const salesFormStorage = {formWholesaler, formDate};
      EncryptStorage.setItem('salesForm', salesFormStorage);
    }
  }, [loaded, formWholesaler, formDate]);

  // scroll table on new line added
  const [shouldScrollTable, setShouldScrollTable] = useState(false);
  useEffect(() => {
    if(shouldScrollTable) {
      document.getElementsByClassName('rt-tbody')[0].scrollTop = Number.MAX_SAFE_INTEGER;
      setShouldScrollTable(false);
    }
  }, [sales, shouldScrollTable]);

  const salesColumns = useMemo(() => [
    numberColumn,
    invoiceDateColumn(sales, setSales),
    invoiceNoColumn(sales, setSales),
    outletColumn(sales, setSales, outletList, _.get(props, 'location.pathname'), {salesSummaryId, salesIsSubmitted}, isAdmin),
    legalNameColumn(outletList),
    productColumn(sales, setSales, productList),
    skuColumn(productList),
    actualUnitsColumn(sales, setSales),
    standardTOColumn(sales, setSales),
    additionalTOColumn(sales, setSales),
    totalColumn,
    addRemoveColumn(sales, setSales, setShouldScrollTable)
  ], [sales, setSales, outletList, productList, props, salesSummaryId, salesIsSubmitted, isAdmin]);
  const submittedSalesColumn = useMemo(() => [
    submittedNumberColumn,
    submittedInvoiceDateColumn,
    submittedInvoiceNoColumn,
    submittedOutletColumn(outletList),
    submittedLegalNameColumn(outletList),
    submittedProductColumn(productList),
    skuColumn(productList),
    submittedActualUnitsColumn,
    submittedStandardTOColumn,
    submittedAdditionalTOColumn,
    totalColumn
  ].concat(isAdmin ? [editColumn(salesSummaryId)] : []), [outletList, productList, isAdmin, salesSummaryId]);

  // Reset formRules
  useEffect(() => {
    formRules = _.cloneDeep(salesFormRules);
  }, []);

  // Form Validation
  const validateSalesForm = (values) => {
    const {wholesaler, date} = values;
    let errors = {};

    if(!wholesaler) {
      formRules.wholesaler_required.valid = false;
      errors.wholesaler = true;
    } else {
      formRules.wholesaler_required.valid = true;
    }

    if(!date) {
      formRules.month_required.valid = false;
      errors.date = true;
    } else {
      formRules.month_required.valid = true;
    }

    if(wholesaler && date) {
      if(!salesSummaryId && salesSummaryList.some(ss => ss.wholesaler_id === wholesaler.id && moment(ss.date).format('MM-YYYY') === moment(date).format('MM-YYYY'))) {
        formRules.month_taken.valid = false;
        errors.date = true;
      } else {
        formRules.month_taken.valid = true;
      }
    }

    return errors;
  };
  const mValidateSalesForm = useCallback(validateSalesForm, [salesSummaryId, salesSummaryList]);

  const [indexWithErrors, setIndexWithErrors] = useState([]);
  const [validatingForm, setValidatingForm] = useState(false);
  const [formValidationMsg, setFormValidationMsg] = useState("");
  const validationOnSubmit = (values, formValues) => {
    setFormValidationMsg("Validating form... Please wait for a moment");

    formRules.invoice_date_required.pristine = false;
    formRules.invoice_no_requried.pristine = false;
    formRules.outlet_required.pristine = false;
    formRules.product_required.pristine = false;
    formRules.units_required.pristine = false;
    formRules.invoice_product_taken.pristine = false;
    setIndexWithErrors([]);

    validateSalesFormWorker.postMessage({msg: 'validateSalesForm', values, sales, formValues});

    // return errors;
  }
  const mValidationOnSubmit = useCallback(validationOnSubmit, [sales]);

  // Get result from validateSalesForm WebWorker
  useEffect(() => {
    validateSalesFormWorker.onmessage = ($event) => {
      if ($event && $event.data) {
        const {invoice_date_required, invoice_no_requried, outlet_required, product_required, units_required, invoice_product_taken, generatedIndexWithErrors, formValues} = $event.data;

        formRules.invoice_date_required.valid = invoice_date_required;
        formRules.invoice_no_requried.valid = invoice_no_requried;
        formRules.outlet_required.valid = outlet_required;
        formRules.product_required.valid = product_required;
        formRules.units_required.valid = units_required;
        formRules.invoice_product_taken.valid = invoice_product_taken;
        setIndexWithErrors(generatedIndexWithErrors);
        
        if (generatedIndexWithErrors.length > 0) {
          const errMsg = "There are errors in the form. Please check error messages on top, to fix the errors, and re-submit";
          setFormValidationMsg(errMsg);
          dispatch(createMessage(errMsg, 'danger'));
        } else {
          setFormValidationMsg("");

          // --- Submit sales ---
          const {saveAs, ...rest} = formValues;
          if((saveAs === 'submit' || saveAs === 'revert') && !submitCheck) {
            dispatch(createMessage('Please check the confirmation checkbox.', 'danger'));
            return false;
          }
          var confirmationMsg = "";
          if (saveAs === 'draft')
            confirmationMsg = "Confirm to save Sales Summary as Draft?"
          else if (saveAs === 'submit')
            confirmationMsg = "Confirm to submit Sales Summary?"
          else if (saveAs === 'revert')
            confirmationMsg = "Confirm to revert Sales Summary back to Draft?"
          if(window.confirm(confirmationMsg)) {
            const reformatted = {...rest, sales, date: moment(formValues.date).format('YYYY-MM-DD[T00:00:00Z]')};
            
            if(salesSummaryId) {
              if(saveAs === 'draft') {
                dispatch(updateDraftSales(authToken, salesSummaryId, sales, () => history.push('/sales_summary')));
              } else if(saveAs === 'submit') {
                dispatch(submitDraftSales(authToken, salesSummaryId, reformatted, () => history.push('/sales_summary')));
              } else if(saveAs === 'revert') {
                dispatch(revertSubmittedSales(authToken, salesSummaryId, reformatted, () => history.push('/sales_summary')));
              }
            } else {
              dispatch(createSalesSummary(authToken, saveAs === 'submit', reformatted, () => history.push('/sales_summary')));
            }
          }
          // --- Submit sales --- end
        }
        setValidatingForm(false);
        
      } else {
        const errMsg = "Error validating form. Please try again on another browser. (Web worker unavailable)";
        setFormValidationMsg(errMsg);
        dispatch(createMessage(errMsg, 'danger'));
        setValidatingForm(false);
      }
    };
    validateSalesFormWorker.onerror = ($event) => {
      console.error("Web Worker Error: ", $event);
      const errMsg = `An error occurs in web worker. ${$event}`;
      setFormValidationMsg(errMsg);
      dispatch(createMessage(errMsg, 'danger'));
      setValidatingForm(false);
    }
  });

  // Validate initial values once on CDM - future refactor and remove eslint-disable
  useEffect(() => {
    salesSummaryDetails && mValidateSalesForm(salesSummaryDetails)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle Enter Event
  const [triggerNewLineEvent, setTriggerNewLineEvent] = useState(false);
  useEffect(() => {
    if(triggerNewLineEvent) {
      setTriggerNewLineEvent(false);
      const {invoice_date, invoice_no, outlet} = sales.length ? sales[sales.length-1] : emptyFormDefaultValue[0];
      let newSales = _.cloneDeep(sales);
      newSales.push({invoice_date, invoice_no, outlet});
      setSales(newSales);
    }
  }, [triggerNewLineEvent, setTriggerNewLineEvent, sales, setSales]);

  const [submitCheck, setSubmitCheck] = useState(false);

  if(!authToken) {
    return null;
  }

  const pathname = _.get(props, 'location.pathname');
  if(!salesSummaryId && pathname === '/edit_sales_summary') {
    return <Redirect to={{
      pathname: '/add_sales_summary',
      state: {
        salesSummaryId,
        salesIsSubmitted,
        preselectedFormValues,
        computedSalesData
      }
    }}/>;
  }

  return (
    <div>
      { (!dataReady || validatingForm) &&
        <div className="content loading-overlay" style={{flexDirection: "column"}}>
          <h3>Loading Data... Please wait for a moment</h3>
          <p>Please do not close or refresh this page, unless there is server connection error</p>
        </div>
      }
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li><Link to="/sales_summary">Sales Summary</Link></li>
          <li className="is-active"><a href="# " aria-current="page">Sales Summary Details</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              {salesSummaryId ? 'Edit ' : 'New '}Sales Summary
            </h1>
          </div>
        </div>
      </section>
      <Form
        onSubmit={ values => {
          const {saveAs, wholesaler} = values;
          const date = moment(values.date).format('YYYY-MM-DD[T00:00:00Z]');
          const formValues = {saveAs, wholesaler, date};

          setValidatingForm(true);
          mValidationOnSubmit(sales, formValues);
        }}
        initialValues={salesSummaryId && Object.values(salesSummaryDetails).length ? salesSummaryDetails : {wholesaler: currentWholesaler && currentWholesaler.id ? currentWholesaler : formWholesaler, date: formDate ? moment(formDate) : moment()}}
        keepDirtyOnReinitialize
        validate={mValidateSalesForm}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            { !values.submitted && <FormErrors formRules={Object.values(formRules)}/> }
            { salesSummaryId || !isAdmin
              ? <Field name="wholesaler">
                  {({input}) => <LabelField label="Wholesaler">{_.get(input.value, 'name')||''}</LabelField>}
                </Field>
              : <Field name="wholesaler">
                  {(props) => (
                    <Select
                      label="Wholesaler"
                      options={wholesalerList}
                      getOptionLabel={o => o.name}
                      getOptionValue={o => o.id}
                      placeholder="Select Wholesaler..."
                      isLoading={isLoadingMaster}
                      isClearable
                      isSearchable
                      {...props}
                      input={{...props.input, onChange: val => {
                        setFormWholesaler(val);
                        props.input.onChange(val);
                      }}}
                    />
                  )}
                </Field>
            }
            { salesSummaryId
              ? <Field name="date">
                  {({input}) => <LabelField label="Month">{moment(input.value).format('MMM, YYYY')}</LabelField>}
                </Field>
              : <Field name="date">
                  {(props) => (
                    <DatePicker
                      label="Month"
                      dateFormat="MMM, yyyy"
                      showMonthYearPicker
                      selected={moment(props.input.value).toDate()}
                      {...props}
                      input={{...props.input, onChange: val => {
                        setFormDate(moment(val));
                        props.input.onChange(val);
                      }}}
                    />
                  )}
                </Field>
            }
            { salesSummaryId
              ? <Field name="submitted">
                  {({input}) => <LabelField label="Status">
                    { input.value
                      ? <p className="has-text-primary">Submitted</p>
                      : <p className="has-text-draft">Draft</p>
                    }
                  </LabelField>}
                </Field>
              : <LabelField label="Status"><p className="has-text-grey">New</p></LabelField>
            }
            { !values.submitted
              && <LabelField label="">
                  <div style={{width: '100%', textAlign: 'right'}}>
                    <Link
                      type="button"
                      className={`button is-outlined is-excel ${isLoading ? 'is-loading' : ''}`}
                      to={{
                        pathname: '/upload_sales',
                        state: {
                          fromSales: true,
                          salesSummaryId,
                          salesIsSubmitted,
                          outletList,
                          productList,
                          preselectedFormValues: {
                            formWholesaler,
                            formDate: formDate ? formDate.format() : formDate
                          }
                        }
                      }}
                    >
                      <span className="icon">
                        <i className="fas fa-file-excel"></i>
                      </span>
                      <span>Data Upload</span>
                    </Link>
                  </div>
                </LabelField>
            }
            { dataReady
              ? <div onKeyDown={_handleKeyDown(triggerNewLineEvent, setTriggerNewLineEvent)}>
                  <ReactTable
                    data={sales}
                    loading={isLoading}
                    columns={salesSummaryId && values.submitted ? submittedSalesColumn : salesColumns}
                    defaultPageSize={100}
                    showPagination={true}
                    sortable={false}
                    className="-striped"
                    NoDataComponent={() => null}
                    getTrProps={(_, rowInfo) => {
                      if(!(indexWithErrors.length && rowInfo))
                        return { className: '' }

                      return { className: indexWithErrors.includes(rowInfo.index) ? 'error' : '' }
                    }}
                  />
                </div>
              : <div className="container has-text-centered">Loading data... Please wait for a moment</div>
            }
            { !values.submitted
              ? <>
                  <div className="column is-full buttons" style={{marginTop: '50px', justifyContent: 'center'}}>
                    <Button
                      type="submit"
                      className="is-draft"
                      onClick={() => {
                        form.change("saveAs", "draft");
                      }}
                      disabled={submitting || validating || isLoading || validatingForm}
                      loading={submitting || validating || isLoading || validatingForm}
                    >Save Draft</Button>
                  </div>
                  <div className="column is-full buttons" style={{marginTop: '30px', justifyContent: 'center'}}>
                    <label className="checkbox" style={{fontSize: '12pt', textAlign: 'center'}}>
                      <input
                        type="checkbox"
                        style={{width: 15, height: 15}}
                        checked={submitCheck}
                        onChange={() => {setSubmitCheck(!submitCheck)}}
                      />
                      {' '}Submit Sales Summary? Sales Summary should only be submitted at end of the month, use 'Save Draft' instead to save this copy.
                    </label>
                  </div>
                  <div className="column is-full buttons" style={{justifyContent: 'center'}}>
                    <Button
                      type="submit"
                      className="is-primary"
                      onClick={() => {
                        form.change("saveAs", "submit");
                      }}
                      disabled={submitting || validating || isLoading || !submitCheck || validatingForm}
                      loading={submitting || validating || isLoading || validatingForm}
                    >Submit</Button>
                  </div>
                </>
              : isAdmin
                ? <>
                    <div className="column is-full buttons" style={{marginTop: '30px', justifyContent: 'center'}}>
                      <label className="checkbox" style={{fontSize: '12pt', textAlign: 'center'}}>
                        <input
                          type="checkbox"
                          style={{width: 15, height: 15}}
                          checked={submitCheck}
                          onChange={() => {setSubmitCheck(!submitCheck)}}
                        />
                        {' '}Revert Sales Summary back to Draft? Please only change if really necessary, else you can edit a submitted Sales Summary too.
                      </label>
                    </div>
                    <div className="column is-full buttons" style={{justifyContent: 'center'}}>
                      <Button
                        type="submit"
                        className="is-draft"
                        onClick={() => {
                          form.change("saveAs", "revert");
                        }}
                        disabled={submitting || validating || isLoading || !submitCheck || validatingForm}
                        loading={submitting || validating || isLoading || validatingForm}
                      >Revert to Draft</Button>
                    </div>
                  </>
                : <></>
            }
            <div className="container has-text-centered has-text-danger">{formValidationMsg}</div>
          </form>
        )}
      />
    </div>
  );
};

SalesForm.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(SalesForm);