import stringify from 'json-stringify-safe';
import CryptoJSCore from 'crypto-js/core';
import AES from 'crypto-js/aes';
import * as Sentry from '@sentry/browser';

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

const getItem = (key) => {
  const encryptedValue = window.localStorage.getItem(key)
  if(!encryptedValue)
    return encryptedValue;
  const bytes = AES.decrypt(encryptedValue, encryptionKey);
  const decryptedString = bytes.toString(CryptoJSCore.enc.Utf8);
  let decryptedValue = {};
  try {
    decryptedValue = JSON.parse(decryptedString);
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error("JSON ERROR: ", error);
      console.error("String To Parse: ", decryptedString);
    } else {
      // Set user information, as well as tags and further extras
      Sentry.configureScope(scope => {
        scope.setExtra("String To Parse", decryptedString);
      });
      
      // Capture exceptions
      Sentry.captureException(error);
    }
  }
  return decryptedValue;
};

const setItem = (key, value) => {
  const stringifyValue = stringify(value);
  const encryptedValue = AES.encrypt(stringifyValue, encryptionKey).toString();
  try {
    window.localStorage.setItem(key, encryptedValue);
  } catch (error) {
    if (JSON.stringify(error).includes("exceed")) {
      Object.keys(window.localStorage).forEach(lsKey => {
        if(lsKey.includes('sales.'))
          window.localStorage.removeItem(lsKey);
      });
    }

    if (process.env.NODE_ENV === 'development') {
      console.error("JSON ERROR: ", error);
      console.error("Item to store on localStorage: ", key, value);
    } else {
      // Set user information, as well as tags and further extras
      Sentry.configureScope(scope => {
        scope.setExtra("Key to store on localStorage", key);
        scope.setExtra("Value to store on localStorage", value);
      });
      
      // Capture exceptions
      Sentry.captureException(error);
    }
  }
};

const removeItem = (key) => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error("JSON ERROR: ", error);
      console.error("Key to remove on localStorage: ", key);
    } else {
      // Set user information, as well as tags and further extras
      Sentry.configureScope(scope => {
        scope.setExtra("Key to remove on localStorage", key);
      });
      
      // Capture exceptions
      Sentry.captureException(error);
    }
  }
};

const convertSalesKey = (salesId) => `sales.${salesId}`;
const getSalesItem = (salesId) => getItem(convertSalesKey(salesId));
const setSalesItem = (salesId, value) => setItem(convertSalesKey(salesId), value);
const removeSalesItem = (salesId) => removeItem(convertSalesKey(salesId));

const convertStocksKey = (stocksId) => `stocks.${stocksId}`;
const getStocksItem = (stocksId) => getItem(convertStocksKey(stocksId));
const setStocksItem = (stocksId, value) => setItem(convertStocksKey(stocksId), value);
const removeStocksItem = (stocksId) => removeItem(convertStocksKey(stocksId));

export default { getItem, setItem, removeItem, getSalesItem, setSalesItem, removeSalesItem, getStocksItem, setStocksItem, removeStocksItem };
