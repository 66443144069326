import {
  CREATE_MESSAGE,
  REMOVE_MESSAGE
} from '../actions';

const INITIAL_STATE = {
  messageName: null,
  messageType: null,
  messageTimeout: 10000
};

export default (state = INITIAL_STATE, action) => {
  const {type, ...rest} = action;
  switch(type) {
    case CREATE_MESSAGE: {
      return {...state, ...rest};
    }

    case REMOVE_MESSAGE: {
      return {...state, ...INITIAL_STATE};
    }

    default: {
      return state;
    }
  }
};