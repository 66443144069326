import _ from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import { FormErrors, TextInput, SubmitButton, resetPasswordFormRules } from '../../components/Forms';
import { resetPassword } from '../../actions';

// Form Validation
let formRules = _.cloneDeep(resetPasswordFormRules);

const validateRPForm = (values) => {
  const {secret_key, password, confirm_password} = values;
  let errors = {};

  if(!secret_key) {
    formRules.secret_required.valid = false;
    errors.secret_key = true;
  } else {
    formRules.secret_required.valid = true;
  }

  if(!password || password.length < 8) {
    formRules.password_length.valid = false;
    errors.password = true;
  } else {
    formRules.password_length.valid = true;

    if(!confirm_password || password !== confirm_password) {
      formRules.password_match.valid = false;
      errors.confirm_password = true;
    } else {
      formRules.password_match.valid = true;
    }
  }

  return errors;
}

const ResetPassword = (props) => {
  const {history} = props;

  const isLoading = useSelector(({auth}) => auth.isLoading);

  const dispatch = useDispatch();

  // Reset formRules
  useEffect(() => {
    formRules = _.cloneDeep(resetPasswordFormRules);
  }, []);

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/login">Login</Link></li>
          <li><Link to="/forgot_password">Forgot Password</Link></li>
          <li className="is-active"><a href="# " aria-current="page">Reset Password</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              Reset Password
            </h1>
          </div>
        </div>
      </section>
      <Form
        onSubmit={ values => {
          // if(window.confirm("Confirm to reset password?")) {
            dispatch(resetPassword(values, () => history.push('/login')));
          // }
        }}
        validate={validateRPForm}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <FormErrors formRules={Object.values(formRules)}/>
              <Field name="secret_key" type="password">
                {(props) => (
                  <TextInput
                    horizontal
                    label="Secret Key"
                    disabled={isLoading}
                    {...props}
                  />
                )}
              </Field>
              <Field name="password" type="password">
                {(props) => (
                  <TextInput
                    horizontal
                    label="New Password"
                    disabled={isLoading}
                    {...props}
                  />
                )}
              </Field>
              <Field name="confirm_password" type="password">
                {(props) => (
                  <TextInput
                    horizontal
                    label="Confirm New Password"
                    disabled={isLoading}
                    {...props}
                  />
                )}
              </Field>
            <SubmitButton
              disabled={submitting || validating}
            >Reset Password</SubmitButton>
          </form>
        )}
      />
    </div>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object.isRequired
}

export default withRouter(ResetPassword);