import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

class DateInput extends React.Component { 
  render() {
    const {meta, isFieldArray, value, dateFormat, ...rest} = this.props;
    return (
      <div className="control has-icons-right">
        <input
          {...rest}
          className={`input ${meta && meta.error && meta.touched ? 'is-danger' : ''}`}
          value={value}
        />
        <span className="icon is-right">
          <i className="fas fa-calendar-alt"></i>
        </span>
      </div>
    );
  }
}

const CustomDatePicker = ({label, input, meta, dateFormat, onChange, ...rest}) => onChange
? (
  <div>
    <DatePicker
      strictParsing
      dateFormat={dateFormat}
      customInput={<DateInput meta={meta} isFieldArray={!!onChange} dateFormat={dateFormat}/>}
      onChange={onChange}
      {...input}
      {...rest}
    />
  </div>
):(
  <div className="field is-horizontal">
    <div className="field-label">
      <label className="label">{label}</label>
    </div>
    <div className="field-body">
      <div className="field" style={styles.inputField}>
        <div className="control">
          <DatePicker
            strictParsing
            dateFormat={dateFormat}
            customInput={<DateInput meta={meta} dateFormat={dateFormat}/>}
            {...input}
            {...rest}
            onChange={e => e && input.onChange(e)}
          />
        </div>
      </div>
    </div>
  </div>
);

CustomDatePicker.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.instanceOf(Date).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }),
  meta: PropTypes.object,
  onChange: PropTypes.func
};

const styles = {
  inputField: {
    maxWidth: '500px',
  }
};

export default CustomDatePicker;