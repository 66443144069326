import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import logoFull from '../../assets/images/logo-full.png';
import { FormErrors, TextInput, SubmitButton, loginFormRules } from '../../components/Forms';
import { loginUser } from '../../actions';

// Form Validation
let formRules = _.cloneDeep(loginFormRules);

const validateEmail = (email) => {
  // eslint-disable-next-line
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const validateLoginForm = (values) => {
  const {email, password} = values;
  let errors = {};

  if(!validateEmail(email)) {
    formRules.email_format.valid = false;
    errors.email = true;
  } else {
    formRules.email_format.valid = true;
  }

  if(!password || password.length < 8) {
    formRules.password_length.valid = false;
    errors.password = true;
  } else {
    formRules.password_length.valid = true;
  }

  return errors;
}

const Login = (props) => {
  const isAdmin = props.isAdmin;

  const isLoading = useSelector(({auth}) => auth.isLoading);
  const dispatch = useDispatch();

  // Reset formRules
  useEffect(() => {
    formRules = _.cloneDeep(loginFormRules);
  }, []);

  return (
    <div>
      <div className="column is-full" style={styles.logoContainer}>
        <figure className="image" style={styles.logo}>
          <img src={logoFull} alt=""/>
        </figure>
      </div>
      { isAdmin &&
        <div className="column is-full" style={styles.loginAsContainer}>
          <span className="has-text-primary is-size-4 has-text-weight-bold">Admin Login</span>
        </div>
      }
      <Form
        onSubmit={ values => dispatch(loginUser(isAdmin, values))}
        validate={validateLoginForm}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="column is-full" style={styles.errorContainer}>
              <div className="field" style={styles.errorField}>
                <FormErrors formRules={Object.values(formRules)}/>
              </div>
            </div>
            <Field name="email" type="text">
              {(props) => (
                <TextInput 
                  label="Email"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="password" type="password">
              {(props) => (
                <TextInput 
                  label="Password"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <SubmitButton
              disabled={submitting || validating}
            >Login</SubmitButton>
          </form>
        )}
      />
      <div className="column is-full" style={styles.linkContainer}>
        <Link className={"button is-inverted is-primary"} to="/forgot_password">Forgot Password?</Link>
      </div>
    </div>
  );
};

const loginWidth = '500px';
const center = {
  display: 'flex',
  justifyContent: 'center'
};
const styles = {
  logoContainer: {
    ...center
  },
  logo: {
    maxWidth: loginWidth,
  },
  loginAsContainer: {
    ...center,
    alignItems: 'center',
    marginTop: '50px',
  },
  errorContainer: {
    ...center,
    marginTop: '30px',
  },
  errorField: {
    width: loginWidth,
  },
  linkContainer: {
    ...center,
    marginTop: '20px',
  },
};

export default Login;