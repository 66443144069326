import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = ({label, desc, disabled=false, input, meta, ...rest}) => (
  <div className="field is-horizontal">
    <div className="field-label">
      <label className="label">{label}</label>
    </div>
    <div className="field-body">
      <div className="field" style={styles.inputField}>
        <div className="control">
          <label className="checkbox">
            <input
              disabled={disabled}
              {...input}
              {...rest}
            />
            {" "}{desc}
          </label>
        </div>
      </div>
    </div>
  </div>
);

CheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["checkbox"]).isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.object.isRequired
};

const styles = {
  inputField: {
    maxWidth: '500px',
  }
};

export default CheckBox;