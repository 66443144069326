import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import moment from 'moment';

import ReactTableFilterable from '../../components/ReactTableFilterable';
import { FormErrors, DatePicker, SubmitButton, reportRules } from '../../components/Forms';
import { fetchNewOutletsListing } from '../../actions';
import {CSVFromTable} from '../../components/ExportCSV';

// variables for table
const nameColumn = {
  Header: 'Outlet Name',
  accessor: 'name',
  minWidth: 200
};
const codeColumn = {
  Header: 'Outlet Code',
  accessor: 'code',
  minWidth: 100
};
const branchColumn = {
  Header: 'Branch',
  accessor: 'branch',
  minWidth: 150
};
const contractColumn = {
  Header: 'Contract Type',
  accessor: 'contract.contract',
  minWidth: 150
};
const outletColumn = {
  Header: 'Outlet Type',
  accessor: 'outlet_type.outlet_type',
  minWidth: 200
};
const legalNameColumn = {
  Header: 'Legal Name',
  accessor: 'legal_name',
  minWidth: 200
};
const createdByColumn = {
  Header: 'Created By',
  accessor: 'created_by.username',
  minWidth: 200
};
const createdDateColumn = {
  Header: 'Created Date',
  accessor: 'created',
  Cell: row => moment.utc(row.value).local().format('YYYY-MM-DD HH:mm'),
  minWidth: 150
};
const activeColumn = {
  Header: 'Status',
  id: 'status',
  accessor: 'active',
  Cell: row => (
    <span>
      <span style={{
        color: row.value ? '#57d500' : '#ff2e00',
        transition: 'all .3s ease'
      }}>
        &#x25cf;
      </span> {
        row.value  ? 'Active' : 'Deactivated'
      }
    </span>
  ),
  minWidth: 100
};
const reportColumns = [nameColumn, codeColumn, branchColumn, contractColumn, outletColumn, legalNameColumn, createdByColumn, createdDateColumn, activeColumn];

// Validate form
let formRules = _.cloneDeep(reportRules);

const validateNewOutletsListing = (values) => {
  const {start_date, end_date} = values;
  let errors = {};

  if(moment(start_date) > moment(end_date)) {
    formRules.date_range.valid = false;
    errors.start_date = true;
    errors.end_date = true;
  } else {
    formRules.date_range.valid = true;
  }

  return errors;
}

const monthStart = moment().startOf('month').toDate();
const monthEnd = moment().endOf('month').toDate();

const NewOutletsListing = (props) => {
  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const isLoading = useSelector(({outlet}) => outlet.isLoading);

  const dispatch = useDispatch();

  const [filterStartDate, setFilterStartDate] = useState(monthStart);
  const [filterEndDate, setFilterEndDate] = useState(monthEnd);
  useEffect(() => {
    if(authToken) {
      const startDate = moment(filterStartDate).utc().format('YYYY-MM-DDTHH:mm:ss');
      const endDate = moment(filterEndDate).utc().format('YYYY-MM-DDTHH:mm:ss');

      if(isAdmin) {
        dispatch(fetchNewOutletsListing(authToken, isAdmin, startDate, endDate));
      }
    }
  }, [filterStartDate, filterEndDate, authToken, isAdmin, dispatch]);
  const data = useSelector(({outlet}) => outlet.newOutletsListing) || [];

  const dataTableRef = useRef(null);

  if(!authToken || !isAdmin)
    return null;

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li><Link to="/reports">Reports</Link></li>
          <li className="is-active"><a href="# " aria-current="page">New Outlets Listing</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              New Outlets Listing
            </h1>
          </div>
        </div>
      </section>
      <Form
        onSubmit={ values => {
          const {start_date, end_date} = values;
          if(start_date !== filterStartDate) {
            setFilterStartDate(start_date);
          }
          if(end_date !== filterEndDate) {
            setFilterEndDate(end_date);
          }
        }}
        initialValues={{
          start_date: monthStart,
          end_date: monthEnd
        }}
        keepDirtyOnReinitialize
        validate={validateNewOutletsListing}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <FormErrors formRules={Object.values(formRules)}/>
            <Field name="start_date">
              {(props) => (
                <DatePicker
                  label="From Date"
                  dateFormat="yyyy-MM-dd"
                  selected={moment(props.input.value).toDate()}
                  {...props}
                />
              )}
            </Field>
            <Field name="end_date">
              {(props) => (
                <DatePicker
                  label="To Date"
                  dateFormat="yyyy-MM-dd"
                  selected={moment(props.input.value).toDate()}
                  {...props}
                />
              )}
            </Field>
            <div className="column is-full has-text-centered" style={{marginBottom: '30px'}}>
              <SubmitButton
                disabled={submitting || validating}
                loading={submitting || validating || isLoading}
              >Filter</SubmitButton>
            </div>
            <div className="column is-full has-text-right" style={{marginBottom: '20px'}}>
              <CSVFromTable
                tableRef={dataTableRef}
                columns={reportColumns}
                fileName="NewOutletListing"
                loading={submitting || validating || isLoading}
              >
                <span className="icon">
                  <i className="fas fa-file-excel"></i>
                </span>
                <span>Export to Excel</span>
              </CSVFromTable>
            </div>
          </form>
        )}
      />
      <div>
        <ReactTableFilterable
          data={data}
          columns={reportColumns}
          defaultPageSize={10}
          className="-striped -highlight"
          ref={dataTableRef}
        />
      </div>
    </div>
  );
};

export default NewOutletsListing;