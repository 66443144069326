import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import ReactTableFilterable from '../../components/ReactTableFilterable';
import { FormErrors, deactivateMessage } from '../../components/Forms';
import { fetchUserList } from '../../actions';

// variables for table
const usernameColumn = {
  Header: 'Username',
  accessor: 'username',
  minWidth: 250
};
const emailColumn = {
  Header: 'Email',
  accessor: 'email',
  minWidth: 250
};
const wholesalerColumn = {
  Header: 'Wholesaler',
  accessor: 'wholesaler.name',
  minWidth: 300
};
const activeColumn = {
  Header: 'Status',
  id: 'status',
  accessor: 'active',
  Cell: row => (
    <span>
      <span style={{
        color: row.value ? '#57d500' : '#ff2e00',
        transition: 'all .3s ease'
      }}>
        &#x25cf;
      </span> {
        row.value  ? 'Active' : 'Deactivated'
      }
    </span>
  ),
  minWidth: 150
};

const UserList = (props) => {
  const forAdmin = props.forAdmin;

  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const isLoading = useSelector(({user}) => user.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if(authToken && isAdmin) {
      dispatch(fetchUserList(authToken, isAdmin, forAdmin));
    }
  }, [authToken, isAdmin, forAdmin, dispatch]);
  const data = useSelector(({user}) => forAdmin ? user.adminList : user.executiveList) || [];

  // variables for tables
  const editColumn = {
    Header: 'Edit',
    id: 'edit',
    accessor: user => user,
    Cell: row => (
      <div className="has-text-centered">
        <Link className="button is-primary is-outlined" to={{
          pathname: forAdmin ? '/edit_admin' : 'edit_executive',
          state: {userToUpdate: row.value}
        }}>
          <span className="icon">
            <i className="fas fa-edit"/>
          </span>
        </Link>
      </div>
    ),
    width: 100
  }
  const adminColumns = [usernameColumn, emailColumn, activeColumn, editColumn];
  const executiveColumns = [usernameColumn, emailColumn, wholesalerColumn, activeColumn, editColumn];

  if(!authToken || !isAdmin)
    return null;

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li className="is-active"><a href="# " aria-current="page">{forAdmin ? 'Admin' : 'Executive'}</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              {forAdmin ? 'Admin List' : 'Executive List'}
            </h1>
          </div>
        </div>
      </section>
      <div className="column is-full has-text-right" style={{marginBottom: '50px'}}>
        <Link
          className={`button is-outlined is-primary ${isLoading && 'is-loading'}`}
          to={forAdmin ? '/add_admin' : '/add_executive'}
        >{forAdmin ? 'New Admin' : 'New Executive'}</Link>
      </div>
      <FormErrors formRules={Object.values(deactivateMessage)}/>
      <div>
        <ReactTableFilterable
          data={data}
          columns={forAdmin ? adminColumns : executiveColumns}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    </div>
  );
};

UserList.propTypes = {
  forAdmin: PropTypes.bool.isRequired,
};

export default UserList;