import {
  LOADING_OUTLET,
  FETCH_OUTLET_LIST,
  FETCH_OUTLET,
  CREATE_OUTLET,
  UPDATE_OUTLET,
  FETCH_NEW_OUTLETS_LISTING,
  COMPLETE_OUTLET,
  CLEAR_OUTLET,
} from '../actions';

const INITIAL_STATE = {
  isLoading: false,
  outletList: [],
  outletToUpdate: undefined
};

export default (state = INITIAL_STATE, action) => {
  const {type, ...rest} = action;
  switch(type) {
    case LOADING_OUTLET: {
      return {...state, isLoading: true};
    }

    case FETCH_OUTLET_LIST:
    case FETCH_OUTLET:
    case FETCH_NEW_OUTLETS_LISTING:
    case CLEAR_OUTLET: {
      return {...state, ...rest, isLoading: false};
    }

    case CREATE_OUTLET: {
      const {newOutlet} = action;
      const outletList = [...state.outletList, newOutlet];
      return {...state, outletList, isLoading: false};
    }

    case UPDATE_OUTLET: {
      const {changedOutlet} = action;
      const outletList = state.outletList.map(o => o.id === changedOutlet.id ? changedOutlet : o);
      return {...state, outletList, isLoading: false};
    }

    case COMPLETE_OUTLET: {
      return {...state, isLoading: false};
    }

    default: {
      return state;
    }
  }
};