import _ from 'lodash';
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import { FormErrors, Select, TextInput, CheckBox, SubmitButton, productFormRules } from '../../components/Forms';
import { fetchProductList, createProduct, updateProduct, fetchMarketList } from '../../actions';

let formRules = _.cloneDeep(productFormRules);

const ProductForm = (props) => {
  const productToUpdate = _.get(props, 'location.state.productToUpdate');
  const history = props.history;

  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const isLoading = useSelector(({product}) => product.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if(authToken && isAdmin) {
      dispatch(fetchProductList(authToken));
      dispatch(fetchMarketList(authToken));
    }
  }, [authToken, isAdmin, dispatch]);
  const productList = useSelector(({product}) => product.productList);
  const marketList = useSelector(({master}) => master.marketList);
  const isLoadingMaster = useSelector(({master}) => master.isloading);

  // Form Validation
  const verifyUniqueFields = (values) => {
    const {name} = values;
    let errors = {};
    const productListExcludeCurrentProduct = productToUpdate ? productList.filter(p => p.id !== productToUpdate.id) : productList;

    formRules.name_taken.pristine = false;
    if(productListExcludeCurrentProduct.some(p => _.get(p, 'name', '').toLowerCase().trim() === (name||'').toLowerCase().trim())) {
      formRules.name_taken.valid = false;
      errors.name = true;
    } else {
      formRules.name_taken.valid = true;
    }

    return errors;
  }
  const mVerifyUniqueFields = useCallback(verifyUniqueFields, [productList]);

  const validateProductForm = (values) => {
    const {brand, sub_brand, name, sku_no, market} = values;
    let errors = {};

    if(!brand) {
      formRules.brand_required.valid = false;
      errors.brand = true;
    } else {
      formRules.brand_required.valid = true;
    }

    if(!sub_brand) {
      formRules.sub_brand_required.valid = false;
      errors.sub_brand = true;
    } else {
      formRules.sub_brand_required.valid = true;
    }

    if(!name) {
      formRules.name_required.valid = false;
      errors.name = true;
    } else {
      formRules.name_required.valid = true;
    }

    if(!sku_no) {
      formRules.sku_required.valid = false;
      errors.sku_no = true;
    } else {
      formRules.sku_required.valid = true;
    }

    if(!market) {
      formRules.market_required.valid = false;
      errors.market = true;
    } else {
      formRules.market_required.valid = true;
    }

    return Object.keys(errors).length ? errors : mVerifyUniqueFields(values);
  }
  const mValidateProductForm = useCallback(validateProductForm, [mVerifyUniqueFields]);

  // Reset formRules
  useEffect(() => {
    formRules = _.cloneDeep(productFormRules);
  }, []);

  // Validate initial values once on CDM - future refactor and remove eslint-disable
  useEffect(() => {
    productToUpdate && mValidateProductForm(productToUpdate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  if(!authToken || !isAdmin) {
    return null;
  }

  const pathname = _.get(props, 'location.pathname');
  if(!productToUpdate && pathname === '/edit_product') {
    return <Redirect to='/add_product'/>;
  }

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li><Link to="/product">Product</Link></li>
          <li className="is-active"><a href="# " aria-current="page">{productToUpdate ? 'Edit ' : 'New '}Product</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              {productToUpdate ? 'Edit ' : 'New '}Product
            </h1>
          </div>
        </div>
      </section>
      <Form
        onSubmit={ values => {
          // if(window.confirm(`Confirm to ${productToUpdate ? 'update' : 'create'} Product?`)) {
            if(productToUpdate) {
              dispatch(updateProduct(authToken, isAdmin, values, () => history.push("/product")));
            } else {
              dispatch(createProduct(authToken, isAdmin, values, () => history.push("/product")));
            }
          // }
        }}
        initialValues={productToUpdate || {active: true}}
        validate={mValidateProductForm}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <FormErrors formRules={Object.values(formRules)}/>
            <Field name="brand" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Brand"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="sub_brand" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Sub Brand"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="name" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Product Name"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="sku_no" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="SKU No."
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="pack_size" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Pack Size"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="bottle_size" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Bottle Size"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="market">
              {(props) => (
                <Select
                  label="Market"
                  options={marketList}
                  getOptionLabel={o => o.market}
                  getOptionValue={o => o.id}
                  placeholder="Select Market..."
                  isLoading={isLoadingMaster}
                  isClearable
                  isSearchable
                  {...props}
                />
              )}
            </Field>
            { productToUpdate &&
              <Field name="active" type="checkbox">
                {(props) => (
                  <CheckBox
                    label="Status"
                    desc="Active"
                    disabled={isLoading}
                    {...props}
                  />
                )}
              </Field>
            }
            <SubmitButton
              disabled={pristine || submitting || validating}
              loading={submitting || validating}
            >{productToUpdate ? 'Update' : 'Add'}</SubmitButton>
          </form>
        )}
      />
    </div>
  );
};

ProductForm.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(ProductForm);