export const CREATE_MESSAGE = 'create_message';
export const REMOVE_MESSAGE = 'remove_message';

export const createMessage = (messageName, messageType, messageTimeout=10000) => {
  return (dispatch) => {
    dispatch({
      type: CREATE_MESSAGE,
      messageName,
      messageType,
      messageTimeout
    });
  };
};

export const removeMessage = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_MESSAGE
    });
  };
};