import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import ReactTable from 'react-table';
// import moment from 'moment';

const exportCSV = (csvData, fileName, isSalesForceReport) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.json_to_sheet(csvData, {dateNF:'yyyy-mm-dd;@',cellDates:false});
  // Workaround to remove timestamp from date values
  // Date fields are saved as numbers, with "cellDates:false" above
  // Convert all number fields to whole number
  Object.keys(ws).forEach(key => {
    if(ws[key]["t"] === "n") {
      ws[key]["v"] = Math.floor(ws[key]["v"]);
    }
    // Convert Salesforce Report's first column (invoice date) to text format
    if (isSalesForceReport && key && key.toString().includes("A")) {
      ws[key]["z"] = "@";
    }
  });
  // --END-- Workaround to remove timestamp from date values
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], {type: fileType});
  FileSaver.saveAs(data, fileName + fileExtension);
};

const exportCSVTabs = (csvData, fileName) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  var wb = { Sheets: {}, SheetNames: []};
  csvData.forEach(value => {
    const {data, name} = value;
    const ws = XLSX.utils.json_to_sheet(data, {dateNF:'yyyy-mm-dd;@',cellDates:false});
    // Workaround to remove timestamp from date values
    // Date fields are saved as numbers, with "cellDates:false" above
    // Convert all number fields to whole number
    Object.keys(ws).forEach(key => {
      if(ws[key]["t"] === "n") {
        ws[key]["v"] = Math.floor(ws[key]["v"]);
      }
    });
    // --END-- Workaround to remove timestamp from date values
    const {Sheets, SheetNames} = wb;
    wb = { Sheets: { ...Sheets, [name]: ws }, SheetNames: [...SheetNames, name] };
  });
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], {type: fileType});
  FileSaver.saveAs(data, fileName + fileExtension);
}

const outletColString = (outlet) => outlet ? `${outlet.name ? outlet.name : '-'}${outlet.branch ? ` @ ${outlet.branch}` : ''}` : ''

const CSVFromTable = ({tableRef, columns, fileName, className, loading, children, ...rest}) => (
  <button
    type="button"
    className={`button is-outlined is-excel is-hidden-touch ${className || ''} ${loading ? 'is-loading' : ''}`}
    onClick={() => {
      const currentRecords = tableRef.current.getResolvedState().sortedData;
      var data_to_download = [];
      for (var index = 0; index < currentRecords.length; index++) {
        let record_to_download = {};
        for(var colIndex = 0; colIndex < columns.length ; colIndex ++) {
          record_to_download[columns[colIndex].Header] = columns[colIndex].accessor === "outlet" 
          ? outletColString(currentRecords[index][columns[colIndex].accessor])
          : currentRecords[index][columns[colIndex].accessor];
        }
        data_to_download.push(record_to_download);
      }
      if (fileName === "SalesforceReport")
        exportCSV(data_to_download, fileName, true);
      else
        exportCSV(data_to_download, fileName, false);
    }}
    onMouseDown={e => e.preventDefault()}
    {...rest}
  >
    {children}
  </button>
);

CSVFromTable.propTypes = {
  tableRef: PropTypes.shape({ current: PropTypes.instanceOf(ReactTable) }).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    Header: PropTypes.any.isRequired,
    accessor: PropTypes.any.isRequired
  })).isRequired,
  fileName: PropTypes.string.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool
};

const CSVFromArray = ({tabsData, fileName, className, loading, children, ...rest}) => (
  <button
    type="button"
    className={`button is-outlined is-primary ${className || ''} ${loading ? 'is-loading' : ''}`}
    onClick={() => {
      var tabs_data_to_download = [];
      Object.keys(tabsData).forEach(tab => {
        const {dataArray, columns} = tabsData[tab];
        var data_to_download = [];
        for (var index = 0; index < dataArray.length; index++) {
          let record_to_download = {}
          for(var colIndex = 0; colIndex < columns.length ; colIndex ++) {
            record_to_download[columns[colIndex].Header] = _.get(dataArray[index], columns[colIndex].accessor, "");
          }
          data_to_download.push(record_to_download);
        }
        tabs_data_to_download.push({
          name: tab,
          data: data_to_download
        })
      })
      exportCSVTabs(tabs_data_to_download, fileName);
    }}
    onMouseDown={e => e.preventDefault()}
    {...rest}
  >
    {children}
  </button>
);

CSVFromArray.propTypes = {
  tabsData: PropTypes.objectOf(
    PropTypes.shape({
      dataArray: PropTypes.array.isRequired,
      columns: PropTypes.arrayOf(PropTypes.shape({
        Header: PropTypes.any.isRequired,
        accessor: PropTypes.any.isRequired
      })).isRequired
    })
  ).isRequired,
  fileName: PropTypes.string.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool
};

export {CSVFromTable, CSVFromArray};


