import _ from 'lodash';
import client from './client';
import { createMessage } from '.';

export const LOADING_AUTH = 'loading_auth';
export const LOGIN_USER = 'login_user';
export const FETCH_CURRENT_USER = 'fetch_current_user';
export const LOGOUT_USER = 'logout_user';
export const COMPLETE_AUTH = 'complete_auth';

export const loginUser = (isAdmin, values) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_AUTH
    });

    const {email, password} = values;
    client.login(email, password, isAdmin)
    .then(({data}) => {
      dispatch({
        type: LOGIN_USER,
        authToken: _.get(data, 'token')
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err, false), 'danger'));
      dispatch({
        type: COMPLETE_AUTH
      });
    });
  };
};

export const getCurrentUser = (authToken) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_AUTH
    });

    client.getCurrentUser(authToken)
    .then(({data}) => {
      dispatch({
        type: FETCH_CURRENT_USER,
        currentUser: data,
        isAdmin: _.get(data, 'admin')
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: LOGOUT_USER
      });
    });
  };
}

export const logoutUser = (authToken) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_AUTH
    });

    client.logout(authToken)
    .then(({data}) => {
      dispatch({
        type: LOGOUT_USER
      });
      dispatch(createMessage('You have logged out successfully.', 'success'));
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_AUTH
      });
    });
  };
};

export const forgotPassword = (values, redirectOnSuccess) => {
  const {email} = values;
  return (dispatch) => {
    dispatch({
      type: LOADING_AUTH
    });

    client.forgotPassword(email)
    .then(({data}) => {
      dispatch(createMessage('Request completed successfully. Please check your email for secret key.', 'success'));
      redirectOnSuccess();
      dispatch({
        type: COMPLETE_AUTH
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_AUTH
      });
    });
  };
}

export const resetPassword = (values, redirectOnSuccess) => {
  const {secret_key, password} = values;
  return (dispatch) => {
    dispatch({
      type: LOADING_AUTH
    });

    client.resetPassword(secret_key, password)
    .then(({data}) => {
      dispatch(createMessage('Password reset successfully', 'success'));
      redirectOnSuccess();
      dispatch({
        type: COMPLETE_AUTH
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_AUTH
      });
    });
  };
}

export const changePassword = (authToken, values) => {
  const {password, new_password} = values;
  return (dispatch) => {
    dispatch({
      type: LOADING_AUTH
    });

    client.changePassword(authToken, password, new_password)
    .then(({data}) => {
      dispatch(createMessage('Password changed successfully. Please log in again.', 'success'));
      dispatch({
        type: LOGOUT_USER
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_AUTH
      });
    });
  };
};