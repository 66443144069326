import _ from 'lodash';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import createDecorator from "final-form-calculate";
import moment from 'moment';

import ReactTableFilterable from '../../components/ReactTableFilterable';
import { FormErrors, DatePicker, Select, TextInput, SubmitButton, reportRules } from '../../components/Forms';
import { fetchRegionList, fetchStateList, fetchWholesalerList, fetchOutletListLean, fetchProductList, fetchSalesSummaryReport } from '../../actions';
import {CSVFromTable} from '../../components/ExportCSV';

// variables for table
const invoiceDateColumn = {
  Header: 'Invoice Date',
  accessor: 'invoice_date',
  Cell: row => (moment(row.value).format('YYYY-MM-DD')),
  minWidth: 100
};
const invoiceNoColumn = {
  Header: 'Invoice No.',
  accessor: 'invoice_no',
  minWidth: 100
};
const wholesalerCodeColumn = {
  Header: 'Wholesaler Code',
  accessor: 'sales_summary.wholesaler.code',
  minWidth: 100
};
const wholesalerColumn = {
  Header: 'Wholesaler Name',
  accessor: 'sales_summary.wholesaler.name',
  minWidth: 150
};
const regionColumn = {
  Header: 'Region',
  accessor: 'sales_summary.wholesaler.region.region',
  minWidth: 100
};
const stateColumn = {
  Header: 'State',
  accessor: 'sales_summary.wholesaler.state.state',
  minWidth: 100
};
const outletCodeColumn = {
  Header: 'Outlet Code',
  accessor: 'outlet.code',
  minWidth: 100
};
const outletNameColumn = {
  Header: 'Outlet Name',
  accessor: 'outlet.name',
  minWidth: 150
};
const outletBranchColumn = {
  Header: 'Outlet Branch',
  accessor: 'outlet.branch',
  minWidth: 150
};
const legalNameColumn = {
  Header: 'Legal Name',
  accessor: 'outlet.legal_name',
  minWidth: 200
};
const productColumn = {
  Header: 'Product',
  accessor: 'product.name',
  minWidth: 200
};
const brandColumn = {
  Header: 'Brand',
  accessor: 'product.brand',
  minWidth: 100
};
const subBrandColumn = {
  Header: 'Sub-brand',
  accessor: 'product.sub_brand',
  minWidth: 150
};
const skuColumn = {
  Header: 'SKU No.',
  accessor: 'product.sku_no',
  minWidth: 100
};
const actualUnitsColumn = {
  Header: 'Actual Units Sold',
  accessor: 'actual_units',
  Cell: row => (row.value || 0),
  minWidth: 100
};
const standardTOColumn = {
  Header: 'Standard Trade Offer',
  accessor: 'standard_to_units',
  Cell: row => (row.value || 0),
  minWidth: 100
};
const additionalTOColumn = {
  Header: 'Additional Trade Offer',
  accessor: 'additional_to_units',
  Cell: row => (row.value || 0),
  minWidth: 100
};
const adminColumns = [invoiceDateColumn, invoiceNoColumn, wholesalerCodeColumn, wholesalerColumn, regionColumn, stateColumn, outletCodeColumn, outletNameColumn, outletBranchColumn, legalNameColumn, productColumn, brandColumn, subBrandColumn, skuColumn, actualUnitsColumn, standardTOColumn, additionalTOColumn];

// Validate form
let formRules = _.cloneDeep(reportRules);

const validateSalesforceReport = (values) => {
  const {start_date, end_date} = values;
  let errors = {};

  if(moment(start_date) > moment(end_date)) {
    formRules.date_range.valid = false;
    errors.start_date = true;
    errors.end_date = true;
  } else {
    formRules.date_range.valid = true;
  }

  return errors;
}

// Calculated wholesaler field
const calculator = createDecorator({
  field: 'region',
  updates: {
    wholesaler: function(region, {wholesaler}) {
      wholesaler = wholesaler || [];
      return wholesaler.filter(ws => !region || region.length === 0 || region.find(r => r.id === _.get(ws, 'region.id')));
    }
  }
}, {
  field: 'state',
  updates: {
    wholesaler: function(state, {wholesaler}) {
      wholesaler = wholesaler || [];
      return wholesaler.filter(ws => !state || state.length === 0 || state.find(s => s.id === _.get(ws, 'state.id')));
    }
  }
});

const monthStart = moment().startOf('month').toDate();
const monthEnd = moment().endOf('month').toDate();

const SalesforceReport = (props) => {
  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const isLoading = useSelector(({sales}) => sales.isLoading);

  const dispatch = useDispatch();

  const [filterStartDate, setFilterStartDate] = useState(monthStart);
  const [filterEndDate, setFilterEndDate] = useState(monthEnd);
  const [filterRegions, setFilterRegions] = useState([]);
  const [filterStates, setFilterStates] = useState([]);
  const [filterWholesalers, setFilterWholesalers] = useState([]);
  const [filterInvoiceNo, setFilterInvoiceNo] = useState('');
  const [filterOutlets, setFilterOutlets] = useState([]);
  const [filterBrands, setFilterBrands] = useState('');
  const [filterSubBrands, setFilterSubBrands] = useState('');
  const [filterProducts, setFilterProducts] = useState([]);
  useEffect(() => {
    if(authToken) {
      const startDate = moment(filterStartDate).format('YYYY-MM-DD[T00:00:00]');
      const endDate = moment(filterEndDate).format('YYYY-MM-DD[T00:00:00]');
      if(isAdmin) {
        dispatch(fetchSalesSummaryReport(authToken, isAdmin, startDate, endDate));
      }
    }
  }, [filterStartDate, filterEndDate, authToken, isAdmin, dispatch]);
  const data = useSelector(({sales}) => sales.salesSummaryReport) || [];

  useEffect(() => {
    if(authToken) {
      if(isAdmin) {
        dispatch(fetchRegionList(authToken));
        dispatch(fetchStateList(authToken));
        dispatch(fetchWholesalerList(authToken, isAdmin));
      }
      dispatch(fetchOutletListLean(authToken));
      dispatch(fetchProductList(authToken));
    }
  }, [authToken, isAdmin, dispatch]);
  const regionList = useSelector(({master}) => master.regionList);
  const stateList = useSelector(({master}) => master.stateList);
  const wholesalerList = useSelector(({wholesaler}) => wholesaler.wholesalerList);
  const outletList = useSelector(({outlet}) => outlet.outletList);
  const productList = useSelector(({product}) => product.productList);
  const isLoadingMaster = useSelector(({master, wholesaler, outlet, product}) => master.isLoading || wholesaler.isLoading || outlet.isLoading || product.isLoading);

  // Prepare data
  const filteredData = useMemo(() => {
    const reformatted = data.map(sale => isAdmin
      ? {...sale, invoice_date: moment(sale.invoice_date).format("YYYY-MM-DD"), outlet: outletList.find(o => o.id === _.get(sale, "outlet.id")), sales_summary: {...sale.sales_summary, wholesaler: wholesalerList.find(ws => ws.id === _.get(sale, 'sales_summary.wholesaler_id'))}}
      : sale
    );

    const filtered = reformatted.filter(sale => 
      (filterRegions.length === 0 || _.find(filterRegions, r => r.id === _.get(sale, 'sales_summary.wholesaler.region.id'))) &&
      (filterStates.length === 0 || _.find(filterStates, s => s.id === _.get(sale, 'sales_summary.wholesaler.state.id'))) &&
      (filterWholesalers.length === 0 || _.find(filterWholesalers, ws => ws.id === _.get(sale, 'sales_summary.wholesaler.id'))) &&
      (!filterInvoiceNo || _.includes(_.get(sale, 'invoice_no', '').toLowerCase(), filterInvoiceNo.toLowerCase())) &&
      (filterOutlets.length === 0 || _.find(filterOutlets, o => o.id === _.get(sale, 'outlet.id'))) &&
      (!filterBrands || _.includes(_.get(sale, 'product.brand', '').toLowerCase(), filterBrands.toLowerCase())) &&
      (!filterSubBrands || _.includes(_.get(sale, 'product.sub_brand', '').toLowerCase(), filterSubBrands.toLowerCase())) &&
      (filterProducts.length === 0 || _.find(filterProducts, p => p.id === _.get(sale, 'product.id')))
    );

    return filtered;
  }, [isAdmin, outletList, wholesalerList, data, filterRegions, filterStates, filterWholesalers, filterInvoiceNo, filterOutlets, filterBrands, filterSubBrands, filterProducts]);

  const dataTableRef = useRef(null);

  if(!authToken || !isAdmin)
    return null;

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li><Link to="/reports">Reports</Link></li>
          <li className="is-active"><a href="# " aria-current="page">Salesforce Report</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              Salesforce Report
            </h1>
          </div>
        </div>
      </section>
      <Form
        onSubmit={ values => {
          const {start_date, end_date, region, state, wholesaler, invoice_no, outlet, brand, sub_brand, product} = values;
          if(start_date !== filterStartDate) {
            setFilterStartDate(start_date);
          }
          if(end_date !== filterEndDate) {
            setFilterEndDate(end_date);
          }
          if(!_.isEqual(region, filterRegions)) {
            setFilterRegions(region || []);
          }
          if(!_.isEqual(state, filterStates)) {
            setFilterStates(state || []);
          }
          if(!_.isEqual(wholesaler, filterWholesalers)) {
            setFilterWholesalers(wholesaler || []);
          }
          if(invoice_no !== filterInvoiceNo) {
            setFilterInvoiceNo(invoice_no);
          }
          if(!_.isEqual(outlet, filterOutlets)) {
            setFilterOutlets(outlet || []);
          }
          if(brand !== filterBrands) {
            setFilterBrands(brand);
          }
          if(sub_brand !== filterSubBrands) {
            setFilterSubBrands(sub_brand);
          }
          if(!_.isEqual(product, filterProducts)) {
            setFilterProducts(product || []);
          }
        }}
        initialValues={{
          start_date: monthStart,
          end_date: monthEnd,
          region: [],
          state: [],
          wholesaler: [],
          invoice_no: '',
          outlet: [],
          brand: '',
          sub_brand: '',
          product: []
        }}
        keepDirtyOnReinitialize
        decorators={[calculator]}
        validate={validateSalesforceReport}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <FormErrors formRules={Object.values(formRules)}/>
            <Field name="start_date">
              {(props) => (
                <DatePicker
                  label="From Date"
                  dateFormat="yyyy-MM-dd"
                  selected={moment(props.input.value).toDate()}
                  {...props}
                />
              )}
            </Field>
            <Field name="end_date">
              {(props) => (
                <DatePicker
                  label="To Date"
                  dateFormat="yyyy-MM-dd"
                  selected={moment(props.input.value).toDate()}
                  {...props}
                />
              )}
            </Field>
            { isAdmin
              ? <>
                  <Field name="region">
                    {(props) => (
                      <Select
                        label="Wholesaler Region"
                        options={regionList}
                        getOptionLabel={o => o.region}
                        getOptionValue={o => o.id}
                        placeholder="All"
                        isLoading={isLoadingMaster || isLoading}
                        isMulti
                        isClearable
                        isSearchable
                        {...props}
                      />
                    )}
                  </Field>
                  <Field name="state">
                    {(props) => (
                      <Select
                        label="Wholesaler State"
                        options={stateList}
                        getOptionLabel={o => o.state}
                        getOptionValue={o => o.id}
                        placeholder="All"
                        isLoading={isLoadingMaster || isLoading}
                        isMulti
                        isClearable
                        isSearchable
                        {...props}
                      />
                    )}
                  </Field>
                  <Field name="wholesaler">
                    {(props) => (
                      <Select
                        label="Wholesaler"
                        options={wholesalerList.filter(ws => 
                          (!values.region || values.region.length === 0 || _.find(values.region, r => r.id === _.get(ws, 'region.id'))) &&
                          (!values.state || values.state.length === 0 || _.find(values.state, s => s.id === _.get(ws, 'state.id')))
                        )}
                        getOptionLabel={o => o.name}
                        getOptionValue={o => o.id}
                        placeholder="All"
                        isLoading={isLoadingMaster || isLoading}
                        isMulti
                        isClearable
                        isSearchable
                        {...props}
                      />
                    )}
                  </Field>
                </>
              : null
            }
            <Field name="invoice_no" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Invoice No."
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="outlet">
              {(props) => (
                <Select
                  label="Outlet"
                  options={outletList}
                  getOptionLabel={o => `${o.name}${o.branch && ` @ ${o.branch}`}`}
                  getOptionValue={o => o.id}
                  placeholder="All"
                  isLoading={isLoadingMaster || isLoading}
                  isMulti
                  isClearable
                  isSearchable
                  {...props}
                />
              )}
            </Field>
            <Field name="brand" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Product Brand"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="sub_brand" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Product Sub-Brand"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="product">
              {(props) => (
                <Select
                  label="Product"
                  options={productList}
                  getOptionLabel={o => o.name}
                  getOptionValue={o => o.id}
                  placeholder="All"
                  isLoading={isLoadingMaster || isLoading}
                  isMulti
                  isClearable
                  isSearchable
                  {...props}
                />
              )}
            </Field>
            <div className="column is-full has-text-centered" style={{marginBottom: '30px'}}>
              <SubmitButton
                disabled={submitting || validating}
                loading={submitting || validating || isLoading}
              >Filter</SubmitButton>
            </div>
            <div className="column is-full has-text-right" style={{marginBottom: '20px'}}>
              <CSVFromTable
                tableRef={dataTableRef}
                columns={adminColumns}
                fileName="SalesforceReport"
                loading={submitting || validating || isLoading}
              >
                <span className="icon">
                  <i className="fas fa-file-excel"></i>
                </span>
                <span>Export to Excel</span>
              </CSVFromTable>
            </div>
          </form>
        )}
      />
      <div>
        <ReactTableFilterable
          data={filteredData}
          columns={adminColumns}
          defaultPageSize={10}
          className="-striped -highlight"
          ref={dataTableRef}
        />
      </div>
    </div>
  );
};

export default SalesforceReport;