import _ from 'lodash';
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import moment from 'moment';

import { FormErrors, Select, TextInput, DatePicker, LabelField, Button, salesItemFormRules } from '../../components/Forms';
import { fetchSalesSummaryDetails, fetchOutletListLean, fetchProductList, createSales, updateSales, deleteSales } from '../../actions';

let formRules = _.cloneDeep(salesItemFormRules);

const SalesItemForm = (props) => {
  const saleToUpdate = _.get(props, 'location.state.saleToUpdate');
  const nextSequence = _.get(props, 'location.state.nextSequence');
  const salesSummaryId = _.get(props, 'location.state.salesSummaryId');
  const history = props.history;

  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const isLoading = useSelector(({sales}) => sales.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if(authToken) {
      dispatch(fetchSalesSummaryDetails(authToken, salesSummaryId, false));
      dispatch(fetchOutletListLean(authToken));
      dispatch(fetchProductList(authToken));
    }
  }, [authToken, salesSummaryId, dispatch]);
  const sales = useSelector(({sales}) => sales.salesSummaryDetails.sales || []);
  const outletList = useSelector(({outlet}) => outlet.outletList.map(outlet => ({...outlet, isDisabled: !outlet.active})));
  const productList = useSelector(({product}) => product.productList.map(product => ({...product, isDisabled: !product.active})));
  const isLoadingMaster = useSelector(({outlet, product}) => outlet.isLoading || product.isLoading);

  // Form Validation
  const verifyUniqueFields = (values) => {
    const {invoice_no, product} = values;
    let errors = {};
    const salesExcludeCurrentSale = saleToUpdate ? sales.filter(s => s.id !== saleToUpdate.id) : sales;

    formRules.invoice_product_taken.pristine = false;
    if(salesExcludeCurrentSale.some(s => _.get(s, 'invoice_no', '').trim() === invoice_no.trim() && _.get(s, 'product.id', 0) === product.id)) {
      formRules.invoice_product_taken.valid = false;
      errors.invoice_no = true;
      errors.product = true;
    } else {
      formRules.invoice_product_taken.valid = true;
    }

    return errors;
  }
  const mVerifyUniqueFields = useCallback(verifyUniqueFields, [sales]);

  const validateSalesItemForm = (values) => {
    const {invoice_date, invoice_no, outlet, product, actual_units, standard_to_units, additional_to_units} = values;
    let errors = {};

    if(!invoice_date) {
      formRules.invoice_date_required.valid = false;
      errors.invoice_date = true;
    } else {
      formRules.invoice_date_required.valid = true;
    }

    if(!invoice_no) {
      formRules.invoice_no_requried.valid = false;
      errors.invoice_no = true;
    } else {
      formRules.invoice_no_requried.valid = true;
    }

    if(!outlet) {
      formRules.outlet_required.valid = false;
      errors.outlet = true;
    } else {
      formRules.outlet_required.valid = true;
    }

    if(!product) {
      formRules.product_required.valid = false;
      errors.product = true;
    } else {
      formRules.product_required.valid = true;
    }

    if(isNaN(actual_units||'')) {
      formRules.actual_units_format.valid = false;
      errors.actual_units = true;
    } else {
      formRules.actual_units_format.valid = true;
    }

    if(isNaN(standard_to_units||'')) {
      formRules.standard_to_format.valid = false;
      errors.standard_to_units = true;
    } else {
      formRules.standard_to_format.valid = true;
    }

    if(isNaN(additional_to_units||'')) {
      formRules.additional_to_format.valid = false;
      errors.additional_to_units = true;
    } else {
      formRules.additional_to_format.valid = true;
    }

    if(!actual_units && !standard_to_units && !additional_to_units) {
      formRules.units_required.valid = false;
      errors.actual_units = true;
      errors.standard_to_units = true;
      errors.additional_to_units = true;
    } else {
      formRules.units_required.valid = true;
    }

    return Object.keys(errors).length ? errors : mVerifyUniqueFields(values);
  }
  const mValidateSalesItemForm = useCallback(validateSalesItemForm, [mVerifyUniqueFields]);

  // Reset formRules
  useEffect(() => {
    formRules = _.cloneDeep(salesItemFormRules);
  }, []);

  // Validate initial values once on CDM - future refactor and remove eslint-disable
  useEffect(() => {
    saleToUpdate && mValidateSalesItemForm(saleToUpdate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  if(!authToken || !isAdmin) {
    return null;
  }
  const pathname = _.get(props, 'location.pathname');
  if(!(saleToUpdate && salesSummaryId) && pathname === '/edit_sale') {
    return null;
  }
  if(!(nextSequence && salesSummaryId) && pathname === '/add_sale') {
    return null;
  }

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li><Link to="/sales_summary">Sales Summary</Link></li>
          <li><Link to={{
              pathname: '/edit_sales_summary',
              state: {
                salesSummaryId,
                salesIsSubmitted: true
              }
            }}
          >Sales Summary Details</Link></li>
          <li className="is-active"><a href="# " aria-current="page">{saleToUpdate ? 'Edit ' : 'New '}Sales Item</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              {saleToUpdate ? 'Edit ' : 'New '}Sales Item
            </h1>
          </div>
        </div>
      </section>
      <Form
        onSubmit={ values => {
          const reformatted = {
            ...values, 
            sales_summary: {id: salesSummaryId},
            invoice_date: moment(values.invoice_date).format('YYYY-MM-DD[T00:00:00Z]'),
            actual_units: parseInt(values.actual_units || 0),
            standard_to_units: parseInt(values.standard_to_units || 0),
            additional_to_units: parseInt(values.additional_to_units || 0)
          };
          if(saleToUpdate) {
            dispatch(updateSales(authToken, isAdmin, reformatted, () => history.push({
              pathname: '/edit_sales_summary',
              state: {
                salesSummaryId,
                salesIsSubmitted: true
              }
            })));
          } else {
            dispatch(createSales(authToken, isAdmin, reformatted, () => history.push({
              pathname: '/edit_sales_summary',
              state: {
                salesSummaryId,
                salesIsSubmitted: true
              }
            })));
          }
        }}
        initialValues={saleToUpdate ? {...saleToUpdate, invoice_date: moment(saleToUpdate.invoice_date)} : {sales_summary: {id: salesSummaryId}, sequence: nextSequence, invoice_date: moment()}}
        validate={mValidateSalesItemForm}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <FormErrors formRules={Object.values(formRules)}/>
            <Field name="invoice_date">
              {(props) => (
                <DatePicker
                  label="Invoice Date"
                  dateFormat="yyyy-MM-dd"
                  selected={moment(props.input.value).toDate()}
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="invoice_no" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Invoice No."
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="outlet">
              {(props) => {
                const selectedOutlet = outletList.find(outlet => outlet.id === props.input.value.id);
                return(
                  <>
                    <Select
                      label="Outlet @ Branch"
                      options={outletList}
                      value={selectedOutlet}
                      getOptionLabel={o => `${o.name}${o.branch && ` @ ${o.branch}`}`}
                      getOptionValue={o => o.id}
                      placeholder="Select Outlet..."
                      isLoading={isLoadingMaster || isLoading}
                      isClearable
                      isSearchable
                      noOptionsMessage={() => <Link
                        to={{
                          pathname: '/add_outlet',
                          state: {
                            breadcrumbs: [
                              {name: "Dashboard", link: "/"},
                              {name: "Sales Summary", link: "/sales_summary"},
                              {name: "Sales Summary Details", link: "/edit_sales_summary", linkState: {salesSummaryId, salesIsSubmitted: true}},
                              {name: `${saleToUpdate ? 'Edit ' : 'New '}Sales Item`, link: pathname, linkState: {saleToUpdate, nextSequence, salesSummaryId}}
                            ],
                          }
                        }}
                      >Create new Outlet</Link>}
                      {...props}
                    />
                    <LabelField label="Legal Name">{_.get(selectedOutlet, 'legal_name')||''}</LabelField>
                  </>
                );
              }}
            </Field>
            <Field name="product">
              {(props) => {
                const selectedProduct = productList.find(product => product.id === props.input.value.id);
                return(
                  <>
                    <Select
                      label="Product Name"
                      options={productList}
                      value={selectedProduct}
                      getOptionLabel={p => p.name}
                      getOptionValue={p => p.id}
                      placeholder="Select Product..."
                      isLoading={isLoadingMaster || isLoading}
                      isClearable
                      isSearchable
                      {...props}
                    />
                    {/* <LabelField label="Brand">{_.get(selectedProduct, 'brand')||''}</LabelField>
                    <LabelField label="Sub-Brand">{_.get(selectedProduct, 'sub_brand')||''}</LabelField> */}
                    <LabelField label="SKU No.">{_.get(selectedProduct, 'sku_no')||''}</LabelField>
                  </>
                );
              }}
            </Field>
            <Field name="actual_units" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Actual Units Sold (Bottles)"
                  disabled={isLoading}
                  onBlur = {e => {
                    e.target.value = e.target.value.trim();
                    if(e.target.value === '')
                      e.target.value = '0';
                  }}
                  onFocus = {e => {
                    e.target.value = e.target.value.trim();
                    if(e.target.value === '0')
                      e.target.value = '';
                  }}
                  {...props}
                />
              )}
            </Field>
            <Field name="standard_to_units" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Standard TO (Bottles)"
                  disabled={isLoading}
                  onBlur = {e => {
                    e.target.value = e.target.value.trim();
                    if(e.target.value === '')
                      e.target.value = '0';
                  }}
                  onFocus = {e => {
                    e.target.value = e.target.value.trim();
                    if(e.target.value === '0')
                      e.target.value = '';
                  }}
                  {...props}
                />
              )}
            </Field>
            <Field name="additional_to_units" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Additional TO (Bottles)"
                  disabled={isLoading}
                  onBlur = {e => {
                    e.target.value = e.target.value.trim();
                    if(e.target.value === '')
                      e.target.value = '0';
                  }}
                  onFocus = {e => {
                    e.target.value = e.target.value.trim();
                    if(e.target.value === '0')
                      e.target.value = '';
                  }}
                  {...props}
                />
              )}
            </Field>
            <div className="column is-full buttons" style={{marginTop: '50px', justifyContent: 'center'}}>
              <Button
                type="submit"
                className="is-primary"
                disabled={pristine || submitting || validating}
                loading={submitting || validating}
              >{saleToUpdate ? 'Update' : 'Add'}</Button>
              { saleToUpdate &&
                <Button
                  type="button"
                  className="is-danger"
                  onClick={() => {
                    if(window.confirm('Confirm to delete Sales Item?')) {
                      dispatch(deleteSales(authToken, isAdmin, saleToUpdate.id, () => history.push({
                        pathname: '/edit_sales_summary',
                        state: {
                          salesSummaryId,
                          salesIsSubmitted: true
                        }
                      })));
                    }
                  }}
                  disabled={submitting || validating}
                  loading={submitting || validating}
                >Delete</Button>
              }
            </div>
          </form>
        )}
      />
    </div>
  );
};

SalesItemForm.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(SalesItemForm);