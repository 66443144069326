import client from './client';
import { createMessage } from '.';
import { compareNumbers } from './sortHelper';

export const LOADING_MASTER = 'loading_master';
export const FETCH_REGION_LIST = 'fetch_region_list';
export const FETCH_STATE_LIST = 'fetch_state_list';
export const FETCH_MARKET_LIST = 'fetch_market_list';
export const FETCH_CONTRACT_LIST = 'fetch_contract_list';
export const FETCH_OUTLET_TYPE_LIST = 'fetch_outlet_type_list';
export const COMPLETE_MASTER = 'complete_master';

export const fetchRegionList = (authToken) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_MASTER
    });

    client.getRegion(authToken)
    .then(({data}) => {
      dispatch({
        type: FETCH_REGION_LIST,
        regionList: data.sort(compareNumbers('id'))
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_MASTER
      });
    });
  };
};

export const fetchStateList = (authToken) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_MASTER
    });

    client.getState(authToken)
    .then(({data}) => {
      dispatch({
        type: FETCH_STATE_LIST,
        stateList: data.sort(compareNumbers('id'))
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_MASTER
      });
    });
  };
};

export const fetchMarketList = (authToken) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_MASTER
    });

    client.getMarket(authToken)
    .then(({data}) => {
      dispatch({
        type: FETCH_MARKET_LIST,
        marketList: data.sort(compareNumbers('id'))
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_MASTER
      });
    });
  };
};

export const fetchContractList = (authToken) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_MASTER
    });

    client.getContract(authToken)
    .then(({data}) => {
      dispatch({
        type: FETCH_CONTRACT_LIST,
        contractList: data.sort(compareNumbers('id'))
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_MASTER
      });
    });
  };
};

export const fetchOutletTypeList = (authToken) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_MASTER
    });

    client.getOutletType(authToken)
    .then(({data}) => {
      dispatch({
        type: FETCH_OUTLET_TYPE_LIST,
        outletTypeList: data.sort(compareNumbers('id'))
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_MASTER
      });
    });
  };
};