import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, createTransform } from 'redux-persist';
import stringify from 'json-stringify-safe';
import CryptoJSCore from 'crypto-js/core';
import AES from 'crypto-js/aes';

import message from './messageReducer';
import auth, {INITIAL_STATE as authInitialState} from './authReducer';
import user from './userReducer';
import master from './masterReducer';
import product from './productReducer';
import wholesaler from './wholesalerReducer';
import outlet from './outletReducer';
import sales from './salesReducer';
import stock from './stockReducer';

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth'],
  transforms: [createTransform(
    (inboundState, key) => {
      const state = inboundState || authInitialState;
      const {currentUser, isLoading} = authInitialState;
      const stringifyState = stringify({...state, currentUser, isLoading});
      const encryptedState = AES.encrypt(stringifyState, encryptionKey).toString();
      return encryptedState;
    },
    (outboundState, key) => {
      if (typeof outboundState !== 'string') {
        console.error("Invalid state, expected outbound state to be a string");
      } else {
        try {
          const bytes = AES.decrypt(outboundState, encryptionKey);
          const decryptedString = bytes.toString(CryptoJSCore.enc.Utf8);
          const decryptedState = JSON.parse(decryptedString);
          return decryptedState || authInitialState;
        } catch (err) {
          console.error("Could not decrypt state, invalid encryption key or state string.");
        }
      };
      return authInitialState;
    },
    { whitelist: ['auth'] }
  )]
};

const rootReducer = combineReducers({
  message,
  auth,
  user,
  master,
  product,
  wholesaler,
  outlet,
  sales,
  stock
});

export default persistReducer(rootPersistConfig, rootReducer);
