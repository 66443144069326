import client from './client';
import { createMessage } from '.';
import { compareNumbers, compareDates } from './sortHelper';
import EncryptStorage from '../components/EncryptStrorage';

export const LOADING_STOCK = 'loading_stock';
export const FETCH_ALL_STOCK_BALANCE = 'fetch_all_stock_balance';
export const FETCH_STOCK_BALANCE_LIST = 'fetch_stock_balance_list';
export const FETCH_STOCK_BALANCE_DETAILS = 'fetch_stock_balance_details';
export const CLEAR_STOCK_BALANCE_DETAILS = 'clear_stock_balance_details';
export const FETCH_STOCK_FOR_CLONING = 'fetch_stock_for_cloning';
export const FETCH_STOCK_BALANCE_REPORT = 'fetch_stock_balance_report';
export const COMPLETE_STOCK = 'complete_stock';

export const fetchAllStockBalance = (authToken) => {
  return(dispatch) => {
    dispatch({
      type: LOADING_STOCK
    });
  
    client.getStockBalance(authToken)
    .then(({data}) => {
      dispatch({
        type: FETCH_ALL_STOCK_BALANCE,
        allStockBalance: data.sort(compareDates('date'))
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_STOCK
      });
    });
  };
};

export const fetchStockBalanceList = (authToken, isAdmin, year, wholesalerId) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_STOCK
    });
  
    if(isAdmin) {
      client.getStockBalanceByYear(authToken, year)
      .then(({data}) => {
        dispatch({
          type: FETCH_STOCK_BALANCE_LIST,
          stockBalanceList: data.sort(compareDates('date'))
        });
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_STOCK
        });
      });
    } else {
      client.getStockBalanceByWholesalerYear(authToken, year, wholesalerId)
      .then(({data}) => {
        dispatch({
          type: FETCH_STOCK_BALANCE_LIST,
          stockBalanceList: data.sort(compareDates('date'))
        });
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_STOCK
        });
      });
    }
  };
};

export const fetchStockBalanceDetails = (authToken, stockBalanceId, draft) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_STOCK
    });

    client.getStockBalanceById(authToken, stockBalanceId, draft)
    .then(({data}) => {
      dispatch({
        type: FETCH_STOCK_BALANCE_DETAILS,
        stockBalanceDetails: {...data, stocks: data.stocks.sort(compareNumbers('sequence'))}
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_STOCK
      });
    });
  };
};

export const clearStockBalanceDetails = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_STOCK_BALANCE_DETAILS
    });
  };
};

export const fetchStockForCloning = (authToken, stockBalanceId, draft) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_STOCK
    });

    client.getStockBalanceById(authToken, stockBalanceId, draft)
    .then(({data}) => {
      dispatch({
        type: FETCH_STOCK_FOR_CLONING,
        stockForCloning: {...data, stocks: data.stocks.sort(compareNumbers('sequence'))}
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_STOCK
      });
    });
  };
};

export const createStockBalance = (authToken, submitted, values, redirectOnSuccess) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_STOCK
    });

    const {wholesaler, date, stocks} = values;
    client.postStockBalance(authToken, wholesaler.id, date, submitted)
    .then(({data}) => {
      const {id} = data;
      const stocksArray = stocks.map((stock, index) => ({
        stock_balance: {id: parseInt(id)},
        sequence: index+1,
        product: {id: stock.product.id},
        balance_units: stock.balance_units
      }));
      return client.postStock(authToken, !submitted, stocksArray);
    })
    .then(() => {
      dispatch(createMessage(`Stock Balance ${submitted ? 'submitted' : 'saved'} successfully.`, 'success'));
      dispatch({
        type: COMPLETE_STOCK
      });
      EncryptStorage.removeStocksItem(0);
      redirectOnSuccess();
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_STOCK
      });
    });
  };
};

export const submitDraftStock = (authToken, stockBalanceId, values, redirectOnSuccess) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_STOCK
    });

    const {wholesaler, date, stocks} = values;
    client.putStockBalance(authToken, stockBalanceId, wholesaler.id, date, true)
    .then(() => {
      const stockArray = stocks.map((stock, index) => ({
        stock_balance: {id: stockBalanceId},
        sequence: index+1,
        product: {id: stock.product.id},
        balance_units: stock.balance_units
      }));
      return client.postStock(authToken, false, stockArray);
    })
    .then(() => {
      dispatch(createMessage('Stock Balance submitted successfully.', 'success'));
      dispatch({
        type: COMPLETE_STOCK
      });
      EncryptStorage.removeStocksItem(stockBalanceId);
      redirectOnSuccess();
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_STOCK
      });
    });
  };
};

export const updateDraftStock = (authToken, stockBalanceId, stocks, redirectOnSuccess) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_STOCK
    });

    client.deleteStockBalance(authToken, stockBalanceId, true, true)
    .then(() => {
      const stockArray = stocks.map((stock, index) => ({
        stock_balance: {id: stockBalanceId},
        sequence: index+1,
        product: {id: stock.product.id},
        balance_units: stock.balance_units
      }));
      return client.postStock(authToken, true, stockArray)
    })
    .then(() => {
      dispatch(createMessage('Stock Balance saved successfully.', 'success'));
      dispatch({
        type: COMPLETE_STOCK
      });
      EncryptStorage.removeStocksItem(stockBalanceId);
      redirectOnSuccess();
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_STOCK
      });
    });
  };
};

export const revertSubmittedStock = (authToken, stockBalanceId, values, redirectOnSuccess) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_STOCK
    });

    const {wholesaler, date, stocks} = values;
    client.deleteStockBalance(authToken, stockBalanceId, false, true)
    .then(() => {
      return client.deleteStockBalance(authToken, stockBalanceId, true, true);
    })
    .then(() => {
      return client.putStockBalance(authToken, stockBalanceId, wholesaler.id, date, false);
    })
    .then(() => {
      const stockArray = stocks.map((stock, index) => ({
        stock_balance: {id: stockBalanceId},
        sequence: index+1,
        product: {id: stock.product.id},
        balance_units: stock.balance_units
      }));
      return client.postStock(authToken, true, stockArray);
    })
    .then(() => {
      dispatch(createMessage('Stock Balance reverted to Draft.', 'success'));
      dispatch({
        type: COMPLETE_STOCK
      });
      redirectOnSuccess();
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_STOCK
      });
    });
  };
};

export const autoSubmitStockBalances = (authToken, stockBalances) => {
  return (dispatch) => {
    const promises = stockBalances.map(sb => (
      client.getStockBalanceById(authToken, sb.id, !sb.submitted)
      .then(({data}) => {
        const {stocks} = data;
        return (
          client.putStockBalance(authToken, sb.id, sb.wholesaler_id, sb.date, true)
          .then(() => {
            const stockArray = stocks.map(stock => ({
              stock_balance: {id: sb.id},
              sequence: stock.sequence,
              product: {id: stock.product.id},
              balance_units: stock.balance_units
            }));
            EncryptStorage.removeStocksItem(sb.id);
            return client.postStock(authToken, false, stockArray);
          })
        );
      })
    ));

    Promise.all(promises)
    .then(() => {
      dispatch({
        type: COMPLETE_STOCK
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_STOCK
      });
    });
  };
};

export const createStock = (authToken, isAdmin, values, redirectOnSuccess) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_STOCK
      });

      const {stock_balance, sequence, product, balance_units} = values;
      client.postStock(authToken, false, [{stock_balance, sequence, product, balance_units}])
      .then(({data}) => {
        dispatch(createMessage('Stock Item created successfully.', 'success'));
        dispatch({
          type: COMPLETE_STOCK
        });
        redirectOnSuccess();
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_STOCK
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
};

export const updateStock = (authToken, isAdmin, values, redirectOnSuccess) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_STOCK
      });

      const {id, stock_balance, sequence, product, balance_units} = values;
      client.putStock(authToken, id, stock_balance, sequence, product, balance_units)
      .then(({data}) => {
        dispatch(createMessage('Stock Balance updated successfully.', 'success'));
        dispatch({
          type: COMPLETE_STOCK
        });
        redirectOnSuccess();
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_STOCK
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
};

export const deleteStock = (authToken, isAdmin, stockId, redirectOnSuccess) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_STOCK
      });

      client.deleteStock(authToken, stockId)
      .then(({data}) => {
        dispatch(createMessage('Stock Item deleted successfully.', 'success'));
        dispatch({
          type: COMPLETE_STOCK
        });
        redirectOnSuccess();
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_STOCK
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
};

export const fetchStockBalanceReport = (authToken, isAdmin, month, wholesalerId) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_STOCK
    });
  
    if(isAdmin) {
      client.getStockBalanceReport(authToken, month)
      .then(({data}) => {
        dispatch({
          type: FETCH_STOCK_BALANCE_REPORT,
          stockBalanceReport: data.sort(compareNumbers('sequence'))
        });
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_STOCK
        });
      });
    } else {
      client.getStockBalanceReportByWholesaler(authToken, month, wholesalerId)
      .then(({data}) => {
        dispatch({
          type: FETCH_STOCK_BALANCE_REPORT,
          stockBalanceReport: data.sort(compareNumbers('sequence'))
        });
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_STOCK
        });
      });
    }
  };
};
