import React, { useState, useEffect } from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import logoSmall from '../assets/images/logo-small.png';
import { logoutUser, getCurrentUser } from '../actions';
import apiClient from '../actions/client';

const NavBar = (props) => {
  const [active, setActive] = useState(false);

  const {history, location} = props;

  const authToken = useSelector(({auth}) => auth.authToken);
  const currentUser = useSelector(({auth}) => auth.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    apiClient.setCurrentUser(currentUser);
  }, [currentUser]);

  useEffect(() => {
    if(authToken && ! currentUser) {
      dispatch(getCurrentUser(authToken));
    }
  }, [authToken, currentUser, dispatch]);

  const noAuthPathes = ['/login', '/admin_login', '/forgot_password', '/reset_password'];
  if(!authToken && ['/ping', ...noAuthPathes].indexOf(location.pathname) < 0) {
    return <Redirect to='/login'/>;
  }
  if(authToken && noAuthPathes.indexOf(location.pathname) >= 0) {
    return <Redirect to='/'/>;
  }

  return (
    <nav className="navbar is-spaced has-shadow" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <span
          className="navbar-item is-clickable"
          onClick={() => history.push('/')}
        >
          <img src={logoSmall} alt=""/>
        </span>
        <span
          className="navbar-item is-clickable"
          onClick={() => history.push('/')}
        >
          <b>PRM - Wholesaler Portal</b>
        </span>

        <div 
          role="button" className={`navbar-burger ${active && 'is-active'}`} aria-label="menu" aria-expanded="false" data-target="navbar"
          onClick={()=>setActive(!active)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>

      <div id="navbar" className={`navbar-menu ${active && 'is-active'}`}>
        <div className="navbar-start"/>
        <div className="navbar-end">
          { authToken
            ? <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link" href="# ">
                  Hello, {currentUser && currentUser.username}
                </a>
                <div className="navbar-dropdown is-right">
                  <Link className="navbar-item" to="/change_password">Change Password</Link>
                  <a
                    className="navbar-item" href="# " 
                    onClick={() => {
                      if(window.confirm("Confirm to Logout?"))
                        dispatch(logoutUser(authToken));
                    }}
                  >Logout</a>
                </div>
              </div>
            : <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link" href="# ">Login</a>
                <div className="navbar-dropdown is-right">
                  <Link className="navbar-item" to="/login">Wholesalers Login</Link>
                  <Link className="navbar-item" to="/admin_login">Admin Login</Link>
                </div>
              </div>
          }
        </div>
      </div>
    </nav>
  );
};

export default withRouter(NavBar);
