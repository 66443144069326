import React from 'react';
import PropTypes from 'prop-types';

const Button = ({className, loading, onClick, children, ...rest}) => (
  <button
    className={`button is-outlined ${className} ${loading ? 'is-loading' : ''}`}
    onClick={() => {
      const el = document.querySelector('.error')
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
      document.activeElement.blur();
      onClick && onClick();
    }}
    onMouseDown={e => e.preventDefault()}
    {...rest}
  >{children}</button>
);

Button.propTypes = {
  type: PropTypes.oneOf(['submit', 'button']).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  onClick: PropTypes.func
};

export default Button;