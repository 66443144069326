import React from 'react';
import PropTypes from 'prop-types';

const LabelField = ({label, children}) => (
  <div className="field is-horizontal">
    <div className="field-label">
      <label className="label">{label}</label>
    </div>
    <div className="field-body">
      <label style={{width: '100%'}}>{children}</label>
    </div>
  </div>
);

LabelField.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default LabelField;