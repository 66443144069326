import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Report = (props) => {
  const isAdmin = useSelector(({auth}) => auth.isAdmin);

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li className="is-active"><a href="# " aria-current="page">Reports</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              Reports
            </h1>
          </div>
        </div>
      </section>
      <aside className="menu">
        <p className="menu-label">
          General
        </p>
        <ul className="menu-list">
          <li><Link to="/reports/sales_report">Sales Report</Link></li>
          { isAdmin &&
            <li><Link to="/reports/wholesaler_sales_summary_report">Wholesaler Sales Summary Report</Link></li>
          }
          <li><Link to="/reports/outlet_sales_summary_report">Outlet Sales Summary Report</Link></li>
          <li><Link to="/reports/stock_balance_report">Stock Balance Report</Link></li>
          { isAdmin &&
            <li><Link to="/reports/stock_balance_summary_report">Stock Balance Summary Report</Link></li>
          }
          { isAdmin &&
            <li><Link to="/reports/new_outlets_listing">New Outlets Listing</Link></li>
          }
          { isAdmin &&
            <li><Link to="/reports/salesforce_report">Salesforce Report</Link></li>
          }
        </ul>
      </aside>
    </div>
  );
};

export default Report;