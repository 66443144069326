import React from 'react';
import ReactTable from 'react-table';

const filterMethod = ({ id, value }, row) => {
  if(id === 'edit')
    return true;
  if(row[id] == null)
    return value === ' ';

  let rowValue = row[id]+'';
  if(id === 'status')
    rowValue = row[id] ? 'active' : 'deactivated';
  if(id === 'outlet+branch')
    rowValue = `${row[id].name}${row[id].branch && ` @ ${row[id].branch}`}`
  if(id.includes('_submission'))
    rowValue = row[id].submitted ? 'submitted' : 'draft';
  
  return rowValue.toLowerCase().includes(value.toLowerCase());
}

const ReactTableFilterable = React.forwardRef((props, ref) => (
  <ReactTable
    filterable
    defaultFilterMethod = {filterMethod}
    ref = {ref}
    {...props}
  />
));

export default ReactTableFilterable;
