import _ from 'lodash';
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import { FormErrors, Select, TextInput, CheckBox, SubmitButton, outletFormRules } from '../../components/Forms';
import { fetchOutletList, fetchOutlet, createOutlet, updateOutlet, clearOutlet, fetchContractList, fetchOutletTypeList, fetchStateList } from '../../actions';

let formRules = _.cloneDeep(outletFormRules);

const OutletForm = (props) => {
  // outletToUpdatePros => props passed in from outlet list page
  const outletToUpdateProps = _.get(props, 'location.state.outletToUpdate');
  const breadcrumbs = _.get(props, 'location.state.breadcrumbs');
  const history = props.history;

  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const currentUser = useSelector(({auth}) => auth.currentUser);
  const isLoading = useSelector(({outlet}) => outlet.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if(authToken) {
      // clear existing outlet in session
      dispatch(clearOutlet());
      if(outletToUpdateProps && outletToUpdateProps.id){
        // fetch full outlet detail for "Edit Outlet"
        dispatch(fetchOutlet(authToken, outletToUpdateProps.id));
      }
      dispatch(fetchOutletList(authToken));
      dispatch(fetchContractList(authToken));
      dispatch(fetchOutletTypeList(authToken));
      dispatch(fetchStateList(authToken));
    }
  }, [authToken, dispatch, outletToUpdateProps]);
  // get outlet details after dispatching fetchOutlet action
  const outletToUpdate = useSelector(({outlet}) => outlet.outletToUpdate);
  const outletList = useSelector(({outlet}) => outlet.outletList);
  const contractList = useSelector(({master}) => master.contractList);
  const outletTypeList = useSelector(({master}) => master.outletTypeList);
  const stateList = useSelector(({master}) => master.stateList);
  const isLoadingMaster = useSelector(({master}) => master.isloading);

  // Form Validation
  const verifyUniqueFields = (values) => {
    const {name, branch} = values;
    let errors = {};
    const outletListExcludeCurrentOutlet = outletToUpdate ? outletList.filter(o => o.id !== outletToUpdate.id) : outletList;

    formRules.name_branch_taken.pristine = false;
    if(outletListExcludeCurrentOutlet.some(o => `${_.get(o, 'name', '').trim()} - ${_.get(o, 'branch', '').trim()}`.toLowerCase().trim() === `${(name||'').trim()} - ${(branch||'').trim()}`.toLowerCase().trim())) {
      formRules.name_branch_taken.valid = false;
      errors.name = true;
      errors.branch = true;
    } else {
      formRules.name_branch_taken.valid = true;
    }

    return errors;
  }
  const mVerifyUniqueFields = useCallback(verifyUniqueFields, [outletList]);

  const validateOutletForm = (values) => {
    const {name, contract, outlet_type, legal_name, state} = values;
    let errors = {};

    if(!name) {
      formRules.name_required.valid = false;
      errors.name = true;
    } else {
      formRules.name_required.valid = true;
    }

    if(!contract) {
      formRules.contract_required.valid = false;
      errors.contract = true;
    } else {
      formRules.contract_required.valid = true;
    }

    if(!outlet_type) {
      formRules.outlet_type_required.valid = false;
      errors.outlet_type = true;
    } else {
      formRules.outlet_type_required.valid = true;
    }

    if(!legal_name) {
      formRules.legal_name_required.valid = false;
      errors.legal_name = true;
    } else {
      formRules.legal_name_required.valid = true;
    }

    if(!state) {
      formRules.state_required.valid = false;
      errors.state = true;
    } else {
      formRules.state_required.valid = true;
    }

    return Object.keys(errors).length ? errors : mVerifyUniqueFields(values);
  }
  const mValidateOutletForm = useCallback(validateOutletForm, [mVerifyUniqueFields]);

  // Reset formRules
  useEffect(() => {
    formRules = _.cloneDeep(outletFormRules);
  }, []);

  // Validate initial values once on CDM - future refactor and remove eslint-disable
  useEffect(() => {
    outletToUpdate && mValidateOutletForm(outletToUpdate)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pathname = _.get(props, 'location.pathname');
  if(!authToken || !isAdmin) {
    return null;
  }

  if(!outletToUpdateProps && pathname === '/edit_outlet') {
    return <Redirect to='/add_outlet'/>;
  }

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          { breadcrumbs
            ? breadcrumbs.map(link => 
                <li><Link 
                  to={link.linkState 
                    ? {
                        pathname: link.link,
                        state: link.linkState
                      }
                    : link.link
                  }
                >{link.name}</Link></li>
              )
            : <>
                <li><Link to="/">Dashboard</Link></li>
                <li><Link to="/outlet">Outlet</Link></li>
                <li className="is-active"><a href="# " aria-current="page">{outletToUpdate ? 'Edit ' : 'New '}Outlet</a></li>
              </>
          }
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              {outletToUpdate ? 'Edit ' : 'New '}Outlet
            </h1>
          </div>
        </div>
      </section>
      <Form
        onSubmit={ values => {
          // if(window.confirm(`Confirm to ${outletToUpdate ? 'update' : 'create'} Outlet?`)) {
            if(outletToUpdate) {
              dispatch(updateOutlet(authToken, isAdmin, values, () => history.push("/outlet")));
            } else {
              const link = breadcrumbs ? _.last(breadcrumbs) : undefined;
              const path = breadcrumbs
                ? link.linkState 
                  ? {
                      pathname: link.link,
                      state: link.linkState
                    }
                  : link.link
                : "/outlet"
              dispatch(createOutlet(authToken, isAdmin, values, () => history.push(path)));
            }
          // }
        }}
        // Wholesaler should not set contract type, default contract type of new outlet to "TBD"
        initialValues={outletToUpdate || {contract: !isAdmin && contractList ? contractList.find(contract => _.get(contract, 'contract', '') === 'TBD') : null, active: true, created_by: currentUser}}
        validate={mValidateOutletForm}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <FormErrors formRules={Object.values(formRules)}/>
            <Field name="code" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Outlet Code"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="name" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Outlet Name"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="branch" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Branch"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            { isAdmin &&
              <Field name="contract">
                {(props) => (
                  <Select
                    label="Contract Type"
                    options={contractList}
                    getOptionLabel={o => o.contract}
                    getOptionValue={o => o.id}
                    placeholder="Select Contract Type..."
                    isLoading={isLoadingMaster}
                    isClearable
                    isSearchable
                    {...props}
                  />
                )}
              </Field>
            }
            <Field name="outlet_type">
              {(props) => (
                <Select
                  label="Outlet Type"
                  options={outletTypeList}
                  getOptionLabel={o => o.outlet_type}
                  getOptionValue={o => o.id}
                  placeholder="Select Outlet Type..."
                  isLoading={isLoadingMaster}
                  isClearable
                  isSearchable
                  {...props}
                />
              )}
            </Field>
            <Field name="legal_name" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Legal Name"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="state">
              {(props) => (
                <Select
                  label="State"
                  options={stateList}
                  getOptionLabel={o => o.state}
                  getOptionValue={o => o.id}
                  placeholder="Select State..."
                  isLoading={isLoadingMaster}
                  isClearable
                  isSearchable
                  {...props}
                />
              )}
            </Field>
            <Field name="phone" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Phone"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="pic" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Person In Charge"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="address1" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Address 1"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="address2" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Address 2"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="postcode" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Postcode"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="city" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="City"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            { outletToUpdate &&
              <Field name="active" type="checkbox">
                {(props) => (
                  <CheckBox
                    label="Status"
                    desc="Active"
                    disabled={isLoading}
                    {...props}
                  />
                )}
              </Field>
            }
            <SubmitButton
              disabled={pristine || submitting || validating}
              loading={submitting || validating}
            >{outletToUpdate ? 'Update' : 'Add'}</SubmitButton>
          </form>
        )}
      />
    </div>
  );
};

OutletForm.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(OutletForm);