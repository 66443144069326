import _ from 'lodash';
import client from './client';
import { createMessage } from '.';
import { compareStrings } from './sortHelper';

export const LOADING_USER = 'loading_user';
export const FETCH_ADMIN_LIST = 'fetch_admin_list';
export const FETCH_EXECUTIVE_LIST = 'fetch_executive_list';
export const FETCH_ALL_USER_LIST = 'fetch_all_user_list';
export const CREATE_ADMIN = 'create_admin';
export const CREATE_EXECUTIVE = 'create_executive';
export const UPDATE_ADMIN = 'update_admin';
export const UPDATE_EXECUTIVE = 'update_executive';
export const COMPLETE_USER = 'complete_user';

export const fetchUserList = (authToken, isAdmin, forAdmin) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_USER
      });

      client.getFilteredUser(authToken, forAdmin)
      .then(({data}) => {
        if(forAdmin) {
          dispatch({
            type: FETCH_ADMIN_LIST,
            adminList: data.sort(compareStrings(['username']))
          });
        } else {
          dispatch({
            type: FETCH_EXECUTIVE_LIST,
            executiveList: data.sort(compareStrings(['username']))
          });
        }
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_USER
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
};

export const fetchAllUserList = (authToken, isAdmin) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_USER
      });

      client.getAllUser(authToken)
      .then(({data}) => {
        dispatch({
          type: FETCH_ALL_USER_LIST,
          allUserList: data.sort(compareStrings(['username']))
        });
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_USER
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
};

export const createUser = (authToken, isAdmin, forAdmin, values, redirectOnSuccess) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_USER
      });

      const {username, email, password, admin, wholesaler} = values;
      client.register(authToken, email, password, username, admin, wholesaler)
      .then(({data}) => {
        dispatch(createMessage(_.get(data, 'message', 'Account created successfully.'), 'success'));
        redirectOnSuccess();
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_USER
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
};

export const updateUser = (authToken, isAdmin, forAdmin, values, redirectOnSuccess) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_USER
      });

      const {id, username, email, admin, active, wholesaler} = values;
      client.putUser(authToken, id, email, admin, username, wholesaler, active)
      .then(({data}) => {
        dispatch(createMessage('User updated successfully.', 'success'));
        dispatch({
          type: forAdmin ? UPDATE_ADMIN : UPDATE_EXECUTIVE,
          changedUser: data
        });
        redirectOnSuccess();
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_USER
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
};
