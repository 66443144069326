import {
  LOADING_MASTER,
  FETCH_REGION_LIST,
  FETCH_STATE_LIST,
  FETCH_MARKET_LIST,
  FETCH_CONTRACT_LIST,
  FETCH_OUTLET_TYPE_LIST,
  COMPLETE_MASTER,
} from '../actions';

const INITIAL_STATE = {
  isLoading: false,
  regionList: [],
  stateList: [],
  marketList: [],
  contractList: [],
  outletTypeList: []
};

export default (state = INITIAL_STATE, action) => {
  const {type, ...rest} = action;
  switch(type) {
    case LOADING_MASTER: {
      return {...state, isLoading: true};
    }

    case FETCH_REGION_LIST:
    case FETCH_STATE_LIST:
    case FETCH_MARKET_LIST:
    case FETCH_CONTRACT_LIST:
    case FETCH_OUTLET_TYPE_LIST: {
      return {...state, ...rest, isLoading: false};
    }

    case COMPLETE_MASTER: {
      return {...state, isLoading: false};
    }

    default: {
      return state;
    }
  }
};