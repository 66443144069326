import _ from 'lodash';
import axios from 'axios';
import { TaskQueue } from 'cwait';
import stringify from 'json-stringify-safe';
import * as Sentry from '@sentry/browser';

let axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVICE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': '*/*',
  },
  timeout: 10000
});

if(process.env.NODE_ENV === 'development') {
  axiosInstance.interceptors.request.use(request => {
    console.log('Starting Request:', request);
    return request;
  });
  axiosInstance.interceptors.response.use(response => {
    console.log('Response:', response);
    return response;
  });
};

let currentUser = {};

export default {
  test: () => {
    return axiosInstance.get('/users/1');
  },
  login: (email, password, admin) => {
    return axiosInstance.post(
      'auth/login',
      {
        email,
        password,
        admin
      }
    );
  },
  getCurrentUser: (authToken) => {
    return axiosInstance.get(
      'auth/user',
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  logout: (authToken) => {
    return axiosInstance.post(
      'auth/logout', 
      null,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  forgotPassword: (email) => {
    return axiosInstance.post(
      'auth/password/forgot',
      {
        email
      }
    );
  },
  resetPassword: (token, password) => {
    return axiosInstance.post(
      'auth/password/reset',
      {
        token,
        password
      }
    );
  },
  changePassword: (authToken, password, new_password) => {
    return axiosInstance.post(
      'auth/password/change',
      {
        password,
        new_password
      },
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  register: (authToken, email, password, username, admin, wholesaler) => {
    return axiosInstance.post(
      'auth/register',
      {
        email,
        password,
        username,
        admin,
        wholesaler
      },
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getAllUser: (authToken) => {
    return axiosInstance.get(
      'users',
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getFilteredUser: (authToken, admin=false) => {
    return axiosInstance.get(
      `users/?filter[admin]=${admin}`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  putUser: (authToken, user_id, email, admin, username, wholesaler, active) => {
    return axiosInstance.put(
      `users/${user_id}`,
      {
        email,
        username,
        admin,
        wholesaler,
        active
      },
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getProducts: (authToken) => {
    return axiosInstance.get(
      'products',
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  postProduct: (authToken, brand, sub_brand, name, sku_no, pack_size, bottle_size, market) => {
    return axiosInstance.post(
      'products',
      {
        brand,
        sub_brand,
        name,
        sku_no,
        pack_size,
        bottle_size,
        market
      },
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  putProduct: (authToken, product_id, brand, sub_brand, name, sku_no, pack_size, bottle_size, market, active) => {
    return axiosInstance.put(
      `products/${product_id}`,
      {
        brand,
        sub_brand,
        name,
        sku_no,
        pack_size,
        bottle_size,
        market,
        active
      },
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getWholesalers: (authToken) => {
    return axiosInstance.get(
      'wholesalers',
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  postWholesaler: (authToken, code, name, region, state, phone, pic, address1, address2, postcode, city) => {
    return axiosInstance.post(
      'wholesalers',
      {
        code,
        name,
        region,
        state,
        phone,
        pic,
        address1,
        address2,
        postcode,
        city
      },
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  putWholesaler: (authToken, wholesaler_id, code, name, region, state, phone, pic, address1, address2, postcode, city, active) => {
    return axiosInstance.put(
      `wholesalers/${wholesaler_id}`,
      {
        code,
        name,
        region,
        state,
        phone,
        pic,
        address1,
        address2,
        postcode,
        city,
        active
      },
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getOutlets: (authToken) => {
    return axiosInstance.get(
      'outlets',
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getOutletsLean: (authToken) => {
    return axiosInstance.get(
      'outlets?lean=true',
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getOutlet: (authToken, outlet_id) => {
    return axiosInstance.get(
      `outlets/${outlet_id}`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  postOutlet: (authToken, code, name, branch, contract, outlet_type, legal_name, state, phone, pic, address1, address2, postcode, city, created_by) => {
    return axiosInstance.post(
      'outlets',
      {
        code,
        name,
        branch,
        contract,
        outlet_type,
        legal_name,
        state,
        phone,
        pic,
        address1,
        address2,
        postcode,
        city,
        created_by
      },
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  putOutlet: (authToken, outlet_id, code, name, branch, contract, outlet_type, legal_name, state, phone, pic, address1, address2, postcode, city, created_by, active) => {
    return axiosInstance.put(
      `outlets/${outlet_id}`,
      {
        code,
        name,
        branch,
        contract,
        outlet_type,
        legal_name,
        state,
        phone,
        pic,
        address1,
        address2,
        postcode,
        city,
        created_by,
        active
      },
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getRegion: (authToken) => {
    return axiosInstance.get(
      'regions',
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getState: (authToken) => {
    return axiosInstance.get(
      'states',
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getMarket: (authToken) => {
    return axiosInstance.get(
      'markets',
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getContract: (authToken) => {
    return axiosInstance.get(
      'contracts',
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getOutletType: (authToken) => {
    return axiosInstance.get(
      'outlet_types',
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getSalesSummary: (authToken) => {
    return axiosInstance.get(
      `sales_summaries`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getSalesSummaryByYear: (authToken, year) => {
    return axiosInstance.get(
      `sales_summaries/?filter[year]=${year}`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getSalesSummaryByWholesalerYear: (authToken, year, wholesaler_id) => {
    return axiosInstance.get(
      `sales_summaries/?filter[year]=${year}&filter[wholesaler]=${wholesaler_id}`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getSalesSummaryById: (authToken, sales_summary_id, draft) => {
    return axiosInstance.get(
      `sales_summaries/${sales_summary_id}?draft=${draft}`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  postSalesSummary: (authToken, wholesaler_id, date, submitted) => {
    return axiosInstance.post(
      'sales_summaries',
      {
        wholesaler_id,
        date,
        submitted
      },
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  putSalesSummary: (authToken, sales_summary_id, wholesaler_id, date, submitted) => {
    return axiosInstance.put(
      `sales_summaries/${sales_summary_id}`,
      {
        wholesaler_id,
        date,
        submitted
      },
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  deleteSalesSummary: (authToken, sales_summary_id, draft, sales) => {
    return axiosInstance.delete(
      `sales_summaries/${sales_summary_id}?draft=${draft}&sales=${sales}`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  postSales: (authToken, draft, sales) => {
    // split sales to chuncks of 2000
    // to prevent huge data size and timeout
    const chunkSize = 2000;
    const batchSize = 5;
    const salesChunks = _.chunk(sales, chunkSize);

    const queue = new TaskQueue(Promise, batchSize);
    const salesPromises = salesChunks.map(queue.wrap(async chunk => 
      axiosInstance.post(
        `sales?draft=${draft}`,
        chunk,
        {
          headers: { Authorization: 'Bearer ' + authToken }
        }
      )
    ));

    return Promise.allSettled(salesPromises)
    .then(values => values.forEach(value => {
      if (value.status === 'rejected')
        throw value.reason;
    }));
  },
  putSale: (authToken, sale_id, sales_summary, sequence, invoice_date, invoice_no, outlet, product, actual_units, standard_to_units, additional_to_units) => {
    return axiosInstance.put(
      `sales/${sale_id}`,
      {
        sales_summary,
        sequence,
        invoice_date,
        invoice_no,
        outlet,
        product,
        actual_units,
        standard_to_units,
        additional_to_units
      },
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    )
  },
  deleteSale: (authToken, sale_id) => {
    return axiosInstance.delete(
      `sales/${sale_id}`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getStockBalance: (authToken) => {
    return axiosInstance.get(
      `stock_balances`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getStockBalanceByYear: (authToken, year) => {
    return axiosInstance.get(
      `stock_balances/?filter[year]=${year}`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getStockBalanceByWholesalerYear: (authToken, year, wholesaler_id) => {
    return axiosInstance.get(
      `stock_balances/?filter[year]=${year}&filter[wholesaler]=${wholesaler_id}`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getStockBalanceById: (authToken, stock_balance_id, draft) => {
    return axiosInstance.get(
      `stock_balances/${stock_balance_id}?draft=${draft}`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  postStockBalance: (authToken, wholesaler_id, date, submitted) => {
    return axiosInstance.post(
      'stock_balances',
      {
        wholesaler_id,
        date,
        submitted
      },
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  putStockBalance: (authToken, stock_balance_id, wholesaler_id, date, submitted) => {
    return axiosInstance.put(
      `stock_balances/${stock_balance_id}`,
      {
        wholesaler_id,
        date,
        submitted
      },
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  deleteStockBalance: (authToken, stock_balance_id, draft, stocks) => {
    return axiosInstance.delete(
      `stock_balances/${stock_balance_id}?draft=${draft}&stocks=${stocks}`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  postStock: (authToken, draft, stocks) => {
    return axiosInstance.post(
      `stocks?draft=${draft}`,
      stocks,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  putStock: (authToken, stock_id, stock_balance, sequence, product, balance_units) => {
    return axiosInstance.put(
      `stocks/${stock_id}`,
      {
        stock_balance,
        sequence,
        product,
        balance_units
      },
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    )
  },
  deleteStock: (authToken, stock_id) => {
    return axiosInstance.delete(
      `stocks/${stock_id}`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getSalesSummaryReport: (authToken, startDate, endDate) => {
    return axiosInstance.get(
      `sales?filter[startDate]=${startDate}&filter[endDate]=${endDate}`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getSalesSummaryReportByWholesaler: (authToken, startDate, endDate, wholesaler) => {
    return axiosInstance.get(
      `sales?filter[startDate]=${startDate}&filter[endDate]=${endDate}&filter[wholesaler]=${wholesaler}`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getStockBalanceReport: (authToken, month) => {
    return axiosInstance.get(
      `stocks?filter[month]=${month}`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getStockBalanceReportByWholesaler: (authToken, month, wholesaler) => {
    return axiosInstance.get(
      `stocks?filter[month]=${month}&filter[wholesaler]=${wholesaler}`,
      {
        headers: { Authorization: 'Bearer ' + authToken }
      }
    );
  },
  getNewOutletsListing: (authToken, startDate, endDate) => {
    return axiosInstance.get(
      `outlets?filter[startDate]=${startDate}&filter[endDate]=${endDate}`,
      {
        headers: { Authorization: 'Bearer ' + authToken },
        timeout: 10000
      }
    );
  },
  setCurrentUser: (user) => {
    currentUser = user;
  },
  handleError: (error, reportError=true, customError={}) => {
    var frontEndErrorMessage = "Server connection failed. Please check your Internet connection.";
  
    if(error.response) {
      frontEndErrorMessage = _.get(error.response, "data.error", _.get(error.response, "data", "Request error."));
    }

    if (process.env.NODE_ENV === 'development') {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("RESPONSE ERROR: ", error.response);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error("REQUEST ERROR: ", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('ERROR MESSAGE', error.message);
      }
      console.error("ERROR CONFIG: ", error.config);
      console.error("ERROR JSON: ", JSON.parse(stringify(error)));
      console.error("FRONTEND ERROR MSG: ", frontEndErrorMessage);
   
    } else if (reportError) {
      // Set user information, as well as tags and further extras
      Sentry.configureScope(scope => {
        if (error.response) {
          scope.setExtra("Response Error: ", error.response);
        } else if (error.request) {
          scope.setExtra("Request Error: ", error.request);
        } else {
          scope.setExtra('Error Message', error.message);
        }
        scope.setExtra("Error Config", error.config);
        scope.setExtra("Error JSON", JSON.parse(stringify(error)));
        scope.setExtra("Frontend Error Message", frontEndErrorMessage);
        scope.setExtra("Custom Error Details", customError);
        scope.setUser(currentUser);
        // scope.clear();
      });
      
      // Capture exceptions, messages or manual events
      if (customError && customError.name) {
        error.name = customError.name;
        Sentry.captureException(error);
      } else if (!error.response || error.response.status >= 500) {
        Sentry.captureException(error);
      }
    }
  
    return frontEndErrorMessage;
  }
};