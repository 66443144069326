import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { PersistGate } from 'redux-persist/lib/integration/react';
import store, { persistor } from "./store";
import App from "./App";

const Root = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Router>
          <ScrollToTopInRouter>
            <App />
          </ScrollToTopInRouter>
        </Router>
      </PersistGate>
    </Provider>
  );
};

const ScrollToTop = ({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

const ScrollToTopInRouter =  withRouter(ScrollToTop);

export default Root;
