import _ from 'lodash';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import createDecorator from "final-form-calculate";
import moment from 'moment';

import ReactTableFilterable from '../../components/ReactTableFilterable';
import { DatePicker, Select, TextInput, SubmitButton } from '../../components/Forms';
import { fetchRegionList, fetchStateList, fetchWholesalerList, fetchProductList, fetchStockBalanceReport } from '../../actions';
import {CSVFromTable} from '../../components/ExportCSV';

// variables for table
const wholesalerColumn = {
  Header: 'Wholesaler',
  accessor: 'stock_balance.wholesaler.name',
  minWidth: 150
};
const regionColumn = {
  Header: 'Region',
  accessor: 'stock_balance.wholesaler.region.region',
  minWidth: 100
};
const stateColumn = {
  Header: 'State',
  accessor: 'stock_balance.wholesaler.state.state',
  minWidth: 100
};
const productColumn = {
  Header: 'Product',
  accessor: 'product.name',
  minWidth: 200
};
const brandColumn = {
  Header: 'Brand',
  accessor: 'product.brand',
  minWidth: 100
};
const subBrandColumn = {
  Header: 'Sub-brand',
  accessor: 'product.sub_brand',
  minWidth: 150
};
const skuColumn = {
  Header: 'SKU No.',
  accessor: 'product.sku_no',
  minWidth: 100
};
const balanceUnitsColumn = {
  Header: 'Balance Units',
  accessor: 'balance_units',
  Cell: row => (row.value || 0),
  minWidth: 100
};
const adminColumns = [wholesalerColumn, regionColumn, stateColumn, productColumn, brandColumn, subBrandColumn, skuColumn, balanceUnitsColumn];
const wholesalerColumns = [productColumn, brandColumn, subBrandColumn, skuColumn, balanceUnitsColumn];

// Calculated wholesaler field
const calculator = createDecorator({
  field: 'region',
  updates: {
    wholesaler: function(region, {wholesaler}) {
      wholesaler = wholesaler || [];
      return wholesaler.filter(ws => !region || region.length === 0 || region.find(r => r.id === _.get(ws, 'region.id')));
    }
  }
}, {
  field: 'state',
  updates: {
    wholesaler: function(state, {wholesaler}) {
      wholesaler = wholesaler || [];
      return wholesaler.filter(ws => !state || state.length === 0 || state.find(s => s.id === _.get(ws, 'state.id')));
    }
  }
});

const monthStart = moment().startOf('month').toDate();

const StockBalanceReport = (props) => {
  const authToken = useSelector(({auth}) => auth.authToken);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);
  const currentWholesaler = useSelector(({auth}) => auth.currentUser ? auth.currentUser.wholesaler : null);
  const isLoading = useSelector(({stock}) => stock.isLoading);

  const dispatch = useDispatch();

  const [filterMonth, setFilterMonth] = useState(monthStart);
  const [filterRegions, setFilterRegions] = useState([]);
  const [filterStates, setFilterStates] = useState([]);
  const [filterWholesalers, setFilterWholesalers] = useState([]);
  const [filterBrands, setFilterBrands] = useState('');
  const [filterSubBrands, setFilterSubBrands] = useState('');
  const [filterProducts, setFilterProducts] = useState([]);
  useEffect(() => {
    if(authToken) {
      const month = moment(filterMonth).format('YYYY-MM');
      if(isAdmin) {
        dispatch(fetchStockBalanceReport(authToken, isAdmin, month));
      } else if(currentWholesaler) {
        dispatch(fetchStockBalanceReport(authToken, isAdmin, month, currentWholesaler.id));
      }
    }
  }, [filterMonth, authToken, isAdmin, currentWholesaler, dispatch]);
  const data = useSelector(({stock}) => stock.stockBalanceReport) || [];

  useEffect(() => {
    if(authToken) {
      if(isAdmin) {
        dispatch(fetchRegionList(authToken));
        dispatch(fetchStateList(authToken));
        dispatch(fetchWholesalerList(authToken, isAdmin));
      }
      dispatch(fetchProductList(authToken));
    }
  }, [authToken, isAdmin, dispatch]);
  const regionList = useSelector(({master}) => master.regionList);
  const stateList = useSelector(({master}) => master.stateList);
  const wholesalerList = useSelector(({wholesaler}) => wholesaler.wholesalerList);
  const productList = useSelector(({product}) => product.productList);
  const isLoadingMaster = useSelector(({master, wholesaler, product}) => master.isLoading || wholesaler.isLoading || product.isLoading);

  // Prepare data
  const filteredData = useMemo(() => {
    const reformatted = data.map(stock => isAdmin
      ? {...stock, stock_balance: {...stock.stock_balance, wholesaler: wholesalerList.find(ws => ws.id === _.get(stock, 'stock_balance.wholesaler_id'))}}
      : currentWholesaler
        ? {...stock, stock_balance: {...stock.stock_balance, wholesaler: currentWholesaler}}
        : stock
    );

    const filtered = reformatted.filter(stock => 
      (filterRegions.length === 0 || _.find(filterRegions, r => r.id === _.get(stock, 'stock_balance.wholesaler.region.id'))) &&
      (filterStates.length === 0 || _.find(filterStates, s => s.id === _.get(stock, 'stock_balance.wholesaler.state.id'))) &&
      (filterWholesalers.length === 0 || _.find(filterWholesalers, ws => ws.id === _.get(stock, 'stock_balance.wholesaler.id'))) &&
      (!filterBrands || _.includes(_.get(stock, 'product.brand', '').toLowerCase(), filterBrands.toLowerCase())) &&
      (!filterSubBrands || _.includes(_.get(stock, 'product.sub_brand', '').toLowerCase(), filterSubBrands.toLowerCase())) &&
      (filterProducts.length === 0 || _.find(filterProducts, p => p.id === _.get(stock, 'product.id')))
    );

    return filtered;
  }, [isAdmin, currentWholesaler, wholesalerList, data, filterRegions, filterStates, filterWholesalers, filterBrands, filterSubBrands, filterProducts]);

  const dataTableRef = useRef(null);

  if(!authToken)
    return null;

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li><Link to="/reports">Reports</Link></li>
          <li className="is-active"><a href="# " aria-current="page">Stock Balance Report</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              Stock Balance Report
            </h1>
          </div>
        </div>
      </section>
      <Form
        onSubmit={ values => {
          const {month, region, state, wholesaler, brand, sub_brand, product} = values;
          if(month !== filterMonth) {
            setFilterMonth(month);
          }
          if(!_.isEqual(region, filterRegions)) {
            setFilterRegions(region || []);
          }
          if(!_.isEqual(state, filterStates)) {
            setFilterStates(state || []);
          }
          if(!_.isEqual(wholesaler, filterWholesalers)) {
            setFilterWholesalers(wholesaler || []);
          }
          if(brand !== filterBrands) {
            setFilterBrands(brand);
          }
          if(sub_brand !== filterSubBrands) {
            setFilterSubBrands(sub_brand);
          }
          if(!_.isEqual(product, filterProducts)) {
            setFilterProducts(product || []);
          }
        }}
        initialValues={{
          month: monthStart,
          region: [],
          state: [],
          wholesaler: [],
          brand: '',
          sub_brand: '',
          product: []
        }}
        keepDirtyOnReinitialize
        decorators={[calculator]}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Field name="month">
              {(props) => (
                <DatePicker
                  label="Month"
                  dateFormat="MMM, yyyy"
                  showMonthYearPicker
                  selected={moment(props.input.value).toDate()}
                  {...props}
                />
              )}
            </Field>
            { isAdmin
              ? <>
                  <Field name="region">
                    {(props) => (
                      <Select
                        label="Wholesaler Region"
                        options={regionList}
                        getOptionLabel={o => o.region}
                        getOptionValue={o => o.id}
                        placeholder="All"
                        isLoading={isLoadingMaster || isLoading}
                        isMulti
                        isClearable
                        isSearchable
                        {...props}
                      />
                    )}
                  </Field>
                  <Field name="state">
                    {(props) => (
                      <Select
                        label="Wholesaler State"
                        options={stateList}
                        getOptionLabel={o => o.state}
                        getOptionValue={o => o.id}
                        placeholder="All"
                        isLoading={isLoadingMaster || isLoading}
                        isMulti
                        isClearable
                        isSearchable
                        {...props}
                      />
                    )}
                  </Field>
                  <Field name="wholesaler">
                    {(props) => (
                      <Select
                        label="Wholesaler"
                        options={wholesalerList.filter(ws => 
                          (!values.region || values.region.length === 0 || _.find(values.region, r => r.id === _.get(ws, 'region.id'))) &&
                          (!values.state || values.state.length === 0 || _.find(values.state, s => s.id === _.get(ws, 'state.id')))
                        )}
                        getOptionLabel={o => o.name}
                        getOptionValue={o => o.id}
                        placeholder="All"
                        isLoading={isLoadingMaster || isLoading}
                        isMulti
                        isClearable
                        isSearchable
                        {...props}
                      />
                    )}
                  </Field>
                </>
              : null
            }
            <Field name="brand" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Product Brand"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="sub_brand" type="text">
              {(props) => (
                <TextInput
                  horizontal
                  label="Product Sub-Brand"
                  disabled={isLoading}
                  {...props}
                />
              )}
            </Field>
            <Field name="product">
              {(props) => (
                <Select
                  label="Product"
                  options={productList}
                  getOptionLabel={o => o.name}
                  getOptionValue={o => o.id}
                  placeholder="All"
                  isLoading={isLoadingMaster || isLoading}
                  isMulti
                  isClearable
                  isSearchable
                  {...props}
                />
              )}
            </Field>
            <div className="column is-full has-text-centered" style={{marginBottom: '30px'}}>
              <SubmitButton
                disabled={submitting || validating}
                loading={submitting || validating || isLoading}
              >Filter</SubmitButton>
            </div>
            <div className="column is-full has-text-right" style={{marginBottom: '20px'}}>
              <CSVFromTable
                tableRef={dataTableRef}
                columns={isAdmin ? adminColumns : wholesalerColumns}
                fileName="StockBalanceReport"
                loading={submitting || validating || isLoading}
              >
                <span className="icon">
                  <i className="fas fa-file-excel"></i>
                </span>
                <span>Export to Excel</span>
              </CSVFromTable>
            </div>
          </form>
        )}
      />
      <div>
        <ReactTableFilterable
          data={filteredData}
          columns={isAdmin ? adminColumns : wholesalerColumns}
          defaultPageSize={10}
          className="-striped -highlight"
          ref={dataTableRef}
        />
      </div>
    </div>
  );
};

export default StockBalanceReport;