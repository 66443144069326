import React from 'react';
import PropTypes from 'prop-types';

const Message = (props) => {
  const {messageName, messageType, removeMessage} = props;

  return (
    <div className={`notification is-${messageType}`}>
      <button className="delete" onClick={()=>{removeMessage()}}></button>
      <span>{messageName}</span>
    </div>
  )
};

Message.propTypes = {
  messageName: PropTypes.string.isRequired,
  messageType: PropTypes.oneOf(['primary', 'link', 'info', 'success', 'warning', 'danger']).isRequired,
  removeMessage: PropTypes.func.isRequired
};

export default Message;
