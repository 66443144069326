import _ from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import { FormErrors, TextInput, SubmitButton, forgotPasswordFormRules } from '../../components/Forms';
import { forgotPassword } from '../../actions';

// Form Validation
let formRules = _.cloneDeep(forgotPasswordFormRules);

const validateEmail = (email) => {
  // eslint-disable-next-line
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const validateFPForm = (values) => {
  const {email} = values;
  let errors = {};

  if(!validateEmail(email)) {
    formRules.email_format.valid = false;
    errors.email = true;
  } else {
    formRules.email_format.valid = true;
  }

  return errors;
}

const ForgotPassword = (props) => {
  const {history} = props;

  const isLoading = useSelector(({auth}) => auth.isLoading);

  const dispatch = useDispatch();

  // Reset formRules
  useEffect(() => {
    formRules = _.cloneDeep(forgotPasswordFormRules);
  }, []);

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/login">Login</Link></li>
          <li className="is-active"><a href="# " aria-current="page">Forgot Password</a></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              Forgot Password
            </h1>
          </div>
        </div>
      </section>
      <Form
        onSubmit={ values => {
          if(window.confirm("Email with secret key to Reset Password will be sent to your email.")) {
            dispatch(forgotPassword(values, () => history.push('/reset_password')));
          }
        }}
        validate={validateFPForm}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <FormErrors formRules={Object.values(formRules)}/>
              <Field name="email" type="text">
                {(props) => (
                  <TextInput
                    horizontal
                    label="Registered Email"
                    disabled={isLoading}
                    {...props}
                  />
                )}
              </Field>
            <SubmitButton
              disabled={submitting || validating}
            >Request</SubmitButton>
          </form>
        )}
      />
    </div>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.object.isRequired
}

export default withRouter(ForgotPassword);