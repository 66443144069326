import React from 'react';
import { useSelector } from 'react-redux';

import DashboardButton from '../components/DashboardButton';

const Dashboard = (props) => {
  const currentUser = useSelector(({auth}) => auth.currentUser);
  const isAdmin = useSelector(({auth}) => auth.isAdmin);

  const {history} = props;

  if(!currentUser) {
    return (
      <div className="column is-full has-text-centered">
        <progress className="progress is-primary is-large" max="100"/>
      </div>
    );
  }

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li className="is-active"><div aria-current="page">Dashboard</div></li>
        </ul>
      </nav>
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              { isAdmin ? 'Admin Dashboard' : 'Wholesaler Dashboard'}
            </h1>
          </div>
        </div>
      </section>
      { isAdmin
        ? <div>
            <b className="title is-4">Master Data</b>
            <div className="buttons">
              <DashboardButton
                icon="fa-wine-bottle"
                text="Product Master Data"
                onClick={() => history.push('/product')}
              />
              <DashboardButton
                icon="fa-warehouse"
                text="Wholesaler Master Data"
                onClick={() => history.push('/wholesaler')}
              />
              <DashboardButton
                icon="fa-store"
                text="Outlet Master Data"
                onClick={() => history.push('/outlet')}
              />
              <DashboardButton
                icon="fa-chart-bar"
                text="Reports"
                onClick={() => history.push('/reports')}
              />
            </div>
            <b className="title is-4">Wholesaler Submissions</b>
            <div className="buttons">
              <DashboardButton
                icon="fa-file-invoice"
                text="Sales Summary"
                onClick={() => history.push('/sales_summary')}
              />
              <DashboardButton
                icon="fa-boxes"
                text="Stock Balance"
                onClick={() => history.push('/stock_balance')}
              />
            </div>
            <b className="title is-4">Users</b>
            <div className="buttons">
              <DashboardButton
                icon="fa-users-cog"
                text="Admin Users"
                onClick={() => history.push('/admin')}
              />
              <DashboardButton
                icon="fa-user"
                text="Wholesaler Executives"
                onClick={() => history.push('/executive')}
              />
            </div>
          </div>
        : <div className="buttons">
            <DashboardButton
              icon="fa-file-invoice"
              text="Sales Summary"
              onClick={() => history.push('/sales_summary')}
            />
            <DashboardButton
              icon="fa-boxes"
              text="Stock Balance"
              onClick={() => history.push('/stock_balance')}
            />
            <DashboardButton
              icon="fa-store"
              text="Outlet Master Data"
              onClick={() => history.push('/outlet')}
            />
            <DashboardButton
              icon="fa-chart-bar"
              text="Reports"
              onClick={() => history.push('/reports')}
            />
          </div>
      }
    </div>
  );
};

export default Dashboard;