import {
  LOADING_PRODUCT,
  FETCH_PRODUCT_LIST,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  COMPLETE_PRODUCT,
} from '../actions';

const INITIAL_STATE = {
  isLoading: false,
  productList: []
};

export default (state = INITIAL_STATE, action) => {
  const {type, ...rest} = action;
  switch(type) {
    case LOADING_PRODUCT: {
      return {...state, isLoading: true};
    }

    case FETCH_PRODUCT_LIST: {
      return {...state, ...rest, isLoading: false};
    }

    case CREATE_PRODUCT: {
      const {newProduct} = action;
      const productList = [...state.productList, newProduct];
      return {...state, productList, isLoading: false};
    }

    case UPDATE_PRODUCT: {
      const {changedProduct} = action;
      const productList = state.productList.map(p => p.id === changedProduct.id ? changedProduct : p);
      return {...state, productList, isLoading: false};
    }

    case COMPLETE_PRODUCT: {
      return {...state, isLoading: false};
    }

    default: {
      return state;
    }
  }
};