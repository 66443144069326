import moment from 'moment';
import client from './client';
import { createMessage } from '.';
import { compareNumbers, compareDates, compareStrings } from './sortHelper';
import EncryptStorage from '../components/EncryptStrorage';

export const LOADING_SALES = 'loading_sales';
export const FETCH_ALL_SALES_SUMMARY = 'fetch_all_sales_summary';
export const FETCH_SALES_SUMMARY_LIST = 'fetch_sales_summary_list';
export const FETCH_SALES_SUMMARY_DETAILS = 'fetch_sales_summary_details';
export const CLEAR_SALES_SUMMARY_DETAILS = 'clear_sales_summary_details';
export const FETCH_SALES_SUMMARY_REPORT = 'fetch_sales_summary_report';
export const COMPLETE_SALES = 'complete_sales';

export const fetchAllSalesSummary = (authToken) => {
  return(dispatch) => {
    dispatch({
      type: LOADING_SALES
    });
  
    client.getSalesSummary(authToken)
    .then(({data}) => {
      dispatch({
        type: FETCH_ALL_SALES_SUMMARY,
        allSalesSummary: data.sort(compareDates('date'))
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_SALES
      });
    });
  };
};

export const fetchSalesSummaryList = (authToken, isAdmin, year, wholesalerId) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_SALES
    });
  
    if(isAdmin) {
      client.getSalesSummaryByYear(authToken, year)
      .then(({data}) => {
        dispatch({
          type: FETCH_SALES_SUMMARY_LIST,
          salesSummaryList: data.sort(compareDates('date'))
        });
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_SALES
        });
      });
    } else {
      client.getSalesSummaryByWholesalerYear(authToken, year, wholesalerId)
      .then(({data}) => {
        dispatch({
          type: FETCH_SALES_SUMMARY_LIST,
          salesSummaryList: data.sort(compareDates('date'))
        });
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_SALES
        });
      });
    }
  };
};

export const fetchSalesSummaryDetails = (authToken, salesSummaryId, draft) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_SALES
    });

    client.getSalesSummaryById(authToken, salesSummaryId, draft)
    .then(({data}) => {
      dispatch({
        type: FETCH_SALES_SUMMARY_DETAILS,
        salesSummaryDetails: {...data, sales: data.sales.sort(compareNumbers('sequence'))}
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_SALES
      });
    });
  };
};

export const clearSalesSummaryDetails = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SALES_SUMMARY_DETAILS
    });
  };
};

export const createSalesSummary = (authToken, submitted, values, redirectOnSuccess) => {

  // TRY{

    // 1. create sales summary 
      // SUCCESS: 
        // - return new sales summary id
        // - continue to create sales
      // FAIL: 
        // - shows error
    // 2. split sales to chunks of 2000
    // 3. foreach chunks create sales with new sales summary id
      // SUCCESS: 
        // - shows success
      // FAIL: 
        // - trigger undo actions

  // } CATCH {

    // 1. DELETE sales
      // SUCCESS: 
        // - continue to delete sales summary
      // FAIL:
        // - report exception
    // 2. DELETE sales summary 
      // SUCCESS: 
        // - shows creation error
      // FAIL:
        // - report exception
        
  // }

  return (dispatch) => {
    dispatch({
      type: LOADING_SALES
    });

    var triggerUndo = false;
    const {wholesaler, date, sales} = values;
    let salesSummaryId = 0;
    client.postSalesSummary(authToken, wholesaler.id, date, submitted)
    .then(({data}) => {
      triggerUndo = true;
      salesSummaryId = parseInt(data.id);
      const salesArray = sales.map((sale, index) => ({
        sales_summary: {id: salesSummaryId},
        sequence: index+1,
        invoice_date: moment(sale.invoice_date).format('YYYY-MM-DD[T00:00:00Z]'),
        invoice_no: String(sale.invoice_no),
        outlet: {id: sale.outlet.id},
        product: {id: sale.product.id},
        actual_units: sale.actual_units,
        standard_to_units: sale.standard_to_units,
        additional_to_units: sale.additional_to_units
      }));
      return client.postSales(authToken, !submitted, salesArray);
    })
    .then(() => {
      dispatch(createMessage(`Sales Summary ${submitted ? 'submitted' : 'saved'} successfully.`, 'success'));
      dispatch({
        type: COMPLETE_SALES
      });
      EncryptStorage.removeSalesItem(0);
      redirectOnSuccess();
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger', 30000));
      
      // delete data if postSales fails
      if(triggerUndo) {
        const salesCount = sales.length;
        dispatch(undoFailedCreateSalesSummary(authToken, salesSummaryId, submitted, wholesaler, date, salesCount));
      } else {
        dispatch({
          type: COMPLETE_SALES
        });
      }
    });
  };
};

const undoFailedCreateSalesSummary = (authToken, salesSummaryId, submitted, wholesaler, date, salesCount) => {
  return (dispatch) => {
    client.deleteSalesSummary(authToken, salesSummaryId, !submitted, true)
    .then( () => {
      return client.deleteSalesSummary(authToken, salesSummaryId, !submitted, false);
    })
    .then(() => {
      dispatch({
        type: COMPLETE_SALES
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err, true, {name: "Failed to revert 'Create Sales Summary'", salesSummaryId, submitted, wholesaler, date, salesCount}), 'danger'));
      dispatch({
        type: COMPLETE_SALES
      });
    });
  };
}

export const submitDraftSales = (authToken, salesSummaryId, values, redirectOnSuccess) => {

  // TRY{

    // 1. update sales summary status
      // SUCCESS: 
        // - continue to create sales
      // FAIL: 
        // - shows error
    // 2. split sales to chunks of 2000
    // 3. foreach chunks create sales for submitted sales
      // SUCCESS: 
        // - shows success
      // FAIL: 
        // - trigger undo actions

  // } CATCH {

    // 1. DELETE sales
      // SUCCESS: 
        // - continue to revert sales summary
      // FAIL:
        // - report exception
    // 2. REVERT sales summary status
      // SUCCESS: 
        // - shows creation error
      // FAIL:
        // - report exception
        
  // }

  return (dispatch) => {
    dispatch({
      type: LOADING_SALES
    });

    var triggerUndo = false;
    const {wholesaler, date, sales} = values;
    client.putSalesSummary(authToken, salesSummaryId, wholesaler.id, date, true)
    .then(() => {
      triggerUndo = true;
      const salesArray = sales.map((sale, index) => ({
        sales_summary: {id: salesSummaryId},
        sequence: index+1,
        invoice_date: moment(sale.invoice_date).format('YYYY-MM-DD[T00:00:00Z]'),
        invoice_no: String(sale.invoice_no),
        outlet: {id: sale.outlet.id},
        product: {id: sale.product.id},
        actual_units: sale.actual_units,
        standard_to_units: sale.standard_to_units,
        additional_to_units: sale.additional_to_units
      }));
      return client.postSales(authToken, false, salesArray);
    })
    .then(() => {
      dispatch(createMessage('Sales Summary submitted successfully.', 'success'));
      dispatch({
        type: COMPLETE_SALES
      });
      EncryptStorage.removeSalesItem(salesSummaryId);
      redirectOnSuccess();
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger', 30000));
      
      // revert data if postSales fails
      if(triggerUndo) {
        dispatch(undoFailedSubmitDraftSales(authToken, salesSummaryId, true, wholesaler, date, sales.length));
      } else {
        dispatch({
          type: COMPLETE_SALES
        });
      }
    });
  };
};

const undoFailedSubmitDraftSales = (authToken, salesSummaryId, submitted, wholesaler, date, salesCount) => {
  return (dispatch) => {
    client.deleteSalesSummary(authToken, salesSummaryId, !submitted, true)
    .then( () => {
      return client.putSalesSummary(authToken, salesSummaryId, wholesaler.id, date, false);
    })
    .then(() => {
      dispatch({
        type: COMPLETE_SALES
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err, true, {name: "Failed to revert 'Submit Draft Sales'", salesSummaryId, submitted, wholesaler, date, salesCount}), 'danger'));
      dispatch({
        type: COMPLETE_SALES
      });
    });
  };
}

export const updateDraftSales = (authToken, salesSummaryId, sales, redirectOnSuccess) => {

  // TRY{

    // 1. get old sales data
      // SUCCESS: 
        // - save a copy of old sales data
      // FAIL: 
        // - shows error
    // 2. delete old sales data
      // SUCCESS: 
        // - continue to create new sales data
      // FAIL: 
        // - shows error
    // 3. split sales to chunks of 2000
    // 4. foreach chunks create new sales 
      // SUCCESS: 
        // - shows success
      // FAIL: 
        // - trigger undo actions

  // } CATCH {

    // 1. DELETE sales
      // SUCCESS: 
        // - continue to create old sales data
      // FAIL:
        // - report exception
    // 2. CREATE old sales data
      // SUCCESS: 
        // - shows creation error
      // FAIL:
        // - report exception
        
  // }

  return (dispatch) => {
    dispatch({
      type: LOADING_SALES
    });

    var triggerUndo = false;
    var oldSales = [];

    client.getSalesSummaryById(authToken, salesSummaryId, true)
    .then(({data}) => {
      oldSales = data.sales.sort(compareNumbers('sequence'));
    })
    .then(() => {
      return client.deleteSalesSummary(authToken, salesSummaryId, true, true);
    })
    .then(() => {
      triggerUndo = true;
      const salesArray = sales.map((sale, index) => ({
        sales_summary: {id: salesSummaryId},
        sequence: index+1,
        invoice_date: moment(sale.invoice_date).format('YYYY-MM-DD[T00:00:00Z]'),
        invoice_no: String(sale.invoice_no),
        outlet: {id: sale.outlet.id},
        product: {id: sale.product.id},
        actual_units: sale.actual_units,
        standard_to_units: sale.standard_to_units,
        additional_to_units: sale.additional_to_units
      }));
      return client.postSales(authToken, true, salesArray)
    })
    .then(() => {
      dispatch(createMessage('Sales Summary saved successfully.', 'success'));
      dispatch({
        type: COMPLETE_SALES
      });
      EncryptStorage.removeSalesItem(salesSummaryId);
      redirectOnSuccess();
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger', 30000));
      // revert data if postSales fails
      if(triggerUndo) {
        dispatch(undoFailedUpdateDraftSales(authToken, salesSummaryId, sales.length, oldSales));
      } else {
        dispatch({
          type: COMPLETE_SALES
        });
      }
    });
  };
};

const undoFailedUpdateDraftSales = (authToken, salesSummaryId, salesCount, oldSales) => {
  return (dispatch) => {
    client.deleteSalesSummary(authToken, salesSummaryId, true, true)
    .then( () => {
      const salesArray = oldSales.map((sale, index) => ({
        sales_summary: {id: salesSummaryId},
        sequence: index+1,
        invoice_date: moment(sale.invoice_date).format('YYYY-MM-DD[T00:00:00Z]'),
        invoice_no: String(sale.invoice_no),
        outlet: {id: sale.outlet.id},
        product: {id: sale.product.id},
        actual_units: sale.actual_units,
        standard_to_units: sale.standard_to_units,
        additional_to_units: sale.additional_to_units
      }));
      return client.postSales(authToken, true, salesArray)
    })
    .then(() => {
      dispatch({
        type: COMPLETE_SALES
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err, true, {name: "Failed to revert 'Update Draft Sales'", salesSummaryId, salesCount}), 'danger'));
      client.handleError(err, true, {name: "Failed to revert 'Update Draft Sales' - with old sales data", salesSummaryId, salesCount, oldSales});
      dispatch({
        type: COMPLETE_SALES
      });
    });
  };
}

export const revertSubmittedSales = (authToken, salesSummaryId, values, redirectOnSuccess) => {

  // TRY{

    // 1. get old sales data
      // SUCCESS: 
        // - save a copy of old sales data
      // FAIL: 
        // - shows error
    // 2. update sales summary status
      // SUCCESS: 
        // - continue to delete draft sales
      // FAIL: 
        // - shows error
    // 3. delete old draft sales data
      // SUCCESS: 
        // - continue to delete submitted sales data
      // FAIL: 
        // - trigger undo status
    // 4. delete old submitted sales data
      // SUCCESS: 
        // - continue to create new sales data
      // FAIL: 
        // - trigger undo status
    // 4. split sales to chunks of 2000
    // 5. foreach chunks create new sales 
      // SUCCESS: 
        // - shows success
      // FAIL: 
        // - trigger undo status + data

  // } CATCH {

    // 1. (if trigger undo status) REVERT sales summary status
      // SUCCESS: 
        // -  continue to create old sales data
      // FAIL:
        // - report exception
    // 2. (if trigger undo data) CREATE old sales data
      // SUCCESS: 
        // - shows creation error
      // FAIL:
        // - report exception
        
  // }

  return (dispatch) => {
    dispatch({
      type: LOADING_SALES
    });

    var triggerUndoStatus = false;
    var triggerUndoData = false;
    const {wholesaler, date, sales} = values;
    var oldSales = [];

    client.getSalesSummaryById(authToken, salesSummaryId, false)
    .then(({data}) => {
      oldSales = data.sales.sort(compareNumbers('sequence'));
    })
    .then(() => {
      return client.putSalesSummary(authToken, salesSummaryId, wholesaler.id, date, false);
    })
    .then(() => {
      triggerUndoStatus = true;
      return client.deleteSalesSummary(authToken, salesSummaryId, true, true);
    })
    .then(() => {
      return client.deleteSalesSummary(authToken, salesSummaryId, false, true);
    })
    .then(() => {
      triggerUndoData = true;
      const salesArray = sales.map((sale, index) => ({
        sales_summary: {id: salesSummaryId},
        sequence: index+1,
        invoice_date: moment(sale.invoice_date).format('YYYY-MM-DD[T00:00:00Z]'),
        invoice_no: String(sale.invoice_no),
        outlet: {id: sale.outlet.id},
        product: {id: sale.product.id},
        actual_units: sale.actual_units,
        standard_to_units: sale.standard_to_units,
        additional_to_units: sale.additional_to_units
      }));
      return client.postSales(authToken, true, salesArray);
    })
    .then(() => {
      dispatch(createMessage('Sales Summary reverted to Draft.', 'success'));
      dispatch({
        type: COMPLETE_SALES
      });
      redirectOnSuccess();
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger', 30000));
      // revert data if postSales fails
      if(triggerUndoStatus || triggerUndoData) {
        dispatch(undoFailedRevertSubmittedSales(triggerUndoData, authToken, salesSummaryId, wholesaler, date, sales.length, oldSales));
      } else {
        dispatch({
          type: COMPLETE_SALES
        });
      }
    });
  };
};

const undoFailedRevertSubmittedSales = (triggerUndoData, authToken, salesSummaryId, wholesaler, date, salesCount, oldSales) => {
  return (dispatch) => {
    client.putSalesSummary(authToken, salesSummaryId, wholesaler.id, date, true)
    .then(() => {
      if(triggerUndoData) {
        const salesArray = oldSales.map((sale, index) => ({
          sales_summary: {id: salesSummaryId},
          sequence: index+1,
          invoice_date: moment(sale.invoice_date).format('YYYY-MM-DD[T00:00:00Z]'),
          invoice_no: String(sale.invoice_no),
          outlet: {id: sale.outlet.id},
          product: {id: sale.product.id},
          actual_units: sale.actual_units,
          standard_to_units: sale.standard_to_units,
          additional_to_units: sale.additional_to_units
        }));
        return client.postSales(authToken, false, salesArray);
      }
    })
    .then(() => {
      dispatch({
        type: COMPLETE_SALES
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err, true, {name: "Failed to revert 'Revert Submitted Sales'", triggerUndoData, salesSummaryId, wholesaler, date, salesCount}), 'danger'));
      client.handleError(err, true, {name: "Failed to revert 'Revert Submitted Sales' - with old sales data", triggerUndoData, salesSummaryId, wholesaler, date, salesCount, oldSales});
      dispatch({
        type: COMPLETE_SALES
      });
    });
  };
}

// disable auto-submit feature as it is too heavy for batch processing
export const autoSubmitSalesSummaries = (authToken, salesSummaries) => {
  return (dispatch) => {
    const promises = salesSummaries.map(ss => (
      client.getSalesSummaryById(authToken, ss.id, !ss.submitted)
      .then(({data}) => {
        const {sales} = data;
        return (
          client.putSalesSummary(authToken, ss.id, ss.wholesaler_id, ss.date, true)
          .then(() => {
            const salesArray = sales.map(sale => ({
              sales_summary: {id: ss.id},
              sequence: sale.sequence,
              invoice_date: moment(sale.invoice_date).format('YYYY-MM-DD[T00:00:00Z]'),
              invoice_no: String(sale.invoice_no),
              outlet: {id: sale.outlet.id},
              product: {id: sale.product.id},
              actual_units: sale.actual_units,
              standard_to_units: sale.standard_to_units,
              additional_to_units: sale.additional_to_units
            }));
            EncryptStorage.removeSalesItem(ss.id);
            return client.postSales(authToken, false, salesArray);
          })
        );
      })
    ));

    Promise.all(promises)
    .then(() => {
      dispatch({
        type: COMPLETE_SALES
      });
    })
    .catch(err => {
      dispatch(createMessage(client.handleError(err), 'danger'));
      dispatch({
        type: COMPLETE_SALES
      });
    });
  };
};

export const createSales = (authToken, isAdmin, values, redirectOnSuccess) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_SALES
      });

      const {sales_summary, sequence, invoice_date, invoice_no, outlet, product, actual_units, standard_to_units, additional_to_units} = values;
      client.postSales(authToken, false, [{sales_summary, sequence, invoice_date: moment(invoice_date).format('YYYY-MM-DD[T00:00:00Z]'), invoice_no: String(invoice_no), outlet, product, actual_units, standard_to_units, additional_to_units}])
      .then(({data}) => {
        dispatch(createMessage('Sales Item created successfully.', 'success'));
        dispatch({
          type: COMPLETE_SALES
        });
        redirectOnSuccess();
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_SALES
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
};

export const updateSales = (authToken, isAdmin, values, redirectOnSuccess) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_SALES
      });

      const {id, sales_summary, sequence, invoice_date, invoice_no, outlet, product, actual_units, standard_to_units, additional_to_units} = values;
      client.putSale(authToken, id, sales_summary, sequence, moment(invoice_date).format('YYYY-MM-DD[T00:00:00Z]'), String(invoice_no), outlet, product, actual_units, standard_to_units, additional_to_units)
      .then(({data}) => {
        dispatch(createMessage('Sales Item updated successfully.', 'success'));
        dispatch({
          type: COMPLETE_SALES
        });
        redirectOnSuccess();
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_SALES
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
};

export const deleteSales = (authToken, isAdmin, saleId, redirectOnSuccess) => {
  return (dispatch) => {
    if(isAdmin) {
      dispatch({
        type: LOADING_SALES
      });

      client.deleteSale(authToken, saleId)
      .then(({data}) => {
        dispatch(createMessage('Sales Item deleted successfully.', 'success'));
        dispatch({
          type: COMPLETE_SALES
        });
        redirectOnSuccess();
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_SALES
        });
      });
    } else {
      dispatch(createMessage('Unauthorised request!', 'danger'));
    }
  };
};

export const fetchSalesSummaryReport = (authToken, isAdmin, startDate, endDate, wholesalerId) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_SALES
    });
  
    if(isAdmin) {
      client.getSalesSummaryReport(authToken, startDate, endDate)
      .then(({data}) => {
        dispatch({
          type: FETCH_SALES_SUMMARY_REPORT,
          salesSummaryReport: data.sort(compareStrings(['invoice_no']))
        });
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_SALES
        });
      });
    } else {
      client.getSalesSummaryReportByWholesaler(authToken, startDate, endDate, wholesalerId)
      .then(({data}) => {
        dispatch({
          type: FETCH_SALES_SUMMARY_REPORT,
          salesSummaryReport: data.sort(compareStrings(['invoice_no']))
        });
      })
      .catch(err => {
        dispatch(createMessage(client.handleError(err), 'danger'));
        dispatch({
          type: COMPLETE_SALES
        });
      });
    }
  };
};
